import { combineReducers } from 'redux';
import { UserReducer } from './UserReducer';
import {CartReducer} from './CartReducer';
import {BasketReducer} from './BasketReducer';
import {AddressReducer} from './AddressReducer';
import {BalloonReducer} from './BalloonReducer';
import {OrderReducer} from './OrderReducer';
import {LKReducer} from './LKReducer';
import ModalReducer from "./ModalReducer";
import {FormReducer} from './FormReducer'
import {SearchReducer} from "./SearchReducer"

export default combineReducers({
  User: UserReducer,
  Cart: CartReducer,
  Basket: BasketReducer,
  Address: AddressReducer,
  Balloon: BalloonReducer,
  Order: OrderReducer,
  LK: LKReducer,
  Modal: ModalReducer,
  Form: FormReducer,
  Search: SearchReducer
});
