import React from 'react';
import Vacancies from '../../Components/Job/Vacancies/Vacancies';
import './Job.sass';
import Form from '../../Components/Job/Form/Form';
import NavigationList from '../../Components/NavigationList/NavigationList'
import useResolution from "../../API/useResolution";

const Job = () => {
  const isMobile = useResolution();
  React.useEffect(() => window.scrollTo(0,0), [])
  return (
    <section className='section section_job'>
      <div className='container container_job'>
        <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Вакансии'}]}/>
          <div className="job-content">
              {isMobile && <h2 className="job-title">Вакансии</h2>}
          <Vacancies />
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Job;
