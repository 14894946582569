import React, {useState, useEffect} from 'react';
import Categories from '../Components/Categories/Categories';
import Advantages from '../Components/Advantages/Advantages';
import SliderMain from '../Components/Slider/SliderMain';
import MapYandex from '../Components/Map/Map/Map';
import {appAPI} from '../API/API'
import MainBlock from '../Components/Main/MainBlock';
import MainRecepies from '../Components/Main/MainRecepies/MainRecepies';
// eslint-disable-next-line
import MainReviews from '../Components/Main/MainReviews/MainReviews';
import MainRecommend from '../Components/Main/MainRecommend/MainRecommend';
import MainNew from '../Components/Main/MainNew/MainNew';
import useResolution from "../API/useResolution";

const Main = () => {

  const [categories, setCategories] = useState([])
  const isMobile = useResolution()
  
  useEffect(() => {
    const fetchData = async () => {
      const groups = await appAPI.getProductGroupsCat();
      if(groups.statusCode === 200) {
        setCategories(groups.data)
      }
    }
    fetchData();
  }, [])

  return (
    <>
      <SliderMain />
      <MainRecommend/>
      <MainNew/>
      <Categories />
      {
        categories.length !== 0 && !isMobile ? (
          categories.map((category) => <MainBlock props = {category} key = {category.id}/>)
        ) : null
      }
      <section>
        <MapYandex filter = {true} id = {0}/>
      </section>
      {/* <Recepies getReceipts={appAPI.getRecepies} productId={'recipes/?offset=0&limit=10'}/> */}
      <MainRecepies/>
      <Advantages />
    </>
  );
};

export default Main;
