const SET_FOLDER = 'SET_FOLDER'

const setFolder = (activeFolder) => {
    return {
        type: SET_FOLDER,
        activeFolder
    }
}

export {SET_FOLDER}
export {setFolder}