import React from "react";
import {useAccordionToggle} from "react-bootstrap";


const CustomToggle = ({children, eventKey, isOpen, toggleAccordion}) => {
    const click = useAccordionToggle(eventKey, () => {
        toggleAccordion(!isOpen)
    })

    return (
        <div className={`accordion__toggle ${isOpen ? 'accordion__toggle__open' : 'accordion__toggle__close'}`}
            onClick={event => {
                click(event)
            }}
        >
            {children}
        </div>
    )
}

export default CustomToggle;