import React, {useState, useEffect, useRef} from 'react';
import {appAPI} from '../../../API/API'
import {useSelector, useDispatch} from 'react-redux'
import '../Oformlenie.sass'
import Cart from '../../../pages/CartPage/Cart/Cart';
import useResolution from "../../../API/useResolution";
import moment from "moment";
import CartForm from '../../../pages/CartPage/Cart/CartForm';
import Cookies from 'js-cookie';
import { getPhone } from '../../Common/FormatPhone';
import { clearCart } from '../../../Actions/CartActions';
import { useHistory } from 'react-router-dom';

const Confirmation = (props) => {

    const orderValues = useSelector((state) => state.Order)
    const checkInput = useRef(null)
    const {cartId, summa, weight} = useSelector((state) => state.Cart)
    const isMobile = useResolution();
    const [checkbox, setCheckbox] = useState(false)
    const {isAuth, phone} = useSelector((state) => state.User)
    const dispatch = useDispatch();

    const getDeliveryDate = () => {
        if(weight >= 10 && orderValues.deliveryDate.split(',')[0].split('.')[0] === moment(new Date()).format('DD'))
        return moment(orderValues.deliveryDate, 'DD-MM-YYYY').add(1, 'days').format('DD.MM, dddd')
        else return orderValues.deliveryDate
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setCheckbox(false)
        console.log(checkInput)
        if(checkInput.current)
            checkInput.current.checked = false
    }, [summa])

    const history = useHistory();

    const clickHandler = async () => {
        if(checkbox) {
            props.setActiveContentSucceess({
                ...props.activeContentSucceess,
                confirmation: true
            })
    
            if(isAuth) {
                if(orderValues.orderReceiving === 'DELIVERY') {
                    try{
                        const response = await appAPI.postOrders(
                            orderValues.username,
                            orderValues.email,  
                            phone, 
                            cartId, 
                            orderValues.orderReceiving, 
                            getDeliveryDate(), 
                            orderValues.deliveryInterval, 
                            orderValues.latitude, 
                            orderValues.longitude,
                            orderValues.comment,
                            orderValues.addressCity,
                            orderValues.addressHome,
                            orderValues.street)
                        // const response = await appAPI.postOrders(orderValues, orderValues.comment)
                        Cookies.set('sberbankId', response.data.sberbankOrderId)
                        dispatch(clearCart())
                        localStorage.setItem("cart", "[]");
                        window.open(response.data.redirectUrl);
                        history.push("/");
                    } catch(err){
                        alert(err)
                    }
        
                } else {
                    try{
                        const response = await appAPI.postOrdersVivoz(
                            orderValues.username, 
                            orderValues.email, 
                            orderValues.phone, 
                            cartId, 
                            orderValues.sellerId,
                            orderValues.orderReceiving, 
                            orderValues.deliveryDate, 
                            orderValues.deliveryInterval, 
                            orderValues.latitude, 
                            orderValues.longitude)
                        Cookies.set('sberbankId', response.data.sberbankOrderId)
                        dispatch(clearCart())
                        localStorage.setItem("cart", "[]");
                        window.open(response.data.redirectUrl);
                        history.push("/");
                    } catch(err){
                        alert(err)
                    } 
                }
            } else {
                
                if(orderValues.orderReceiving === 'DELIVERY') {
                    try{
                        const response = await appAPI.postOrdersNotAuth(
                            orderValues.username,  
                            orderValues.email,
                            orderValues.phone, 
                            JSON.parse(localStorage.getItem('cart')).map(item => ({amount: item.amount, productId: item.id})), 
                            orderValues.orderReceiving, 
                            getDeliveryDate(), 
                            orderValues.deliveryInterval, 
                            orderValues.latitude, 
                            orderValues.longitude,
                            orderValues.comment,
                            orderValues.addressCity,
                            orderValues.street,
                            orderValues.building,
                            orderValues.porch,
                            orderValues.office)
                        // const response = await appAPI.postOrders(orderValues, orderValues.comment)
                        Cookies.set('sberbankId', response.data.sberbankOrderId)
                        dispatch(clearCart())
                        // localStorage.clear();
                        localStorage.setItem("cart", "[]");
                        window.open(response.data.redirectUrl);
                        history.push("/");
                    } catch(err){
                        alert(err)
                    }
        
                } else {
                    try{
                        const response = await appAPI.postOrdersVivozNotAuth(
                            orderValues.username,  
                            orderValues.email,
                            orderValues.phone, 
                            JSON.parse(localStorage.getItem('cart')).map(item => ({amount: item.amount, productId: item.id})), 
                            orderValues.orderReceiving, 
                            getDeliveryDate(), 
                            orderValues.deliveryInterval, 
                            orderValues.latitude, 
                            orderValues.longitude,
                            orderValues.sellerId)
                        Cookies.set('sberbankId', response.data.sberbankOrderId)
                        dispatch(clearCart())
                        localStorage.setItem("cart", "[]");
                        window.open(response.data.redirectUrl);
                        history.push("/");
                    } catch(err){
                        alert(err)
                    } 
                }
            }
   

        }
      
    }

    const switchActiveSutki = (sutki) => {

        switch(sutki) {
            case 'MORNING':
                return 'Утро'
            case 'AFTERNOON':
                return 'День'
            case 'EVENING':
                return 'Вечер'
            default: return;
        }
    }

    
    return(
        <>
            <div className="oformlenie-confirmation">
            <Cart cart = {props.cart}/>
                {isMobile && <CartForm/>}
                <div className="oformlenie-confirmation__footer">
                    <div className="oformlenie-confirmation__block">
                        <h4>Контактные данные</h4>
                        <ul>
                            <li>
                                <p>Имя:</p>
                                <span>{orderValues.username}</span>
                            </li>
                            <li>
                                <p>Email:</p>
                                <span>{orderValues.email}</span>
                            </li>
                            <li>
                                <p>Телефон:</p>
                                <span>{isAuth ? getPhone(phone) : getPhone(orderValues.phone)}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="oformlenie-confirmation__block">
                        <h4>{orderValues.orderReceiving === 'PICKUP' ? 'Самовывоз' : 'Доставка'}</h4>
                        <ul>
                            <li>
                                <p>Адрес:</p>
                                <span>{isAuth ? orderValues.city : orderValues.fullAddress}</span>
                            </li>
                            {orderValues.orderReceiving === 'PICKUP' ? (
                                <>
                                    <li>
                                        <p>Телефон магазина:</p>
                                        <span>{orderValues.phoneShop}</span>
                                    </li>
                                    <li>
                                        <p>Телефон администратора:</p>
                                        <span>{orderValues.phoneAdmin}</span>
                                    </li>
                                </>

                            ) : <li>
                                    <p>Комментарий:</p>
                                    <span>{orderValues.comment}</span>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className="oformlenie-confirmation__block">
                        <h4>Дата и время</h4>
                        <ul>
                            <li>
                                <p>Заказ будет готов:</p>
                                {weight >= 10 && orderValues.deliveryDate.split(',')[0].split('.')[0] === moment(new Date()).format('DD')
                                ? <span style = {{color: 'red'}}>{moment(orderValues.deliveryDate, 'DD-MM-YYYY').add(1, 'days').format('DD.MM, dddd')}</span> : <span>{orderValues.deliveryDate.substr(0, 10)}</span>}
                                
                            </li>
                            <li>
                                <p>Время:</p>
                                <span>{`${switchActiveSutki(orderValues.deliveryInterval)}, ${orderValues.time}`}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {!isMobile &&
                    <label className="oformlenie-confirmation__label">
                        <input 
                            type="checkbox"
                            onChange = { (e) => setCheckbox(e.target.checked)}
                            ref = {checkInput}
                        />
                        <p>Все данные верны</p>
                    </label>
                }
            </div>
            {
                isMobile
                    ? (
                        <>
                            <div className="oformlenie-payment__checkbox">
                                <label>
                                    <input type="checkbox" onChange={(e) => setCheckbox(e.target.checked)}/>
                                    <span>Все данные верны</span>
                                </label>
                            </div>
                            <div className="btn_oformlenie_wrapper">
                                <button className={`btn form-btn btn_oformlenie ${checkbox ? 'active' : ''}`} onClick = {clickHandler}>Оплатить</button>
                            </div>
                        </>
                    )
                    : <button className={`btn form-btn btn_oformlenie ${checkbox ? 'active' : ''}`} onClick = {clickHandler}>Оплатить</button>
            }
        </>
    )
}

export default Confirmation;