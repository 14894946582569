import React, {useState, useEffect} from 'react';
import {appAPI} from '../../../API/API'
import SliderCategoriesCard from '../../SliderCategories/SliderCards/SliderCategoriesCard';
import './LKItems.sass'

const Favorite = () => {

    const [favorites, setFavorites] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const result = await appAPI.getFavorites();
            if(result.statusCode === 200 && isMounted) {
                setFavorites(result.data)
                setIsFetching(true)
                
            }
        }
        fetchData();
        return(() => {isMounted = false});
    }, [])

    const deliteCardFavourite = (id) => {
        setFavorites(favorites.filter(card => card.id !== id))
    }

    return(
        <div className = 'lk-favourite'>
            <div className="recepies-cards">
                {isFetching ? (
                    favorites.map((el) => <SliderCategoriesCard 
                        deliteCardFavourite = {deliteCardFavourite}
                        folder = {el.product.category[0].group.name} 
                        product = {el.product} 
                        cardName = {'favourites'}
                        idFavourite = {el.id} 
                        key = {el.id}/>)
                ) : null}
            </div>
            {isFetching && favorites.length === 0 && 
                <h2 className = 'lk-favourite__title'>У вас пока нет избранных товаров</h2>}

        </div>
    )
}

export default Favorite;