import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import RecepiesCard from '../RecepiesCard/RecepiesCard';
import './RecepiesFilter.sass';
import {appAPI} from "../../../API/API";

const RecepiesFilter = (props) => {
  const arrFilter = ['Все', 'Рыба', 'Морепродукты', 'Икра'];

  const [activeFilter, setActiveFilter] = useState(arrFilter[0]);

  const clickHandler = (filterName) => {
    setActiveFilter(filterName);
  };

  console.log(props)
  

  const loadMore = async (string) => {
    await appAPI.getRecepies(string)
    .then(response => {
      props.setData(prev => ({
        ...prev,
        paginated: [...prev.paginated, ...response.data.paginated]
      }))
      response.data.nextPage ? props.setStringLimit(response.data.nextPage) : props.setShowBtnNext(false)
    })
    .catch((err) => console.log(err))
  }

  return (
    <div className='recepies-filter'>
      {
        props.data.length !== 0 ? (
          <>
            <div className='recepies-filter__buttons'>
            
            {arrFilter.map((el, i) => (
              <button
                className={`recepies-filter__button ${activeFilter === el ? 'active' : ''}`}
                key={i}
                onClick={() => clickHandler(el)}>
                {el}
              </button>
            ))}
            
          </div>
          <div className='recepies-cards'>
                    {activeFilter === 'Все'
              ? props.data.paginated.map((el) => <Link to = {`/recepies/${el.name}`} key={el.id}><RecepiesCard props={el}/></Link> )
              : props.data.paginated.map((el) => (el.category === activeFilter ? <Link to = {`/recepies/${el.name}`}  key={el.id}><RecepiesCard props={el}/></Link> : null))}
  
          </div>

          {props.showBtnNext  
            ? <button 
                className = 'review-load'
                onClick={() => loadMore(props.stringLimit)}>
                Загрузить ещё
              </button>
            : null
          }
 
        </>

        ) : null
      }

    </div>
  );
};

export default RecepiesFilter;
