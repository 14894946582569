import React, { useEffect, useState } from 'react';
import {Route} from 'react-router-dom';
import { appAPI } from '../../API/API';
import RecepiesFilter from '../../Components/Recepies/RecepiesFilter/RecepiesFilter';
import './Recepies.sass';
import Recipe from '../Recipe/Recipe';
import NavigationList from '../../Components/NavigationList/NavigationList'

const Recepies = () => {
  let [data, setData] = useState([]);
  const [stringLimit, setStringLimit] = useState(8)
  const [showBtnNext, setShowBtnNext] = useState(true)

  useEffect(() => {
    window.scrollTo(0,0);
    const fetchData = async () => {
      await appAPI.getRecepies(`recipes?offset=0&limit=8`)
        .then(response => {
          setData(response.data);
          response.data.nextPage !== null
            ? setStringLimit(response.data.nextPage)
            : setShowBtnNext(false)

          // history.push('/recepies');
        })
      
    };
    fetchData();
  }, []);
  console.log("response.data", data)


  return (
    <section className='section_recepies'>
      {data && 
      <>
        <Route exact path = '/recepies'>
          <div className='container container_recepies'>
            <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Рецепты', src: ''}]}/>
            {
              data.length !== 0 ?
              (
                <>
                  <h2>Рецепты</h2>
                <RecepiesFilter 
                  data = {data} 
                  setData = {setData} 
                  stringLimit = {stringLimit} 
                  setStringLimit = {setStringLimit}
                  showBtnNext = {showBtnNext}
                  setShowBtnNext = {setShowBtnNext}
                />
              </>
              ) : null
            }
          </div>
        </Route>
        {
          data.length !== 0 ?
          (
          <div className="container container_recipe">
            {data.paginated && data.paginated.map((el) => (
                  <Route path = {`/recepies/${el.name}`} key = {el.id}>
                    <Recipe recepie = {el} recepieMore = {data}/>
                  </Route>
            ))}
          </div>
          ) : null
        }
      </>
      }
      
    </section>
  );
};

export default Recepies;
