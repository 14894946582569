import React, {useState, useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom'
import Cart from './Cart/Cart'
import {appAPI} from '../../API/API'
import './CartPage.sass'
import NavigationList from '../../Components/NavigationList/NavigationList';
import CartForm from './Cart/CartForm';
import Oformlenie from '../../Components/Oformlenie/Oformlenie';
import {setProductsCart, rerenderCart, addItemsAll} from '../../Actions/CartActions'
import {setForm} from '../../Actions/FormActions'
import { useDispatch, useSelector } from 'react-redux';
import CartModal from './CartModal';
import useResolution from "../../API/useResolution";

const CartPage = (props) => {

    const isMobile = useResolution();
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')))
    const [saveCartShow, setSaveCartShow] = useState(false)
    const dispatch = useDispatch();
    const cartValues = useSelector((state) => state.Cart)
    const userValues = useSelector((state) => state.User)

    
    const clearCart = async () => {
        if(userValues.isAuth) {
            try {
                await appAPI.postCartClear();
                localStorage.removeItem('cart')
                dispatch(rerenderCart(cartValues.render + 1))
            } catch (err) {}
        } else {
            localStorage.removeItem('cart');
            dispatch(rerenderCart(cartValues.render + 1))
            dispatch(addItemsAll([]))
            
        }
        dispatch(setProductsCart(0, 0))
    }

    const saveCart = async () => {
        if(userValues.isAuth) {
            if(cart) {
                const response = await appAPI.postSaveCart(cartValues.cartId)
                if(response.status === 'SUCCESS') { 
                    setSaveCartShow(true) 
                }
            }
        } else {
            dispatch(setForm('signin', true))
        }

    }

    useEffect(() => {
    }, [saveCartShow]);

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart'));
        setCart(cart)
        if(isMobile) {
            const cartPrice = cart?.reduce((sum, current) => sum + current.amount * (current.promotionalPrice || current.ordinaryPrice), 0)
            const cartWeight = cart?.reduce((weight, current) => weight + current.amount * current.weight, 0)
            dispatch(setProductsCart(cartPrice, cartWeight))
        }
        // eslint-disable-next-line
    }, [cartValues.render])

    useEffect(() => {
        dispatch(rerenderCart(cartValues.render + 1))
        // eslint-disable-next-line
    }, [])

    return (

        <section className = "section_cart">
            <div className="container container_cart">
                <Route exact path = '/cart'>
                    <NavigationList items = {[{name: 'Главная', src: '/'}, {name: 'Корзина', src: ''}]}/>
                    {isMobile && <h2>Корзина</h2>}
                    <div className="cart-navigation">
                        {!isMobile && <h2>Корзина</h2>}
                        <ul className = "cart-navigation__menu">
                            <li 
                                className = 'cart-navigation__menu__item'
                                onClick = {saveCart}
                                >
                                <img src = '/img/cart/watch.svg' alt = 'save'/>
                                <p>Сохранить корзину</p>
                            </li>
                            <li 
                                className = 'cart-navigation__menu__item'
                                onClick = {clearCart}
                            >
                                <img src = '/img/cart/delete.svg' alt = 'delete'/>
                                <p>Удалить все</p>
                            </li>
                        </ul>
                    </div>
                </Route>
                
                    <Switch>
                    {cart !== null ?
                        <Route exact path = '/cart/oformlenie'>
                            <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Корзина', src: '/cart'}, {name: 'Оформление заказа'}]}  />
                            <h2 style = {{marginBottom: '20px'}}>Оформление заказа</h2>
                        </Route>
                    : <Route exact path = '/cart/oformlenie'>
                        <Redirect to = '/'/>
                        </Route>
                    }
                        <Route path = '/cart/*'>
                            <Redirect to = '/404'/>
                        </Route>
                    </Switch>
                
                
                <div className="cart-page-content">
                    <Route exact path = '/cart'> 
                        <div className="cart-items">
                            <Cart cart = {cart} setCart = {setCart}/>
                        </div>
                        <CartForm />
                    </Route>
                    <Route exact path = '/cart/oformlenie'>
                        <Oformlenie cart = {cart} setCart = {setCart} />
                        {!isMobile && (<CartForm />)}
                    </Route>
                    
                </div>                
                {saveCartShow ? <CartModal close = {setSaveCartShow}/> : null}
            </div>
        </section>
    )
}

export default CartPage;