import React, { useEffect, useState } from 'react';
import MainBlockCatalog from '../..//Main/MainBlockCatalog';
import './CatalogMain.sass';

const CatalogMain = ({groups, menuSwitchHandler, setMenuSwitchHandler}) => {

  const [products] = useState([])

  useEffect(() => {
    // const fetchData = async () => {
    //   await appAPI.getCategoryProducts(menuSwitchHandler.id, token)
    //     .then(response => setProducts(response.data))
    // }
    // fetchData();
  }, [menuSwitchHandler])

  // const [categories, setCategories] = useState([])
  // useEffect(() => {
  //   window.scrollTo(0,0)
  //   const fetchData = async () => {
  //     const groups = await appAPI.getProductGroupsCat();
  //     if(groups.statusCode === 200) {
  //       setCategories(groups.data)
  //       console.log(groups)
  //     }
      
  //   }
  //   fetchData();
  // }, [])

  console.log(products)

  return (
    <div className='catalog-main'>
        {/* {
          categories.length !== 0 ? ( */}
            {groups.map(group => <MainBlockCatalog props = {group} key = {group.id}/>)}
          {/* ) : <h2>Каталог загружается..</h2>
        } */}
    </div>
  );
};

export default CatalogMain;
