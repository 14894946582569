import React from 'react';

const SliderArrows = (props) => {
  const { className, onClick, src } = props;

  const onMouseEnter = (event) => {
    event.target.src = props.srcNew;
  };
  const onMouseLeave = (event) => {
    event.target.src = props.src;
  };

  return (
    <img
      src={src}
      alt='Arrow'
      className={className}
      style={{ display: 'block' }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default SliderArrows;
