import React from 'react';
import './LKUser.sass'
import NavigationList from '../../../Components/NavigationList/NavigationList'
import LKList from '../../../Components/LK/LKList/LKList';

const LKUser = () => {

    return(
        <section className = 'section section_lk'>
            <div className="container container_user">
                <NavigationList items = {[{name: 'Главная', src: '/'}, {name: 'Личный кабинет', src: ''}]}/>
                <h2>Личный кабинет</h2>
                <div className="lk-list__info">
                    <LKList/>
                </div>
            </div>
        </section>
    )
}

export default LKUser;