import {SET_ADDRESSES} from '../Actions/AddressActions';

const AddressReducer = (
    state = {
        addressList: null,
    },
    action
) => {
    switch (action.type) {
        case SET_ADDRESSES:
            return {
                ...state,
                addressList: action.list
            }
        default:
            return {
                ...state
            }
    }
}


export {AddressReducer};