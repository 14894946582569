import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import './Shop.sass';
import ImageGallery from 'react-image-gallery';
import useResolution from "../../../API/useResolution";
import {apiURL} from '../../../API/API'
import NavigationList from '../../NavigationList/NavigationList';

const Shop = ({ props }) => {

  const isMobile = useResolution();
  const [activeImgBalloon, setActiveImgBalloon] = useState('/img/map/balloonBlack.svg')
  const images = [];
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])


  for (let i = 0; i < props.images.length; i++) {
    images.push({ original: `${apiURL}${props.images[i].url}`, thumbnail: `${apiURL}${props.images[i].url}` });
  }
  const mapState = { center: [props.latitude, props.longitude], zoom: 10 };
  const [placemarkState] = useState({
    geometry: [props.latitude, props.longitude],
    properties: {
      balloonContent:
        `<h3 class= "balloon-header">${props.city}</h3>` +
        '<p class = "balloon-description">Телефон администратора<br/></p>' +
        `<a class = "balloon-link" href="tel:${props.adminPhone}">${props.adminPhone}</a><br/>` +
        '<p class = "balloon-description"><span>Открыт.</span> Закроется в 21:00</p>',
      iconContent: 'Узнать адрес',
    },
  });

  const redirectYandexPersonal= (e) => {
    console.log(props.latitude)
    navigator.geolocation.getCurrentPosition(position => { 
        const lat =  position.coords.latitude;
        const long = position.coords.longitude;
        window.open(`https://yandex.ru/maps/?rtext=${lat}%2C${long}~${props.latitude}%2C${props.longitude}&rtt=auto&ruri=~&z=11`)
    }, error => {
        console.log(error)
    })        
}

  return (
    <div className='container container_shop'>
      <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Магазины', src: '/shops'}, {name: props.name, src: ''}]}/>
      <div className='shop-card'>
        <div className='shop-card__top'>
          <div className='shop-card__gallary'>
            <ImageGallery
              items={images}
              infinite={true}
              showNav={false}
              showThumbnails={true}
              showFullscreenButton={false}
              showPlayButton={false}
              autoplay={true}
              slideDuration={450}
              preventDefaultTouchmoveEvent={true}
            />
          </div>
          <div className='shop-card__description'>
            <div className='shop-card__address'>
              <h2 className='shop-card__title shop-card__title_red'>{props.name}</h2>
              {props.underground}
            </div>
            <div className='shop-card__time'>
              <h4>Режим работы</h4>
              До {props.closeTime}
            </div>
            <div className='shop-card__phone'>
              <h4>Телефон магазина</h4>
              <p>{props.shopPhone}</p>
            </div>
            <div className='shop-card__phone'>
              <h4>Телефон администратора</h4>
              <p>{props.adminPhone}</p>
            </div>
            <button className='shop-card__btn' onClick = {redirectYandexPersonal}>
              {isMobile &&  <img src="/img/map/mapArrow.svg" alt="map" />}
              <p>Построить маршрут</p>
            </button>
          </div>
        </div>
        <div className='shop-card__bottom'>
          <div className='shop-card__map'>
            <h2 className='shop-card__title shop-card__title_blue'>Карта</h2>
            <YMaps query={{ apikey: 'ffa8919c-f377-4df6-b245-444b0b7c81e3' }}>
              <Map
                state={mapState}
                className='map'
                options={{
                  minZoom: 4,
                  maxZoom: 15,
                }}
                modules={[
                  'control.SearchControl',
                  'geoObject.addon.balloon',
                  'geoObject.addon.hint',
                  'templateLayoutFactory',
                  'layout.ImageWithContent',
                ]}>
                <Placemark
                  {...placemarkState}
                  options={{
                    iconLayout: 'default#image',
                    iconImageSize: [50, 50],
                    iconImageHref: activeImgBalloon,
                  }}
                  onMouseEnter = {() => setActiveImgBalloon('/img/map/ballonRed.svg')}
                  onMouseLeave = {() => setActiveImgBalloon('/img/map/balloonBlack.svg')}
                />
              </Map>
            </YMaps>
          </div>
          {props.video &&
            <div className='shop-card__find'>
              <h2 className='shop-card__title shop-card__title_blue'>Как нас найти</h2>
                <iframe title="video" width="430" height="430" src={`https://www.youtube.com/embed/${new URL(props.video).search.slice(3)}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
              {/*<video src={props.video} alt='video' controls width = '430px' height = '430px'/>*/}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Shop;
