import React from 'react'
import './Search.sass'
import {useSelector} from 'react-redux'
import NavigationList from '../../Components/NavigationList/NavigationList'
import SliderCategoriesCard from '../../Components/SliderCategories/SliderCards/SliderCategoriesCard';
import {Link} from 'react-router-dom'

const Search = () => {

    const {productList} = useSelector((state) => state.Search)

    return(
        <div className="container container_search">
            <div className="page-search">
                <NavigationList items = {[{name: 'Главная', src: '/'}, {name: `Результаты поиска`}]}/>
                <h2>Результаты поиска</h2>
                <div className="recepies-cards">
                    {productList.map((product) => 
                        <SliderCategoriesCard product = {product} folder = {product.groups[0].name}/>
                    )}
                </div>
                {productList.length === 0 && 
                    <div className="page-search__empty">
                        <img src="/img/searchFish.svg" alt="img"/>
                        <p className="bold">Поиск не дал результатов</p>
                        <p className = 'light'>Не найдено или не существует</p>
                        <Link to = '/catalog' className = 'btn form-btn'>Перейти в каталог</Link>
                    </div>
                } 
            </div>
        </div>
    )

}

export default Search;