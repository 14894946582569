import {SET_PRODUCTS} from '../Actions/SearchActions';

const SearchReducer = (
    state = {
        productList: [],
    },
    action
) => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                productList: action.list
            }
        default:
            return {
                ...state
            }
    }
}


export {SearchReducer};