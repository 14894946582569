const SET_BALLOON = 'SET_BALLOON'

const setBalloon = (balloon) => {
    return {
        type: SET_BALLOON,
        balloon
    }
}


export {SET_BALLOON}
export {setBalloon}