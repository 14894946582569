import React, {useState, useEffect} from 'react'
import InputMask from 'react-input-mask';
import '../Oformlenie.sass'
import {useSelector, useDispatch} from 'react-redux'
import {userData} from '../../../Actions/UserActions'
import {addFio} from '../../../Actions/OrderActions'
import {appAPI} from '../../../API/API';
import useResolution from "../../../API/useResolution";
import { getPhone } from '../../Common/FormatPhone';

const ContactData = (props) => {

    const userValues = useSelector((state) => state.User)
    const [checkboxCreateLk, setCheckboxCreateLk] = useState(false)
    const {confirmPhone, username, email, phone} = useSelector((state) => state.Order)
    const { isAuth, checkAuth } = useSelector((state) => state.User);
    const dispatch = useDispatch();
    const isMobile = useResolution();

    const clickHandler = async () => {    
        if(formData.name && formData.phone && formData.email && checkboxCreateLk) {
            dispatch(userData('user', formData.phone, formData.name, formData.email))
            // eslint-disable-next-line
            if(isAuth && checkbox || !isAuth) {
                props.setActiveContent('get');
                props.setActiveContentSucceess({
                    ...props.activeContentSucceess,
                    contacts: true
                });
                dispatch(addFio(formData.name, formData.email, formData.phone))
                
                // setCheckboxCreateLk && 
                // await appAPI.
            }    
        }
    }



    const [me, setMe] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [meFlag, setMeFlag] = useState(false)
    const [checkbox, setCheckbox] = useState(false)
    const [formData, setFormData] = useState({
        name: username ?? '',
        phone: phone[0] !== '+' ? '+7' + phone.slice(1) : phone ?? '',
        email: email ?? ''
    })

    useEffect(() => {
        if(formData.name && formData.phone && formData.phone.length === 16 && formData.email) {
            setCheckbox(true)
        }
        else {
            if (checkbox) setCheckbox(false)
        }
        // eslint-disable-next-line
    }, [formData])

    useEffect(() => {
        if(!isAuth && confirmPhone) {
            props.setActiveContent('get');
            props.setActiveContentSucceess({
                ...props.activeContentSucceess,
                contacts: true
            });
        }
        // eslint-disable-next-line
    }, [confirmPhone])

    useEffect(() => {window.scrollTo({top: 0, behavior: 'smooth'})}, [])


    useEffect(() => {
        if(userValues.isAuth) {
            const fetchData = async () => {
                try{
                    const response = await appAPI.getMe()
                    setMe(response)
                    setIsFetching(true)
                    let obj = {
                        name: response.username,
                        phone: response.phone,
                        email: response.email
                    }
                    setFormData(obj)

                } catch(err) {
                    alert('Введены некорректные данные')
                }
            }
            fetchData();
        } setIsFetching(true)
        // eslint-disable-next-line
    }, [meFlag])

    const submitFormRed = async (e) => {
        e.preventDefault()
        const data = {};
        data.username = formData.name;
        data.phone = formData.phone.split(' ').join('')
        data.email = formData.email
        const response = await appAPI.patchMe(data)
        
        if(response.status === 200) {
            setMeFlag(false)
            
        }
    }

    const changeHandler = (e) => {
        
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const submitHandlerUser = (e) => {
        e.preventDefault()
        dispatch(userData('user', formData.phone, formData.name, formData.email))
        if(formData.name !== '' && formData.phone !== '' && formData.phone.length === 16 && formData.email !== '') {
            setCheckbox(true)
        } else {
            alert('Данные введены некорректно')
        }
    }

    return(
        <div className="oformlenie-content oformlenie-content_contact">
            <h4 className="oformlenie__title">Получатель</h4>


            {
                isFetching ? (
                    
                    isAuth && checkAuth ? (
                        !meFlag ? (
                            <>
                                {
                                    isMobile
                                        ? (
                                            <>
                                                <div className = 'oformlenie-contact__info'>
                                                    <div>
                                                        <ul>
                                                            <li>{me.username ? me.username : 'ФИО'}</li>
                                                            <li>{me.phone ? getPhone(me.phone) : 'Телефон'}</li>
                                                            <li>{me.email ? me.email : 'Email'}</li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <img src="/img/lk/user/pencil.svg" alt="img" onClick = {() => setMeFlag(true)}/>
                                                    </div>
                                                </div>
                                                <div className="oformlenie-contact__checkbox">
                                                    <label>
                                                        <input type="checkbox" onChange={(e) => {setCheckbox(e.target.checked); setCheckboxCreateLk(e.target.checked)}}/>
                                                        <span>Все данные верны</span>
                                                    </label>
                                                    
                                                </div>

                                                <button className={`btn form-btn btn_oformlenie ${checkbox ? 'active' : ''} `} onClick = {clickHandler}>Далее</button>

                                            </>
                                        )
                                        : (
                                            <>
                                                <div className = 'oformlenie-contact__info'>
                                                    <ul>
                                                        <li>{me.username ? me.username : ''}</li>
                                                        <li>{me.phone ? getPhone(me.phone) : ''}</li>
                                                        <li>{me.email ? me.email : ''}</li>
                                                    </ul>
                                                    <label>
                                                        <input type="checkbox" onChange={(e) => {setCheckbox(e.target.checked); setCheckboxCreateLk(e.target.checked)}}/>
                                                        <span>Все данные верны</span>
                                                    </label>
                                                </div>
                                                <div className="oformlenie-contact__img">
                                                    <img src="/img/lk/user/pencil.svg" alt="img" onClick = {() => setMeFlag(true)}/>
                                                </div>
                                            </>
                                        )
                                }
                        </>
                        ) : (
                            <form className="form form_contactData" onSubmit={submitFormRed}>
                                <input 
                                    className = 'form__field form__field_input' 
                                    name = 'name' type="text" placeholder = 'ФИО' 
                                    
                                    onChange = {changeHandler}
                                    />
                                <InputMask 
                                    type = "phone" name = 'phone' 
                                    className="form__field form__field_input" 
                                    placeholder = '*+7' mask="+7 999 999 99 99"
                                    onChange = {changeHandler}
                                    />
                                <input 
                                    className = 'form__field form__field_input' 
                                    name = 'email' type="email" placeholder = 'Email' 
                                    defaultValue = {me.email}
                                    onChange = {changeHandler}
                                    />
                                <button className = 'btn form-btn'>Сохранить</button>
                            </form>
                        )


                    ) :
                    (
                        <div className = 'contact-data_notAuth'>
                            <form action="" className="form form_contactData form_contactData_user" onSubmit={submitHandlerUser}>
                                <input 
                                    type="text" 
                                    name = 'name' 
                                    className="form__field form__field_input" 
                                    placeholder = '*Введите ваше имя' 
                                    onChange = {changeHandler}
                                    value = {formData.name}
                                    readOnly = {!isAuth && confirmPhone}
                                />
                                <InputMask 
                                    type = "phone" 
                                    name = 'phone' 
                                    className="form__field form__field_input" 
                                    placeholder = '*+7' 
                                    mask="+7 999 999 99 99"
                                    onChange = {changeHandler}
                                    value = {formData.phone}
                                    readOnly = {!isAuth && confirmPhone}
                                />
                                <input 
                                    type="email" 
                                    name = 'email'
                                    value = {formData.email}
                                    className="form__field form__field_input" 
                                    placeholder = '*Введите email'
                                    onChange = {changeHandler}
                                    readOnly = {!isAuth && confirmPhone}
                                />
                                <p style ={{marginBottom: '20px'}}>*Поля обязательные для заполнения</p>                      
                            </form>
                            <p>
                            <label>
                                <input type="checkbox" onChange = { () => setCheckboxCreateLk(!checkboxCreateLk)}/>
                                <p>Согласен с <a href = '/PDFs/confidential.pdf' target ="_blank">политикой конфиденциальности</a> и <a href = '/PDFs/licenseAgreement.pdf' target ="_blank">лицензионным соглашением</a></p>
                            </label>
                            </p>   
                        </div>
                    )
                    
                ) : null
            }
            {(!isMobile || !isAuth) && <button className={`btn form-btn btn_oformlenie ${checkbox && formData.name && formData.phone && formData.email && checkboxCreateLk ? 'active' : ''} `} onClick = {clickHandler}>Далее</button>}
        </div>
    )
}

export default ContactData;