import React, {useState, useEffect} from 'react';
import '../CatalogSales/CatalogSales.sass'
import { useSelector } from 'react-redux';
import { appAPI } from '../../../API/API';
import CatalogNewsFilter from './CatalogNewsFilter';

const CatalogNews = ({groups}) => {

    const {token} = useSelector(state => state.User)
    const [products, setProducts] = useState([])
    const [isFetching, setIsFetching] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            await appAPI.getProductsNew(token)
                .then(response => {
                    setProducts(response.data)
                    setIsFetching(false)
                })
        }
        fetchData();
// eslint-disable-next-line
    }, [])

    return(
        <section className = 'section_news'>
            <div className="catalog catalog_news">
                <div className="catalog-product" style = {{maxWidth: '825px'}}>
                    <CatalogNewsFilter products = {products} filterItems = {groups} isFetching = {isFetching}/>                    
                    {/* <CatalogFilterName data = {products} page = {"news"}/> */}
                </div>

            </div>
        </section>
    )   
}

export default CatalogNews;