import React from 'react'
import {appAPI} from '../../../API/API'
import Recepies from '../../Catalog/CatalogProduct/Recepies';

const MainRecepies = () => {
    
    return(
        <div className = 'block block_slider'>
            <h3 className="block__title">Рецепты</h3>
            <div className="cards cards_recepies">
                <Recepies getReceipts={appAPI.getRecepies} productId={'recipes/?offset=0&limit=10'}/>
            </div>
        </div>
    )
}

export default MainRecepies;