import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setProductsCart, setCartId } from '../../Actions/CartActions'
import {appAPI} from '../../API/API'
import './Cart.sass';
import CartItem from './CartItem';
import {logoutUser} from '../../Actions/UserActions'

const Cart = () => {
 
  const [cart, setCart] = useState([])
  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useState(false)
  const cartValues = useSelector((state) => state.Cart)
  const {isAuth} = useSelector((state) => state.User)
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(logoutUser)
  }

  useEffect(() => {
      const fetchData = async () => {
        if(isAuth) {
          try{
              const response = await appAPI.getCarts();
              const cartActive = response.data.find(cart => cart.cartType === 'ACTIVE');
              localStorage.setItem('cart', JSON.stringify(cartActive.products.map(product => ({...product.product, amount: product.amount}))))
              dispatch(setProductsCart(cartActive.cartPrice, cartActive.cartWeight))
              setCart(cartActive.products)
              !cartValues.cartId && dispatch(setCartId(cartActive.id))
          } catch(err){
            err.response.data.statusCode === 404 && logout()
          }
        } else {
          const cart = JSON.parse(localStorage.getItem('cart'));
          if(cart) {
            const cartPrice = cart?.reduce((sum, current) => sum + current.amount * (current.promotionalPrice ?? current.ordinaryPrice), 0)
            const cartWeight = cart?.reduce((weight, current) => weight + current.amount * current.weight, 0)
            dispatch(setProductsCart(cartPrice, cartWeight))
            setCart(cart)
            setIsFetching(true)
          } else setCart([])
        }
    }
    fetchData();
    // eslint-disable-next-line
  }, [cartValues.render, isAuth]) 

  const showOstatok = () => {
    return 5000 - cartValues.summa <= 0 ? '0' : 5000 - cartValues.summa;
  };

  return (
    <div className='cart'>
      <div className='cart-items'>
        { cart.length > 0 ?
            cart.map((product, i) =>  (
                <CartItem
                  props={ isAuth ? product.product : product}
                  key = {product.id}
                  amount = {product.amount}
                />
              ))
          : <div className = 'cart-items_isEmpty'><p>В вашей корзине пока пусто.</p></div>
        }
      </div>
      <div className='cart-details'>
      <div className='cart-order'>
        <div className='cart-order__top'>
          <p className='cart-order__desc'>
            Сумма заказа: {cartValues.summa} ₽<span>Вес заказа: {cartValues.weight?.toFixed(2)} кг</span>
          </p>
          <div className='cart-order__itog'>
            <div className='cart-order__itogovaya'>
              <p>Итого: {cartValues.summa} ₽</p>
              <p>Стоимость без учета доставки</p>
              
            </div>
            {cartValues.summa > 0 && <Link to = '/cart/oformlenie' className='btn cart-order__btn form-btn '>Оформить заказ</Link> }
          </div>
          <p className='cart-order__description'>
                        Стоимость весового товара предварительно рассчитывается 
                        с максимальном возможным весом единицы товара. 
                        После оформления заказа будет произведен перерасчёт,
                        разница будет возвращена покупателю.
              </p>
        </div>
      </div>
      <div className='cart-bottom'>
        <p className='cart-bottom_desc'>Закажите на 5 000 рублей и доставка будет бесплатной</p>
        <div className='cart-bottom__graph'>
          <div className='graph__img'>
            <div className='graph__green' style={{width: cartValues.summa/5000* 100 + '%'}}></div>
          </div>
          <div className='graph__points'>
            <p> {cartValues.summa} ₽</p>
            <p>Осталось {showOstatok()} ₽</p>
            <p>{5000} ₽</p>
          </div>
        </div>
      </div>
   
      </div>
       </div>
  );
};

export default Cart;
