import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import Slider from 'react-slick';
import settings from './SliderMain/SliderSettings'
import {appAPI} from '../../API/API'
import SliderCategoriesCard from '../SliderCategories/SliderCards/SliderCategoriesCard';
import useResolution from "../../API/useResolution";

const MainBlock = ({props}) => {
    const [products, setProducts] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const userValues = useSelector((state) => state.User)
    const isMobile = useResolution();

    useEffect(() => {
        const fetchData = async () => {
            if(userValues.isAuth) {
                try{
                    const response = await appAPI.getCategoryProducts(props.id, userValues.token);
                    setProducts(response.data)
                    setIsFetching(true)
                } catch(err) {}

            } else {
                try{
                    const response = await appAPI.getCategoryProducts(props.id);
                    setProducts(response.data)
                    setIsFetching(true)
                } catch(err) {}
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    return(
        <>
        { isFetching && products.length !== 0 ? (
            <div className="block block_slider">
                <h3 className="block__title">{props.name}</h3>
                <Slider {...settings(isMobile)}>
                    {products.map((product) => (
                        product.groups.length
                        ? <SliderCategoriesCard folder = {props.name} product = {product} cardName = {props.name} key = {product.id}/>
                        : null
                    )
                    )}
                </Slider>
            </div>
            ) : null
        }
        </>
    )
}

export default MainBlock;