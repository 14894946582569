import React from 'react';
import './Vacancies.sass';
import useResolution from "../../../API/useResolution";

const VacanciesItem = ({ props }) => {
  const isMobile = useResolution();
  const formatPhoneNumber = (phoneNumber) => {
    phoneNumber.toString();
  };
  formatPhoneNumber(props.phone);
  return (
    <div className='vacancies-item'>
      <div className='vacancies-item__top'>
        <h3 className='vacancies-item__title'>{props.name}</h3>
        <p className='vacancies-item__address'>{props.address}</p>
      </div>
      <div className='vacancies-item__bottom'>
        <p className='vacancies-item__cost'>
          {props.salaryFrom} - {props.salaryTo}
        </p>
        <div className='vacancies-item__phone'>
          {
            isMobile
              ? (
                    <p>
                      Телефон для связи: <br />
                      <span>{props.phone}</span>
                    </p>
                )
              : (
                    <div>
                      <div>Телефон для связи:</div>
                      <div className="phone">{props.phone}</div>
                    </div>
                )
          }
        </div>
      </div>
    </div>
  );
};

export default VacanciesItem;
