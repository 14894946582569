import React from 'react';
import './Slider.sass';
import Slider from 'react-slick';
import useResolution from "../../API/useResolution";
import {Link} from 'react-router-dom'
import {appAPI, apiURL} from '../../API/API'
import {useSelector} from 'react-redux'
import SliderArrowPrev from './SliderArrowPrev';
import SliderArrowNext from './SliderArrowNext';

const SliderMain = React.memo(() => {
  const isMobile = useResolution();

  const [slides, setSlides] = React.useState([])
  const {token} = useSelector(state => state.User)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: !isMobile,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    prevArrow: <SliderArrowPrev/>,
    nextArrow: <SliderArrowNext/>,
    className: 'carousel',
  };

  React.useEffect(() => {
      const fetchData = async () => {
        await appAPI.getSlides(token)
          .then(response => setSlides(response.data))
          .catch(error => console.error(error))
      }
      fetchData();
// eslint-disable-next-line
  }, [])

  return (
    <section>
      <div className='container container_carousel'>
        <Slider {...settings}>
          {slides.sort((prev, next) => prev.localId < next.localId ? -1 : 1).map((slide, i) => (
            <div className={`carousel__item carousel__item_${slide.id}`} key = {slide.id}>
              <img className = 'slide-background-img' src={`${apiURL}${slide.image}`} alt=""/>
              <h1>{slide.slideText}</h1>
              <Link to = {slide.link.replace(window.location.href, '')}
                className = 'btn form-btn slider-btn'>{slide.buttonText}</Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
});

export default SliderMain;
