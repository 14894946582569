import React, {useState, useEffect} from 'react'
import {appAPI} from '../../../API/API'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import '../Oformlenie.sass'
import 'react-dadata/dist/react-dadata.css';
import ShowAddress from '../../LK/LKItems/Addresses/ShowAddress'
import AddAddress from '../../LK/LKItems/Addresses/AddAddress';
import PatchAddress from '../../LK/LKItems/Addresses/PatchAddress';
import {useDispatch, useSelector} from 'react-redux'
import {addType, addDelivery, addOrderTime} from '../../../Actions/OrderActions'
import moment from "moment";

// eslint-disable-next-line
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const GetWayDelivery = ({showReduxFields}) => {
    // eslint-disable-next-line
    const {orderReceiving, deliveryPrice} = useSelector((state) => state.Order);
    const {summa, weight} = useSelector((state) => state.Cart)
    const userValues = useSelector((state) => state.User);
    const timeNow = moment(new Date()).format('LT').slice(0, 2)
    const newDate = new Date()
    let n = newDate.getDay();

    const [activeRangeMin, setActiveRangeMin] = useState(() => getActiveInterval(8, 12, 16))
    const [activeRangeMax, setActiveRangeMax] = useState(() => getActiveInterval(12, 16, 22))
    const [activeSutki, setActiveSutki] = useState(() => getActiveInterval('Утро', 'День', 'Вечер'))


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addType('DELIVERY'))
        dispatch(addDelivery(activeDate, 'MORNING'))
        dispatch(addOrderTime(`${activeRangeMin}-${activeRangeMax}`))
        // eslint-disable-next-line 
    }, [])

    const oformlenieGetListItems = [
        {
            id: 0,
            date: '12.09, Пн',
            time: '08-16',
            cost: '300  ₽'
        },
        {
            id: 1,
            date: '13.09, Вт',
            time: '16-22',
            cost: '300  ₽'
        },
        {
            id: 2,
            date: '14.09, Ср',
            time: '16-22',
            cost: '300  ₽'
        },
        {
            id: 3,
            date: '15.09, Чт',
            time: '08-16',
            cost: '300  ₽'
        },
        {
            id: 4,
            date: '16.09, Пт',
            time: '16-22',
            cost: '300  ₽'
        },
        {
            id: 5,
            date: '',
            time: '',
            cost: ''
        }
    ]

    const [activeDayItem, setActiveDayItem] = useState(weight < 10 ? oformlenieGetListItems[0].id : oformlenieGetListItems[1].id)
    const [activeDate, setActiveDate] = useState(`${(new Date()).toISOString()}`)
    // const [todayDate, setTodayDate] = useState(`${(new Date()).toISOString()}`)
    const [isToday, setToday] = useState(true)

    const days = [
        'Вск',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
    ];

    function getDays(m) {
        return m === 2 ? 28 : 30 + (m > 7 ? m + 1 : m) % 2;
    }

    function getActiveInterval(...args) {
        if (timeNow <= '10') return args[0]
        else if (timeNow <= '14') return args[1]
        else return args[2]
    }

    let [inputValue, setInputValue] = useState({
        value: {min: activeRangeMin, max: activeRangeMax},
    })

    const onClickInterval = (interval, min, max) => {
        setActiveSutki(interval);
        setActiveRangeMin(min)
        setActiveRangeMax(max)
        setInputValue({value: {min, max}})
        dispatch(addOrderTime(`${min} - ${max}`))
    }

    const changeInputHandler = (value) => {
        const newActiveDate = new Date(activeDate)
        newActiveDate.setHours(value.min + 3)
        newActiveDate.setMinutes(0)
        console.log(newActiveDate.toISOString())
        setActiveDate(newActiveDate.toISOString())
        if ((value.max - value.min) >= 3) {
            setInputValue({value: {min: value.min, max: value.max}})
            dispatch(addOrderTime(`${value.min} - ${value.max}`))
        }
    }

    // eslint-disable-next-line
    const [addresses, setAddresses] = useState([]);
    const [isFetching, setIsFetching] = useState(false)


    const fetchData = async () => {
        try {
            const response = await appAPI.getAddresses()
            setAddresses(response.data)
            setIsFetching(true)
        } catch (err) {
        }

    }

    useEffect(() => {
        // eslint-disable-next-line
        let isMounted = true;
        if (userValues.isAuth) fetchData()
        return () => {
            isMounted = false
        };
        // eslint-disable-next-line
    }, [])

    const [activeBlock, setActiveBlock] = useState('showAddresses')
    const [activeAddress, setActiveAddress] = useState('')
    const [generalAddress, setGeneralAddress] = useState('')

    useEffect(() => {
        userValues.isAuth ? setActiveBlock('showAddresses') : setActiveBlock('showAddAddresses')
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        switch (activeSutki) {

            case ('Утро'):
                dispatch(addDelivery(activeDate, 'MORNING'))
                break;

            case ('День'):
                dispatch(addDelivery(activeDate, 'AFTERNOON'))
                break;

            case ('Вечер'):
                dispatch(addDelivery(activeDate, 'EVENING'))
                break;
            default:
                return;
        }
        // eslint-disable-next-line
    }, [activeDate, activeSutki])

    console.log(timeNow);

    return (

        <div className="oformlenie-get__delivery">
            <div className="oformlenie-get__address">
                <div className="form form_delivery" style={{display: 'flex'}}>
                    {userValues.isAuth ?
                        isFetching &&
                        (
                            activeBlock === 'showAddresses' ? (
                                <ShowAddress
                                    setActiveBlock={setActiveBlock}
                                    setActiveAddress={setActiveAddress}
                                    setGeneralAddress={setGeneralAddress}
                                    generalAddress={generalAddress}
                                    checkbox={true}
                                    sort={true}
                                />
                            ) : activeBlock === 'showAddAddresses' ? (
                                <AddAddress setActiveBlock={setActiveBlock} showReduxFields={showReduxFields}/>
                            ) : <PatchAddress setActiveBlock={setActiveBlock} activeAddress={activeAddress}/>
                        )
                        : <AddAddress setActiveBlock={setActiveBlock} showReduxFields={showReduxFields}/>
                    }
                </div>
                {userValues.isAuth ?
                    <button className='form-btn btn' onClick={() => setActiveBlock('showAddAddresses')}>Добавить адрес
                        доставки</button>
                    : null}
            </div>
            <div className="oformlenie-get__date">
                <h4>Дата</h4>
                <ul className="oformlenie-get__list">
                    {
                        oformlenieGetListItems.map((item, i) => (
                            // eslint-disable-next-line
                            (i === 0 && weight < 10) || i < 5 && i > 0 || (i === 5 && weight >= 10) ? (
                                <li className={`oformlenie-get__item ${activeDayItem === item.id ? 'active' : ''}`}
                                    key={i}
                                    onClick={(e) => {
                                        if (i === 0) {
                                            setToday(true)
                                        } else {
                                            setToday(false)
                                        }
                                        setActiveDayItem(i);
                                        newDate.setHours(inputValue.min || activeRangeMin)
                                        newDate.addDays(i)
                                        setActiveDate(newDate.toISOString())
                                    }}>
                                    <h5>{newDate.getDate() + i > getDays(newDate.getMonth() + 1) ? (newDate.getDate() + i - getDays(newDate.getMonth() + 1)) : (newDate.getDate() + i)}.{newDate.getMonth() < '10' ? '0' + (newDate.getMonth() + 1) : newDate.getMonth()}, {days[(n + i) % 7]}</h5>
                                    <p>
                                        {activeSutki === 'Утро' && '08-12'}
                                        {activeSutki === 'День' && '12-16'}
                                        {activeSutki === 'Вечер' && '16-22'}
                                    </p>
                                    <p>{summa < 5000
                                        ? (deliveryPrice !== 0 ? deliveryPrice + '₽' : '-')
                                        : (deliveryPrice !== 0 ? 'Бесплатно' : '-')
                                    }</p>
                                </li>
                            ) : null
                        ))
                    }
                </ul>
            </div>
            <div className="oformlenie-get__interval">
                <div>
                    <h4>Интервал</h4>
                    <ul>
                        <li>Утро 8-12</li>
                        <li>День 12-16</li>
                        <li>Вечер 16-22</li>
                    </ul>
                </div>
                <ul className="oformlenie-get__sutki">
                    {/*{timeNow <= '10' && <li className={`${activeSutki === 'Утро' ? 'active' : ''}`} onClick={() => onClickInterval('Утро', 8, 12) }>Утро</li>}*/}
                    {!(timeNow >10 && isToday) && <li className={`${activeSutki === 'Утро' ? 'active' : ''}`}
                        onClick={() => onClickInterval('Утро', 8, 12)}>Утро
                    </li>}
                    {/*{timeNow <= '14' && <li className={`${activeSutki === 'День' ? 'active' : ''}`} onClick={() => onClickInterval('День', 12, 16)}>День</li>}*/}
                    {!(timeNow >14 && isToday) && <li className={`${activeSutki === 'День' ? 'active' : ''}`}
                        onClick={() => onClickInterval('День', 12, 16)}>День
                    </li>}
                    {/*{timeNow <= '18' && <li className={`${activeSutki === 'Вечер' ? 'active' : ''}`} onClick={() => onClickInterval('Вечер', 16, 22)}>Вечер</li>}*/}
                    <li className={`${activeSutki === 'Вечер' ? 'active' : ''}`}
                        onClick={() => onClickInterval('Вечер', 16, 22)}>Вечер
                    </li>
                </ul>
            </div>
            <div className="oformlenie-get__range">
                <div>
                    <h4>Время</h4>
                    <p>Минимальный <br/>
                        интервал - 3 часа
                    </p>
                </div>
                <div className="oformlenie-get-range">
                    <InputRange
                        maxValue={activeRangeMax}
                        minValue={activeRangeMin}
                        value={inputValue.value}
                        onChange={value => changeInputHandler(value)}
                        draggableTrack={false}
                        formatLabel={value => ``}
                    />
                    <ul>
                        {Array(activeRangeMax - activeRangeMin + 1).fill('').map((item, i) => <li
                            key={i}>{activeRangeMin + i}</li>)}
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default GetWayDelivery;