import React from 'react';
import './Menu.sass';
import { Link } from 'react-router-dom';

const Menu = ({ arr, menuName }) => {
  const menu = `menu ${menuName}`;

  return (
    <ul className={menu}>
      {arr.map((el, i) => {
        return (
          <li className='menu__item' key={el.id}>
            <Link to={el.src} className='menu__link'>
              {el.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
