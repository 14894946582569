import React from 'react'
import '../CatalogProduct.sass'

const CatalogProductNalichie = () => {


    const shopList = [ 
        {
            id: 1,
            address: 'ТРЦ “Июнь”, пр. Индустриальный, д. 24. 1 этаж, напротив гипермаркета “Карусель”',
            metro: 'Проспект Большевиков',
            nalichie: 'Много',
            branchColor: '#EF6752'
        },
        {
            id: 2,
            address: 'Балканская пл. 5,лит. М, пом. 8-н',
            metro: 'Купчино',
            nalichie: 'Мало',
            branchColor: '#6586B8'
        },
        {
            id: 3,
            address: 'Балканская пл. 5,лит. М, пом. 8-н',
            metro: 'Купчино',
            nalichie: 'Скоро закончится',
            branchColor: '#6586B8'
        },
        {
            id: 4,
            address: 'ТРЦ “Июнь”, пр. Индустриальный, д. 24. 1 этаж, напротив гипермаркета “Карусель”',
            metro: 'Проспект Большевиков',
            nalichie: 'Много',
            branchColor: '#EF6752'
        }
    ]

    return(
        <div className="filter-content">
            {shopList.map((shop) => (
                <div className='filter-item'>
                    <h3 className='filter-item__title'>{shop.address}</h3>
                    <div className='filter-item__subway'>
                        <span style = {{background: shop.branchColor}}/>
                        <p className='filter-item__subway'>{shop.metro}</p>
                    </div>
                    <div className='filter-item__phone'>
                        <p>В наличии</p>
                        <span>{shop.nalichie}</span>
                    </div>
                </div>
            ))}

        </div>  
    )
}

export default CatalogProductNalichie;