import axios from 'axios';
import Cookies from 'js-cookie';

export const apiURL = 'https://api.ikra.market';

const instance = axios.create({
    baseUrl: apiURL,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const setTokenForAPI = (token) => {
    instance.defaults.headers[`Authorization`] = 'Bearer ' + token;
};

instance.interceptors.response.use(
    response => response,
    async error => {
        if (error.response.status === 401 || error.response.status === 403) {
            Cookies.remove('access-token');
            Cookies.remove('username');
            localStorage.setItem('cart', '[]')
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

export const appAPI = {
    postAppeals(category, author, text, token) {
        return axios.post(apiURL + '/appeals', {category, author, text})
    },
    async registerUser(object) {
        const response = await instance.post(apiURL + '/auth/register/user', {...object});
        return response.data;
    },
    async confirmationUser(token, smsCode) {
        const response = await instance.post(apiURL + '/auth/confirmation/user', {smsCode, registrationToken: token});
        return response.data;
    },
    async loginUser(email, password) {
        const response = await instance.post(apiURL + '/auth/login/user', {email, password});
        return response.data.data;
    },
    async resetPassword(email, resetPasswordCode, password) {
        const response = await axios.post(apiURL + '/auth/reset-password/user', {email, resetPasswordCode, password});
        return response.data;
    },
    async forgotPassword(email) {
        const response = await axios.post(apiURL + '/auth/forgot-password/user', {email});
        return response.data;
    },
    resetPasswordConfirmation(email, password, resetToken) {
        axios.post(apiURL + '/auth/reset-password/confirmation/user', {
            email,
            password,
            resetToken
        }).then((response) => {
        });
    },
    loginAdmin(login, password) {
        axios.post(apiURL + '/auth/login/admin', {login, password}).then((response) => {
        });
    },
    loginOperator(login, password) {
        axios.post(apiURL + '/auth/login/operator', {login, password}).then((response) => {
        });
    },
    loginSaller(login, password) {
        axios.post(apiURL + '/auth/login/saller', {login, password}).then((response) => {
        });
    },
    loginCourier(login, password) {
        axios.post(apiURL + '/auth/login/courier', {login, password}).then((response) => {
        });
    },
    async getVacancies() {
        const response = await axios.get(apiURL + '/vacancies');
        return response.data;
    },
    async getDefaultProduct() {
        const response = await axios.get(apiURL + '/products/defaultProduct');
        return response.data.data;
    },
    async getRecepies(string) {
        const response = await instance.get(apiURL + `/${string}`);
        return {data: response.data.data};
    },
    async getShops() {
        const response = await instance.get(apiURL + '/shops');
        return response.data;
    },
    async getShop(id) {
        const response = await instance.get(apiURL + '/shops/shop', {params: id})
        return response.data
    },
    async getRemainsShops(data) {
        const response = await instance.post(apiURL + '/shops/by-remains', {cartId: data});
        return response.data;
    },
    async getRemainsShopNotAuth(orderProducts) {
        const response = await instance.post(apiURL + '/shops/by-remains', {orderProducts});
        return response.data;
    },
    async getProducts(token) {
        const response = await instance.get(apiURL + '/products', {headers: {'Authorization': 'Bearer ' + token}});
        return response.data;
    },
    async getProductId(id, token) {
        const response = await instance.get(apiURL + `/products/${id}`, {params: {token}});
        return response.data;
    },
    async getProductGroups(token) {
        const response = await instance.get(apiURL + '/product-groups/categories', {params: {token}});
        return response.data;
    },
    async getCategoriesByGroup(id) {
        const response = await instance.get(apiURL + `/categories/${id}`);
        return response.data;
    },
    async getProductsNew(token) {
        const response = await instance.get(apiURL + '/products/new', {params: {token}});
        return response.data;
    },
    async getProductsRecommend(token) {
        const response = await instance.get(apiURL + '/products/recommend', {params: {token}});
        return response.data;
    },
    async searchProducts(token, searchBy) {
        const response = await instance.post(apiURL + '/products/searchBy', {searchBy}, {params: {token}});
        return response.data;
    },
    async getCategories() {
        const response = await instance.get(apiURL + '/categories');
        return response.data;
    },
    async getMe() {
        try {
            const response = await instance.get(apiURL + '/users/me');
            return response.data;
        } catch (err) {
            return err;
        }
    },
    async patchMe(object) {
        const response = await instance.patch(apiURL + '/users/me', {...object});
        return response;
    },
    async changePasswordMe(passwordOld, passwordNew) {
        const response = await instance.patch(apiURL + '/users/me/change-password', {passwordOld, passwordNew});
        return response.data;
    },
    async getCategoryProducts(id, token) {
        const response = await instance.get(apiURL + `/product-groups/${id}/products`, {params: {token}});
        return response.data;
    },
    async getProductGroupsCat() {
        const response = await instance.get(apiURL + `/product-groups`);
        return response.data;
    },
    async getReviewsAll() {
        const response = await instance.get(apiURL + `/reviews`);
        return response.data;
    },
    async getReviewsLast() {
        const response = await instance.get(apiURL + `/reviews/last`);
        return response.data;
    },
    async postReviews(formData) {
        const response = await instance.post(apiURL + `/reviews`, formData);
        return response.data;
    },
    async getCarts() {
        const response = await instance.get(apiURL + `/carts`);
        return response.data;
    },
    async postCartProduct(amount, productId, token) {
        const response = await instance.post(apiURL + `/carts/product`, {amount, productId}, {params: {token}});
        return response.data;
    },
    async putCartProduct(amount, productId) {
        const response = await instance.put(apiURL + `/carts/product`, {amount, productId});
        return response.data;
    },
    async delCartProduct(id) {
        const response = await instance.delete(apiURL + `/carts/${id}`);
        return response.data;
    },
    async postSaveCart(cartId) {
        const response = await instance.post(apiURL + `/carts/${cartId}/saved`, {cartId});
        return response.data;
    },
    async delSaveCart(cartId) {
        const response = await instance.delete(apiURL + `/carts/${cartId}/saved`);
        return response.data
    },
    async postCartClear() {
        const response = await instance.post(apiURL + `/carts/clear`);
        return response.data;
    },
    async getAddresses() {
        const response = await instance.get(apiURL + `/addresses`);
        return response.data;
    },
    async getAddressesId(id) {
        const response = await instance.get(apiURL + `/addresses/${id}`);
        return response.data;
    }
    ,
    async postAdresses(obj) {
        const response = await instance.post(apiURL + `/addresses`, {...obj});
        return response.data;
    },
    async delAddress(id) {
        const response = await instance.delete(apiURL + `/addresses/${id}`);
        return response.data;
    },
    async putAddress(id, addressCity, addressHome, phone, comment, latitude, longitude, general, name) {
        const response = await instance.put(apiURL + `/addresses/${id}`, {
            addressCity,
            addressHome,
            phone,
            comment,
            latitude,
            longitude,
            general,
            name
        });
        return response.data;
    },
    async patchAddress(id) {
        const response = await instance.patch(apiURL + `/addresses/${id}/set-general`);
        return response.data;
    },
    async getReviewsPagination(string) {
        const response = await instance.get(apiURL + `${string}`);
        return response.data;
    },
    async getReviewsSummary() {
        const response = await instance.get(apiURL + `/reviews/summary`);
        return response.data;
    },
    async postFavorites(productId) {
        const response = await instance.post(apiURL + `/favorites`, {productId});
        return response.data;
    },
    async getFavorites() {
        const response = await instance.get(apiURL + '/favorites');
        return response.data;
    },
    async postOrders(username, email, phone, cartId, orderReceiving, deliveryDate, deliveryInterval, latitude, longitude, comment, addressCity, addressHome, street) {
        const response = await instance.post(apiURL + '/orders',
            {
                username,
                email,
                phone,
                cartId,
                orderReceiving,
                deliveryDate,
                deliveryInterval,
                latitude,
                longitude,
                comment,
                city: addressCity,
                home: addressHome,
                street
            });
        return response.data;
    },
    async postOrdersNotAuth(username, email, phone, orderProducts, orderReceiving, deliveryDate, deliveryInterval, latitude, longitude, comment, city, street, building, porch, office) {
        const response = await instance.post(apiURL + '/orders',
            {
                username,
                email,
                phone,
                orderProducts,
                orderReceiving,
                deliveryDate,
                deliveryInterval,
                latitude,
                longitude,
                comment,
                city,
                street,
                building,
                porch,
                office
            });
        return response.data;
    },
    async postOrdersVivoz(username, email, phone, cartId, sellerId, orderReceiving, deliveryDate, deliveryInterval, latitude, longitude) {
        const response = await instance.post(apiURL + '/orders',
            {
                username,
                email,
                phone,
                cartId,
                sellerId,
                orderReceiving,
                deliveryDate,
                deliveryInterval,
                latitude,
                longitude
            }
        );
        return response.data;
    },
    async postOrdersVivozNotAuth(username, email, phone, orderProducts, orderReceiving, deliveryDate, deliveryInterval, latitude, longitude, sellerId) {
        const response = await instance.post(apiURL + '/orders',
            {
                username,
                email,
                phone,
                orderProducts,
                orderReceiving,
                deliveryDate,
                deliveryInterval,
                latitude,
                longitude,
                sellerId
            });
        return response.data;
    },
    async confirmationOrder(sberbankOrderId) {
        const response = await instance.post(apiURL + '/orders/confirmation', {sberbankOrderId});
        return response.data;
    },
    async getDeliveryPrice(latitude, longitude) {
        const response = await instance.post(apiURL + '/shops/price-by-address', {latitude, longitude});
        return response.data;
    },
    async checkUserPhone(phone) {
        const response = await instance.post(apiURL + '/auth/check-phone', {phone});
        return response.data;
    },
    async checkUserPhoneConfirmation(checkPhoneToken, smsCode) {
        const response = await instance.post(apiURL + '/auth/check-phone/confirmation', {smsCode, checkPhoneToken});
        return response.data;
    },
    async getProductsSubcategory(id) {
        const response = await instance.get(apiURL + `/subcategories/${id}/products`);
        return response.data;
    },
    async getSlides(token) {
        const response = await instance.get(apiURL + `/slider`, {headers: {'Authorization': 'Bearer ' + token}});
        return response.data;
    },
    async getRecepiesByProduct(productId) {
        const response = await instance.get(apiURL + `/recipes/by-product/${productId}`);
        return response.data;
    },
    async getMenuConstruction() {
        const response = await instance.get(apiURL + `/product-groups/simple-categories/subcategories`);
        return response.data;
    },
    async getProductsPromotional(token) {
        const response = await instance.get(apiURL + `/products/promotional`, {params: {token}});
        return response.data;
    },
    async getCategoriesWithGroupsByGroupId(id, token) {
        const response = await instance.get(apiURL + `/product-groups/${id}/categories/products`, {params: {token}});
        return response.data;
    },
    async changeUserPhone(phone, token) {
        const response = await instance.post(apiURL + '/users/me/change-phone', {phone}, {headers: {'Authorization': 'Bearer ' + token}});
        return response;
    },
    async confirmationUserPhone(smsCode, checkPhoneToken, token) {
        const response = await instance.post(apiURL + '/users/me/change-phone/confirmation', {
            smsCode,
            checkPhoneToken
        }, {headers: {'Authorization': 'Bearer ' + token}});
        return response.data;
    },
    async getSubcategoriesByCategoryId(id, token) {
        const response = await instance.get(apiURL + `/categories/${id}/subcategories/products`, {headers: {'Authorization': 'Bearer ' + token}});
        return response.data;
    }
};
