import React from 'react'
import {Link} from 'react-router-dom'
import '../LKItems.sass'
import {apiURL} from '../../../../API/API'

const SaveCartItem = (props) => {
    // console.log(props)
    
    return(
        <div className="lk-history__item">
            <div className="lk-history__item-left">
                <Link to = {`/catalog/${props.product.product.category[0].group.name}/${props.product.product.name}`}>
                    <img src = {`${apiURL}${props.product.product.images[0].url}`} alt = 'img' className="lk-history__item__img"></img>
                    <div className="lk-history__item__block">
                        <p>{props.product.product.name}</p>
                        <span>Артикул 239454 | 1 кг</span>
                    </div>
                </Link>

            </div>
            <div className="lk-history__item-right">
                <div className="lk-history__item__count">
                    {props.product.amount}
                </div>
                <div className="lk-history__item__block">
                    <p>{props.product.product.ordinaryPrice * props.product.amount} ₽</p>
                    <span>{props.product.product.ordinaryPrice} ₽ * {props.product.amount}</span>
                </div>
            </div>
        </div>
    )
}

export default SaveCartItem;