import React from 'react';
import {Link} from 'react-router-dom'
import './Errors.sass'

const Error404 = () => {
    
    return(
        <section className = 'section_error section_error_404'>
            <div className="container container_error .container_error_404">
                <div className="error-text">
                    <h2 className="error-title">404</h2>
                    <p className = 'error-desc'>страница не найдена</p> 
                    <Link to = '/catalog' className = 'error-link btn btn-form'> 
                        Перейти в каталог
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Error404;