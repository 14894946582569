import {SET_BALLOON} from '../Actions/BallonActions';

const BalloonReducer = (
    state = {
        balloon: null,
    },
    action
) => {
    switch (action.type) {
        case SET_BALLOON:
            return {
                balloon: action.balloon
            }
        default:
            return {
                ...state
            }
    }
}


export {BalloonReducer};