import React from 'react'
import CartItem from './CartItem';
import {useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom';

const Cart = ({setCart, cart}) => {
    
    const {isAuth} = useSelector((state) => state.User)
    const history = useHistory()
        
    const cartIsEmpty = () => (
        <div className="cart-items_empty">
            <img src = '/img/cart/CartEmptyPlaceholder.svg' alt = 'img'/>
            <p>Корзина пока пуста</p>
            <p>Выбирайте понравившиеся товары в нашем каталоге и добавляйте их в корзину</p>
            <button class = 'button form-btn' onClick = { () => history.push('/')}>Продолжить покупки</button>
        </div>
    )


    return (
        <>
        {isAuth ? (
            cart?.length
                ? cart.map((product) => <CartItem props = {product} setCart = {setCart} cart = {cart} amount = {product.amount} key = {product.id}/>)
                :  cartIsEmpty()
            
        ) : cart?.length
            ? cart.map((product, i) => <CartItem props = {product} setCart = {setCart} cart = {cart} amount = {1} key = {i}/>)
            : cartIsEmpty()
        }
        </>
    )
}

export default Cart;