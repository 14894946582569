import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import './Categories.sass';
import {appAPI} from "../../API/API";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    appAPI.getProductGroupsCat()
        .then(res => setCategories(res.data))
  }, [])

  const getCategoryId = (name) => {
    const item = categories.find(item => item.name === name);
    return item ? item.id : '';
  }
  
  return (
    <section>
      <div className='container'>
        <div className='categories'>
          <h3 className='categories-title'>Категории товаров</h3>
          <div className='categories-cards'>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Икра охлажденная')}`}>
                <img src='/img/categories/caviar.svg' alt='caviar' className='categories__img' />
              </Link>
              <h4 className='categories__category'>Икра</h4>
            </div>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Рыба')}`}>
                <img src='/img/categories/fish.svg' alt='fish' className='categories__img' />
              </Link>
              <h4 className='categories__category'>Рыба</h4>
            </div>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Морепродукты')}`}>
                <img src='/img/categories/shrimp.svg' alt='shrimp' className='categories__img' />
              </Link>
              <h4 className='categories__category'>Морепродукты</h4>
            </div>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Полуфабрикаты')}`}>
                <img src='/img/categories/salmon.svg' alt='salmon' className='categories__img' />
              </Link>
              <h4 className='categories__category'>Полуфабрикаты</h4>
            </div>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Готовая продукция')}`}>
                <img src='/img/categories/salad.svg' alt='salad' className='categories__img' />
              </Link>
              <h4 className='categories__category'>Готовая продукция</h4>
            </div>
            <div className='categories__card'>
              <Link to={`/catalog/categories/${getCategoryId('Консервы')}`}>
                <img src='/img/categories/canned.svg' alt='canned' className='categories__img' />
              </Link>
              <h4 className='categories__category'>
                Консервы
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
