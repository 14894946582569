import React, {useState, useEffect} from 'react';
import {apiURL} from '../../../API/API'
import './CatalogProduct.sass'
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import SliderArrows from '../../SliderCategories/Slider/SliderArrows';

const Recepies = ({getReceipts, productId = undefined}) => {
    const [recepies, setRecepies] = useState([])
    const [isFetching, setIsFetching] = useState(true)

    const mobileSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: recepies ? recepies.length > 4 ? 4 : recepies.length : 4,
        arrows: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 6000,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: recepies ? recepies.length > 2 ? 2 : recepies.length : 2,
                arrows: false,
              }
            },
        ],
        appendDots: dots => (
            <div>
              {dots.slice(0, 7)}
            </div>
        ),
        prevArrow: <SliderArrows src='/img/slider/arrowPrevSlider.svg' srcNew={'/img/slider/arrowPrevRed.svg'} />,
        nextArrow: <SliderArrows src='/img/slider/arrowNextSlider.svg' srcNew={'/img/slider/arrowNextRed.svg'} />,
    }

    const sliderSettings = mobileSettings;

    useEffect(() => {
        const fetchData = async () => {
            await getReceipts(productId || "")
                .then(response => {
                    setRecepies(response.data.paginated || response.data);
                    setIsFetching(false);
                })
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    const [mouseX, setMouseX] = useState(0)
    const [mouseY, setMouseY] = useState(0)
    const handleOnMouseDown = (e) => {
        setMouseX(e.clientX)
        setMouseY(e.clientY)
    }
    const handleClick = (e) => {
      e.stopPropagation()
      if (mouseX !== e.clientX || 
          mouseY !== e.clientY) {
          e.preventDefault()
      }
    }


    return(
        <div className="product-content-block product-content-block_recepies">
            {isFetching 
                ? null
                :   recepies.length > 0 ?
                        <div className="block block_slider block_slider_recepies">
                            <Slider {...sliderSettings} 
                                
                            >
                            {recepies.map((recipe) => (
                                <Link to = {`/recepies/${recipe.name}`} className = 'card card_recepies' key = {recipe.id} onMouseDown={handleOnMouseDown} onClick={handleClick}>
                                    <div className = 'card__img' key = {recipe.id}>
                                    <img src={`${apiURL}${recipe.image}`} alt="img"/>
                                    <h4 className = 'card__title'>{recipe.name}</h4>
                                    <p className = 'card__description'>{recipe.cookingTime}</p>
                                    </div>
                                </Link>
                            ))}
                            </Slider>
                        </div>
                        : <h3>У данного товара нет рецептов</h3>
            }
        </div>
    )
}

export default Recepies