import React, {useState, useEffect} from 'react'
import {appAPI, apiURL} from '../../../API/API'
import ImageGallery from 'react-image-gallery';
import './CatalogProduct.sass'
import NavigationList from '../../../Components/NavigationList/NavigationList';
import Description from '../../../Components/Catalog/CatalogProduct/Description';
import Reviews from '../../../Components/Catalog/CatalogProduct/Reviews';
import Recepies from '../../../Components/Catalog/CatalogProduct/Recepies';
import { Link } from 'react-router-dom';
import {rerenderCart} from '../../../Actions/CartActions'
import {useDispatch, useSelector} from 'react-redux'
import {setForm} from '../../../Actions/FormActions'
import CatalogProductNalichie from './CatalogProductNalichie/CatalogProductNalichie';
import Slider from "react-slick";
import useResolution from "../../../API/useResolution";
import {cartFunctions} from '../../../pages/CartPage/CartFunctions/CartFunctions'

const CatalogProduct = React.memo(({id}) => {

    const isMobile = useResolution();
    const images = [];
    const arrItems = [ {img: '', name: 'Описание товара'}, {img: '', name: 'Отзывы о товаре'}, {img: '', name: 'Рецепты с товаром'}]
    const [productInfo, setProductInfo] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const [activeItem, setActiveItem] = useState(arrItems[0].name)
    const [blockCoverActive, setBlockCoverActive] = useState(false)
    const [activeImgLike, setActiveImgLike] = useState(false)
    const [favWasAdded, setFavWasAdded] = useState(false)
    const {render} = useSelector((state) => state.Cart)
    const userValues = useSelector((state) => state.User)
    const {isAuth} = useSelector((state) => state.User)
    const dispatch = useDispatch();
    const [rerender, setRerender] = useState(0);

    const cartItemFromLocal = cartFunctions.getItemFromLocal(id)

    const fetchData = async () => {
        try{
            let response;
            userValues.isAuth 
                ? response = await appAPI.getProductId(id, userValues.token) 
                : response = await appAPI.getProductId(id)

            setProductInfo(response.data)
            setIsFetching(true)

        } catch(err) {
            // window.location.href = '/404'
        }
    }

    useEffect(() => {
        
        fetchData();
        window.scrollTo({ top: 0})
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {}, [render])
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [rerender])

    if(productInfo.length !== 0) {
        for (let i = 0; i < productInfo.images.length; i++) {
            images.push({ original: `${apiURL}${productInfo.images[i].url}`, thumbnail: `${apiURL}${productInfo.images[i].url}` });
        }
    }

    const clickHandler = (item) => {
        setActiveItem(item);
      };

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 6000,
        className: 'product-carousel carousel',
    }

    const switchActiveContent = (item) => {
        switch (item) {
            case arrItems[0].name: return <Description props = {productInfo}/>
            case arrItems[1].name: return <Reviews props = {productInfo.reviews} id = {productInfo.id} forceRender={setRerender}/>
            case arrItems[2].name: return <Recepies productId = {id} getReceipts={appAPI.getRecepiesByProduct}/>
            default: return;
        }
    }

    const counterCart = (sign) => {
        console.log("render", render)
        if(isAuth) {
            cartFunctions.putProductCartAuth(id, cartItemFromLocal.amount + 1 * sign) 
            .then(() => dispatch(rerenderCart(render + 1)))
        } else {
            cartFunctions.putProductCart(id, cartItemFromLocal.amount + 1 * sign)
            dispatch(rerenderCart(render + 1))
        }
    }

    const addProductCart = async () => {

        if(isAuth) {
            cartFunctions.addProductCartAuth({...productInfo, amount: 1}) 
            .then(() => dispatch(rerenderCart(render + 1)))
        } else {
            await cartFunctions.addProductCart({...productInfo, amount: 1}, dispatch, render)
            setTimeout(() => dispatch(rerenderCart(render+1)), 2000);

            // dispatch(rerenderCart(render+2))
            // dispatch(rerenderCart(render+3))
        }
    }

    const addProductFavourites = async () => {
        if(userValues.isAuth) {
          try {
            await appAPI.postFavorites(productInfo.id);
            setFavWasAdded(true)
          } catch(err) {}
        } else dispatch(setForm('signin', true))
    
      }

    const omMouseEnterFav = () => {
        if(userValues.isAuth) {
          !productInfo.inFavorite && setActiveImgLike(true)
        } else setActiveImgLike(true)
    }

    const omMouseLeaveFav = () => {
    if(userValues.isAuth) {
        if(!productInfo.inFavorite && !favWasAdded) setActiveImgLike(false)
    } else setActiveImgLike(false)
    }
    
    return(
        <div className='catalog-product' style = {isMobile ? {padding: '0 15px'} : null} key={rerender}>
            {isFetching && (
                <>
                    <NavigationList items = {[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: productInfo.groups[0].name, src: `/catalog/categories/${productInfo.groups[0].id}`}, {name: productInfo.name, src: ''}]}/>
                    <div className='catalog-product__content'>
                        <div className='catalog-product__top'>
                            {
                                isMobile
                                    ? (
                                        <Slider {...settings}>
                                            {
                                                images.map((img, i) => (
                                                    <div className='product-carousel__item' key = {i}>
                                                        <img src={img.thumbnail} alt='product_img' />
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    )
                                    : (
                                        <ImageGallery
                                            items = {images}
                                            infinite={true}
                                            showNav={false}
                                            showThumbnails={true}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            autoplay={true}
                                            slideDuration={450}
                                            preventDefaultTouchmoveEvent={true}
                                        />
                                    )
                            }
                            {productInfo.isNew && <img src='/img/cards/New.svg' id = 'icon-new' alt='New' className='card-top__icon card-top__icon_new' />}
                            {productInfo.isSale && <img src='/img/cards/Sale10.svg' id = 'icon-sale' alt='Sale' className='card-top__icon card-top__icon_star'/>}
                            <div className='catalog-product__description'>
                                <h2 className='catalog-product__title'>{productInfo.name}</h2>
                                <div>
                                    <p>{productInfo.description}</p>
                                    <p>Фасофка {productInfo.weight} {productInfo.unit}</p>
                                    <p>Артикул {productInfo.articul}</p>
                                </div>
                                <Link className = 'catalog-product__category' to = {`/catalog/categories/${productInfo.groups[0].id}`}>{productInfo.groups[0].name}</Link>
                                <div>
                                    
                                    <p>Купили {productInfo.totalSold} шт.</p>                                        
                                    <p>{productInfo.mark}</p>
                                </div>
                                <div>
                                    <div className = 'catalog-product__nalichie'>
                                        <p>На складе:</p>
                                        <div className = 'catalog-product__nalichie__link' onMouseEnter={() => setBlockCoverActive(true)}>
                                            <p className = 'catalog-product__bold'>Есть</p>
                                            <img src = '/img/catalog/product/arrow.svg' alt = 'img'/>
                                        </div>
                                        {
                                            blockCoverActive &&
                                            (
                                                <div className = 'catalog-product__nalichie__cover' onMouseLeave={() => setBlockCoverActive(false)} >
                                                    <CatalogProductNalichie/>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <p>Доставим: <span className = 'catalog-product__bold'>Сегодня</span></p>
                                </div>

                                <div className='catalog-product__price'>
                                    <div>
                                        <p className = 'catalog-product__bold'>Цена</p>
                                        {/*{productInfo.promotionalPrice !== 0 && */}
                                        {/*    <p style = {{textDecorationLine: 'line-through'}}>{productInfo.ordinaryPrice} ₽</p>*/}
                                        {/*}*/}

                                        {
                                            productInfo.promotionalPrice
                                            ? <p style = {{textDecorationLine: 'line-through'}}>{productInfo.ordinaryPrice} ₽</p>
                                                : null
                                        }

                                        <p className = 'catalog-product__ordinaryPrice catalog-product__bold'>
                                                <span>{productInfo.promotionalPrice || productInfo.ordinaryPrice} ₽</span> 
                                                {productInfo.unit === 'кг' && productInfo.weightRange ? <span>{productInfo.weightRange} {productInfo.unit}</span> : null}
                                        </p>
                                        {productInfo.unit === 'кг' &&
                                            <>
                                                <br/>
                                                <p>{productInfo.promotionalPrice ? (productInfo.promotionalPrice/productInfo.weight).toFixed(2) : (productInfo.ordinaryPrice/productInfo.weight).toFixed(2)} ₽ - 1 кг</p>
                                            </>
                                        }
                                    </div>
                                    <div className="icons-wrapper">
                                    <img 
                                        src={activeImgLike ? '/img/catalog/likeFill.svg': '/img/catalog/like.svg'} 
                                        alt='Like' 
                                        className='card-bottom__icon' 
                                        onMouseEnter = {omMouseEnterFav}
                                        onMouseLeave = {omMouseLeaveFav}
                                        onClick = {addProductFavourites}
                                    />
                                    { cartItemFromLocal ? (
                                        <div className='cart-item__counter'>
                                            <img
                                                src='/img/cart/cartMines.svg'
                                                alt='Mines'
                                                className='cart-item__btn cart-item__btn_mines'
                                                onClick = {() => counterCart(-1)} 
                                            />
                                            {isAuth 
                                                ? <span className='cart-item__show'>{cartItemFromLocal.amount}</span>
                                                : <span className='cart-item__show'>{
                                                    cartItemFromLocal.amount && cartItemFromLocal.amount
                                                }</span>
            }
                                                {/* {userValues.isAuth 
                                                            ? <span className='cart-item__show'>{JSON.parse(localStorage.getItem(props.id)).amount}</span>
                                                            : <span className='cart-item__show'>{JSON.parse(localStorage.getItem(props.id)).amount}</span>
                                                        //   <input className='cart-item__show' type='number' placeholder={`${JSON.parse(localStorage.getItem(props.id)).amount}`}/>
                                                        } */}
                                                <img
                                                    src='/img/cart/cartPlus.svg'
                                                    alt='Plus'
                                                    className='cart-item__btn cart-item__btn_plus'
                                                onClick = {() => counterCart(1)} 
                                                />
                                            </div>
                                            
                                            ) : (
                                                <img 
                                                src={
                                                    // activeImgCart ? '/img/catalog/product/cartFill.svg': 
                                                    '/img/catalog/product/buy.svg'} 
                                                alt='Cart' className='card-bottom__icon card-bottom__icon_cart' 
                                                onClick = {addProductCart} 
                                                />
                                            ) }
                                        </div>
                                    
                                </div>
                                <div className='catalog-product__weight-description'>
                                    <p>Стоимость весового товара предварительно рассчитывается с максимальном возможным весом единицы товара. После оформления заказа будет произведен перерасчёт, разница будет возвращена покупателю.</p>
                                </div>
                            </div>
                        </div>
                        <div className='catalog-product__bottom'>
                            <ul className='catalog-product__list'>
                                {arrItems.map((item, i) => (
                                    <li key = {i} className = {`catalog-product__list-item ${activeItem === item.name ? 'active' : ''}`}
                                    onClick={() => clickHandler(item.name)}>
                                        {!isMobile && <img src={`/img/catalog/product/${i}.svg`} alt="img"/>}
                                        {item.name && !isMobile}
                                        {item.name === 'Отзывы о товаре' && !isMobile &&` (${productInfo.reviews.reviews.length}) `}
                                        {item.name === 'Отзывы о товаре' && isMobile
                                            ? `Отзывы (${productInfo.reviews.reviews.length})`
                                            : item.name === 'Рецепты с товаром' && isMobile
                                                ? 'Рецепты'
                                                : item.name
                                        }
                                    </li>)
                                )}
                            </ul>
                            <div className="catalog-product__content">
                                {switchActiveContent(activeItem)}
                            </div>
                        </div>
                    </div>
            </>
            )}

        </div>
    )
});

export default CatalogProduct;