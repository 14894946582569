import React, {useState} from 'react';
import './LKItems.sass'
import MapYandex from '../../Map/Map/Map';
import AddAddress from './Addresses/AddAddress';
import ShowAddress from './Addresses/ShowAddress';
import PatchAddress from './Addresses/PatchAddress';

const Addresses = () => {

    const [activeBlock, setActiveBlock] = useState('showAddresses')
    const [activeAddress, setActiveAddress] = useState('')
    const [generalAddress, setGeneralAddress] = useState('')


    return(
        <div className = 'lk-addresses'>
            <MapYandex/>
            <button className = 'form-btn btn' onClick={() => setActiveBlock('showAddAddresses')}>Добавить адрес доставки</button>
            {
                activeBlock === 'showAddresses' ? (
                    <ShowAddress 
                        setActiveBlock={setActiveBlock} 
                        setActiveAddress = {setActiveAddress} 
                        setGeneralAddress = {setGeneralAddress}
                        generalAddress = {generalAddress}
                        patchAddress = {true}
                    />
                ) : activeBlock === 'showAddAddresses' ? (
                        <AddAddress setActiveBlock={setActiveBlock}/>
                ): <PatchAddress setActiveBlock={setActiveBlock} activeAddress = {activeAddress}/>

            }
        </div>
    )
}

export default Addresses;