import React from 'react';
import './Advantages.sass';
import useResolution from "../../API/useResolution";

const Advantages = () => {
  const isMobile = useResolution()
  return (
    <section>
      <div className='container container_middle'>
        <div className='advantages'>
          <h3 className='advantages__title'>Наши преимущества</h3>

          {
            !isMobile
              ? (
                  <div className='cards'>
                    <div className='card card_advantages'>
                      <img src='img/blocks/advantages/lowPrice.svg' alt='Price' className='card__img card__img_advantages'/>
                      <h3 className='card__title card__title_advantages'>Низкие цены</h3>
                      <p className='card__description card__description_advantages'>
                        Мы постоянно работаем с поставщиками и ищем наиболее выгодные предложения
                      </p>
                    </div>
                    <div className='card card_advantages'>
                      <img src='img/blocks/advantages/goods.svg' alt='Goods' className='card__img  card__img_advantages'/>
                      <h3 className='card__title card__title_advantages'>Широкий ассортимент</h3>
                      <p className='card__description card__description_advantages'>
                        Мы стараемся удовлетворять весь спектр запросов наших покупателей
                      </p>
                    </div>
                    <div className='card card_advantages'>
                      <img src='img/blocks/advantages/quality.svg' alt='Quality' className='card__img  card__img_advantages'/>
                      <h3 className='card__title card__title_advantages'>Контроль качества</h3>
                      <p className='card__description card__description_advantages'>
                        Мы проверяем продукцию, которую поставляем нашим покупателям
                      </p>
                    </div>
                  </div>
                )
              : (
                    <div className='cards_advantages'>
                      <div className='card card_advantages'>
                        <img src='img/blocks/advantages/lowPrice.svg' alt='Price' className='card__img card__img_advantages' />
                        <div>
                          <h3 className='card__title card__title_advantages'>Низкие цены</h3>
                          <p className='card__description card__description_advantages'>
                            Мы постоянно работаем с поставщиками и ищем наиболее выгодные предложения
                          </p>
                        </div>
                      </div>
                      <div className='card card_advantages'>
                        <img src='img/blocks/advantages/goods.svg' alt='Goods' className='card__img  card__img_advantages' />
                        <div>
                          <h3 className='card__title card__title_advantages'>Широкий ассортимент</h3>
                          <p className='card__description card__description_advantages'>
                            Мы стараемся удовлетворять весь спектр запросов наших покупателей
                          </p>
                        </div>
                      </div>
                      <div className='card card_advantages'>
                        <img src='img/blocks/advantages/quality.svg' alt='Quality' className='card__img  card__img_advantages' />
                        <div>
                          <h3 className='card__title card__title_advantages'>Контроль качества</h3>
                          <p className='card__description card__description_advantages'>
                            Мы проверяем продукцию, которую поставляем нашим покупателям
                          </p>
                        </div>
                      </div>
                    </div>
                )
          }

          <div className='advantages-company'>
            <h3 className='advantages-company__title'>
              Сеть розничных магазинов и интернет-магазин по продаже икры, рыбы и морепродуктов в Санкт-Петербурге и
              Ленинградской области
            </h3>
            <p className='advantages-company__description'>
              «Икра и Рыба» – это розничная сеть магазинов по продаже свежемороженой рыбы, икры, морепродуктов, которая
              раскинулась в ключевых точках СПб, Ленобласти и насчитывает более 40 магазинов, продолжая расширяться. Мы
              стараемся держать наши цены на рыбу и иные продукты на максимально низком уровне, опережая конкурентов.
              Также мы постоянно работаем над расширением ассортимента, ведем работу с лучшими поставщиками из Карелии,
              Китая, Вьетнама, Чили. Также у нас есть собственное производство на Камчатке, благодаря чему рыба всегда
              свежая. Онлайн магазин по продаже рыбы и морепродуктов, действующий на базе нашей сети, позволяет
              пользователям оперативно получать нашу продукцию с доставкой на дом. Благодаря широкой сети магазинов и
              дарксторов, нам удаётся минимизировать время доставки.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
