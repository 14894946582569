import React, {useState} from 'react'
import {rerenderCart} from '../../../Actions/CartActions'
import { useDispatch, useSelector } from 'react-redux';
import {apiURL} from '../../../API/API';
import useResolution from "../../../API/useResolution";
import { cartFunctions } from '../CartFunctions/CartFunctions';

const CartItem = ({props, amount}) => {

    const isMobile = useResolution();
    const [amountProduct] = useState(props.amount)
    const dispatch = useDispatch();
    const {isAuth} = useSelector((state) => state.User)
    const {render} = useSelector(state => state.Cart)

    const counterCart = (sign) => {
        if(isAuth) {
            cartFunctions.putProductCartAuth(props.id, amount + 1 * sign) 
            .then(() => dispatch(rerenderCart(1)))
        } else {
            cartFunctions.putProductCart(props.id, props.amount + 1 * sign)
            dispatch(rerenderCart(render + 1))
        }
    }
    
    const deleteItem = async () => {
        if(isAuth) {
            cartFunctions.delProductCartAuth(props.id) 
            .then(() => dispatch(rerenderCart(render + 1)))
        } else {
            cartFunctions.delProductCart(props.id)
            dispatch(rerenderCart(render + 1))
        }
    }

    const Wrapper = ({children}) => 
        isMobile ? <div className='cart-item__top'>{children}</div> : <>{children}</>

    return(
        <div className='cart-item'>
            <Wrapper>
                {props.images ? <img src={`${apiURL}${props.images[0].url}`} alt='img' className='cart-item__img' />
                    : <img src = '/img/blocks/caviar/caviar.jpg' alt = 'img' className='cart-item__img'/> }
                <div className='cart-item__description'>
                    <p className='cart-item__name'>{props.name}</p>
                    <p className='cart-item__articul'>{props.articul}</p>
                </div>
            </Wrapper>

            {
                isMobile
                    ? (
                        <div className="cart-item__bottom">
                            <div className='cart-item__costs'>
                                <p className='cart-item__cost'>{props.promotionalPrice ? props.promotionalPrice : props.ordinaryPrice} ₽</p>
                                <p className='cart-item__amount'>{props.ordinaryPrice} ₽ x {amountProduct || props.amount}</p>
                            </div>
                            <div className='cart-item__counter'>
                                <img
                                    src='/img/cart/cartMines.svg'
                                    alt='Mines'
                                    className='cart-item__btn cart-item__btn_mines'
                                    onClick = {() => counterCart(-1)}
                                />
                                <input className='cart-item__show' type='number' placeholder={props.amount}/>
                                <img
                                    src='/img/cart/cartPlus.svg'
                                    alt='Plus'
                                    className='cart-item__btn cart-item__btn_plus'
                                    onClick = {() => counterCart(1)}
                                />
                            </div>
                            <img
                                src='/img/forms/formClose.svg'
                                alt='deleteItem'
                                className='btn_close'
                                onClick = {deleteItem}
                            />
                        </div>
                    )
                    : (
                      <>
                          <div className='cart-item__counter'>
                              <img
                                  src='/img/cart/cartMines.svg'
                                  alt='Mines'
                                  className='cart-item__btn cart-item__btn_mines'
                                  onClick = {() => counterCart(-1)}
                              />
                              <input className='cart-item__show' type='number' placeholder={props.amount}/>
                              <img
                                  src='/img/cart/cartPlus.svg'
                                  alt='Plus'
                                  className='cart-item__btn cart-item__btn_plus'
                                  onClick = {() => counterCart(1)}
                              />
                          </div>
                          <div className='cart-item__costs'>
                              <p className='cart-item__cost'>{props.promotionalPrice ? props.promotionalPrice * props.amount : props.ordinaryPrice * props.amount} ₽</p>
                              <p className='cart-item__amount'>
                              {props.ordinaryPrice } ₽ x {props.amount}
                              </p>
                          </div>
                          <img
                              src='/img/forms/formClose.svg'
                              alt='deleteItem'
                              className='btn_close'
                              onClick={deleteItem}
                          />

                      </>
                    )
            }
    </div>
    )
}

export default CartItem;