import React from 'react';
import './RecepiesCard.sass';
import {apiURL} from '../../../API/API'


const RecepiesCards = ({ props }) => {
  return (
      <div className='card card_recepies'>
        <img src={`${apiURL}${props.image}`} alt='recepies' className='card__img' />
        <h4 className='card__title card__title_recepies'>{props.name}</h4>
        <p className='card__description'>{props.cookingTime}</p>
      </div>
  );
};

export default RecepiesCards;
