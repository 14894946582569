import { useState, useEffect } from 'react';

function useResolution(resolution = 768) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    // eslint-disable-next-line
    }, []);

    const handleResize = () => {
        let isMobileDevice = !!navigator.userAgent.match(/Mobile/) || false;
        let width = window.innerWidth;

        if (width <= resolution || (isMobileDevice && window.screen.availWidth <= resolution))
            setIsMobile(true)
        else
            setIsMobile(false)

    }


    return isMobile;
}

export default useResolution;