import { ADD_ITEM, DEL_ITEM, SET_CART, RENDER_CART, ADD_ITEMS, CHANGE_ITEMS, DEL_ITEMS, ADD_ITEMS_ALL, RERENDER_FAVOURITE, SET_CART_ID, CLEAR_CART} from '../Actions/CartActions';

const CartReducer = (
    state = {
        summa: 0,
        weight: 0,
        render: 0,
        items: [],
        renderFavorites: 0,
        cartId: ''
    },
    action,
) => {
    switch (action.type) {

        case ADD_ITEM: 
            return {
                ...state,
                summaCostProducts: state.summaCostProducts + action.summaCostItem,
                summaCountProducts: state.summaCountProducts + action.summaCountItem
            };

        case DEL_ITEM:
            return {
                ...state,
                summaCostProducts: state.summaCostProducts - action.summaCostItem,
                summaCountProducts: state.summaCountProducts - action.summaCountItem
            };

        case SET_CART: 
            return {
                ...state,
                summa: action.summa,
                weight: action.weight
            }

        case SET_CART_ID: {
            return {
                ...state,
                cartId: action.id
            }
        }

        case RENDER_CART:
            return {
                ...state,
                render: state.render + 1
            }

        case ADD_ITEMS_ALL: {
            return {
                ...state,
                items: action.items
            }
        }

        case ADD_ITEMS: {
            return {
                ...state,
                items: [...state.items, action.item]
            }
        }

        case CHANGE_ITEMS: {
            return {
                ...state,
                items: state.items.map(item => 
                    item.id === action.item.id ? action.item : item
                ) 

            }
        }

        case DEL_ITEMS: {
            return{
                ...state,
                items: state.items.filter((item => item.id !== action.id))
            }
        }

        case CLEAR_CART: {
            return {
                ...state,
                render: 0,
                summaCostProducts: 0,
                summaCountProducts: 0,
                summa: 0,
                weight: 0,
                items: [],
            }
        }

        case RERENDER_FAVOURITE: {
            return {
                ...state,
                renderFavorites: state.renderFavorites++
            }
        }

        default: 
            return {
                ...state
            }
    }
}

export {CartReducer};