const SET_PRODUCTS = 'SET_PRODUCTS'

const setProductList = (list) => {
    return {
        type: SET_PRODUCTS,
        list
    }
}


export {SET_PRODUCTS}
export {setProductList}