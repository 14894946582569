import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {setForm} from '../../Actions/FormActions'
import {appAPI} from '../../API/API'
import ClickAwayListener from 'react-click-away-listener';
import useResolution from '../../API/useResolution'
import './Forms.sass'
import Cookies from 'js-cookie';

const ChangePhone = (props) => {

    const [checkbox, setCheckbox] = useState(false)
    const [code, setCode] = useState(null)
    const {token} = useSelector((state) => state.User)
    const dispatch = useDispatch();
    const [errorMessage] = useState(null);
    const {open} = useSelector((state) => state.Form)
    const isMobile = useResolution();

    const submitHandler = async (e) => {
        e.preventDefault()
        await appAPI.confirmationUserPhone(code, Cookies.get('phoneConfirmUser'), token)
            .then(response => {
                Cookies.remove('phoneConfirmUser');
                dispatch(setForm('default', false))
            })
            .catch(err => alert(err))
    }

    return(
        <ClickAwayListener onClickAway={ () => dispatch(setForm('phoneCode', false))} style={{maxWidth: isMobile ? '345px' : 'auto'}}>
            <form className={`form modal-body__child form_modal-agree ${open ? 'active' : ''}`} onSubmit={submitHandler}>
                <h2>Подтверждение телефона</h2>
                {errorMessage && <div className="form__error-message"><p>{errorMessage}</p></div>}
                <input 
                    type="number" 
                    className="form__field form__field_input" 
                    placeholder = 'Введите код из СМС'
                    onChange={ (e) => setCode(e.target.value)}
                />
                <label>
                    <input 
                        type="checkbox"
                        onChange={ (e) => setCheckbox(e.target.checked)}
                    />
                    <p>
                        
                        Принимаю условия <a href = '/PDFs/LicenseAgreement.pdf' target="_blank">пользовательского соглашения</a> и <a href = '/PDFs/confinential.pdf' target="_blank">политики конфиденциальности</a>
                    </p>
                </label>
                <button 
                    type = 'submit' 
                    className = {`btn form-btn_main ${code && checkbox ? 'active' : ''}`}>Подтвердить</button>
                <a href="/" className = 'form_modal-agree_link' onClick = {submitHandler}>Отправить код еще раз</a>
            </form>
        </ClickAwayListener>

    )
}

export default ChangePhone;