import {SET_FOLDER} from '../Actions/LKActions';

const LKReducer = (
    state = {
        activeFolder: 0,
    },
    action
) => {
    switch (action.type) {
        case SET_FOLDER:
            return {
                ...state,
                activeFolder: action.activeFolder
            }
        default:
            return {
                ...state
            }
    }
}


export {LKReducer};