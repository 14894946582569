import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import NavigationList from '../../Components/NavigationList/NavigationList'
import Slider from 'react-slick';
import settings from './RecipeSliderSetting'
import useResolution from '../../API/useResolution'
import './Recipe.sass'
import {apiURL} from '../../API/API'
import SliderCategoriesCard from '../../Components/SliderCategories/SliderCards/SliderCategoriesCard';

const Recipe = (props) => {

    const isMobile = useResolution();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    console.log(props.recepie.products)

    return (
        <div className="recipe">
            <NavigationList items = {[{name: 'Главная', src: '/'}, {name: 'Рецепты', src: '/recepies'}, {name: `${props.recepie.name}`, src: ''}]}/>
            <div className="recipe-top">
                <img src = {`${apiURL}${props.recepie.image}`} alt = 'img' className="recipe-top__img"/>
                <div className="recipe-top__ingredients">
                    <h2 className = 'recipe-title'>{props.recepie.name}</h2>
                    <p className = 'recipe-top__info'>Время приготовления: <span>{props.recepie.cookingTime}</span></p>
                    <p className = 'recipe-top__info'>Количество порций: <span>{props.recepie.portions} порций</span></p>
                    <h3>Ингридиенты</h3>
                    <ul className="ingredients-list">
                        {props.recepie.ingredients.map((el, i) => 
                            <li className="ingredients-list__item" key = {i}>
                                <p>{el.name}</p> 
                                <p>{el.amount} {el.unit}</p>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <div className="recipe-cook">
                    {props.recepie.steps.sort((prev, next) => prev.localId > next.localId ? 1 : -1).map((step, i) => (
                        <div className="recipe-cook__card" key = {step.id}>
                            <img src={`${apiURL}${step.image}`} className="recipe-cook__img" alt="img"/>
                            <p>{step.description}</p>
                        </div>  
                    ))}
            </div>
            <div className="recipe-video">
                <h3>Видеорецепт</h3>
                {/* <iframe width={isMobile ? '345' : '1080'} height={isMobile ? '200' : '610'}
                src="https://www.youtube.com/embed/uNT_AxXrUGs" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen/> */}
                <iframe title="video" width={isMobile ? '345' : '1080'} height={isMobile ? '200' : '610'} src={`https://www.youtube.com/embed/${new URL(props.recepie.video).search.slice(3)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>

            {!isMobile && props.recepie.products.length > 0 ?
                <div className="block block_slider block_slider_recipe-products">
                    <h3 className="block__title">Продукты этого рецепта</h3>
                    <Slider {...settings}>
                        {props.recepie.products.map(product => product.category.length &&
                        <SliderCategoriesCard 
                            folder = {product.category !== null && product.category[0].group !== null ? product.category[0].group.name : null} 
                            product = {product} 
                            key = {product.id}/>
                        )
                        }
                    </Slider>
                </div>
                : null
            }

            {!isMobile && (<div className="block cards_recepies">
                <h3 className="block__title">Еще рецепты</h3>
                <Slider {...settings(isMobile)}>
                    {props.recepieMore.paginated.map((el) => (
                        el.id !== props.recepie.id ?
                            (
                                <Link to={`/recepies/${el.name}`} className='card card_recepies' key={el.id}>
                                    <div className='card__img' key={el.id}>
                                        <img src={`${apiURL}${el.image}`} className = 'card__img' alt="img"/>
                                        <h4 className='card__title'>{el.name}</h4>
                                        <p className='card__description'>{el.cookingTime}</p>
                                    </div>
                                </Link>

                            ) : null
                    ))}
                </Slider>
            </div>)}
        </div>
    )
}

export default Recipe;