import React, { useState, useEffect } from 'react';
import './Forms.sass';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {setForm} from '../../Actions/FormActions'
import ClickAwayListener from 'react-click-away-listener';
import useResolution from "../../API/useResolution";
import Recovery from "./Recovery";
import {openModalAction} from "../../Actions/ModalActions";
import InputMask from 'react-input-mask';


const Form = ({ props, formName, state, setState, submitHandler, style, validation, errorMessage, ...rest }) => {
  const [btnActive, setBtnActive] = useState(false)
  const isMobile = useResolution();
  const form = `form ${formName}`;

  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.Form)

  const recoveryPassword = () => {
    dispatch(setForm('recovery', true))
  }
  const onClickClose = () => {
    dispatch(setForm('signin', false))
  };

  const openRecovery= () => {
    dispatch(openModalAction(Recovery, true, true))
  }

  useEffect(() => {
    let flag = true;
    for (let key in state) {
      if(key === 'checkbox' && state[key] === false) {
        flag = false
        break;
      } else if(state[key] === '') {
        flag = false;
        break;
      }
   }
   setBtnActive(flag)
   
  }, [state])


  const typeForm = () => {
    switch (formName) {
      case 'form_registration':
        return (
          <label className='form__politica form__description_first'>
            <input type='checkbox' onChange = { (e) => {
              const checkbox = e.target;
              setState((state) => ({
                ...state,
                checkbox: checkbox.checked
              }))
            }}/>
            <p>
              Принимаю условия <a href='/PDFs/licenseAgreement.pdf' target="_blank" >пользовательского соглашения</a> и{' '}
              <a href='/PDFs/confidential.pdf' target="_blank" >политики конфиденциальности</a>
            </p>
          </label>
        );

      case 'form_signin':
        return (
          <Link to='' className='form__description_first form__description_link' onClick = {() => isMobile ? openRecovery() : recoveryPassword()}>
            Напомнить пароль
          </Link>
        );

      case 'form_recovery':
        return (
          <p className='form__description form__description_first'>
            {props.firstDesc ? props.firstDesc : "На указанный адрес будет отправлено сообщение с восстановленным паролем"}
          </p>
        );
      default:
        console.log('there');
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    
  };

  const switchForm = () => {
    switch (props.link) {
      case 'Зарегистрироваться':
        dispatch(setForm('registration', true))
      break;
      case 'Войти':
        dispatch(setForm('signin', true))
        break;
      default: return;
    }
  }

  const handleClickAway = () => {
    if(formValues.open) {
      dispatch(setForm('signin', false))
    }
  };


  return (
    <div style={{maxWidth: isMobile ? '345px' : 'auto'}} className={rest.className}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <form className={`${form} ${formValues.open && !isMobile ? 'active' : ''}`} onSubmit={submitHandler}   style = {{display: props.style}}>
          <img src='/img/forms/formClose.svg' alt='closeForm' className='form-close' onClick={()=> isMobile ? rest.close() : onClickClose()} />
          <h2 className='form__title'>{props.title}</h2>
          {errorMessage && <div className="form__error-message"><p>{errorMessage}</p></div>}
          <div className='form-fields'>
            {props.fields.map((el, index) => (

              el.type !== 'phone' 
                ? 
                  <input
                    type={el.type}
                    name={el.name}
                    placeholder={el.placeholder}
                    className={`form__field ${validation(el.name, state)}`}
                    key={index}
                    onChange={changeHandler}
                  />
                :  <InputMask 
                      type = "phone" 
                      name = 'phone' 
                      className="form__field form__field_input" 
                      placeholder = '*+7' 
                      mask="+7 999 999 99 99"
                      onChange={changeHandler}
                      key={index}
                    />

            )
            )}
            {typeForm()}
          </div>
          <div className='form-bottom'>
            <button type='submit' disabled={!btnActive} className={`form-btn_main ${btnActive ? 'active form-btn' : ''}`}>
              {props.btn}
            </button>
            <div className='form__description'>
              <p>{props.secondDesc} &#160;</p>
              <p className={`form__description__link ${isMobile ? 'link' : ''}`} onClick = {() => isMobile ? props.modal() : switchForm()}>{props.link}</p>
            </div>
          </div>
        </form>
      </ClickAwayListener>
    </div>
  );
};

export default Form;
