import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux'
import {appAPI} from '../../API/API'
import SliderCategoriesCard from '../SliderCategories/SliderCards/SliderCategoriesCard';


const MainBlockCatalog = ({props}) => {
    const [products, setProducts] = useState([])
    const [flag, setFlag] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const userValues = useSelector((state) => state.User)


    useEffect(() => {
        const fetchData = async () => {
            if(userValues.isAuth) {
                try {
                    const response = await appAPI.getCategoryProducts(props.id, userValues.token);
                    console.log(response)
                    setProducts(response.data)
                    setIsFetching(true)
                } catch (err) {}
            } else {
                const response = await appAPI.getCategoryProducts(props.id);
                setProducts(response.data)
                setIsFetching(true)
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    const onClickBtn = (e) => {
        setFlag(true);
        e.currentTarget.style.display = 'none'
    }

    return(
        <>
        {isFetching && products.length !== 0 &&
        
            <div className="catalog-product">
                <h3 className="block__title">{props.name}</h3>
                <div className="recepies-cards">
                {
                    products.map((product, i) => (
                        i < 3 ?
                            <SliderCategoriesCard folder = {props.name} product = {product} cardName = {props.name} key = {product.id}/>
                        : null
                    ))
                }
                {flag &&
                    products.map((product, i) => (
                        i >= 3 ?
                            <SliderCategoriesCard folder = {props.name} product = {product} cardName = {props.name} key = {product.id}/>
                        : null
                    )
                        )
                    
                }
                </div>
                {products.length > 3 && <button className="btn form-btn catalog-main__load" onClick={onClickBtn}>Показать все товары из категории</button>}
            </div>
        }
        </>
    )
}

export default MainBlockCatalog;