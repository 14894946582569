const ADD_ADDRESS = 'ADD_ADDRESS';
const ADD_FIO = 'ADD_FIO';
const ADD_COORDINATES = 'ADD_COORDINATES'
const ADD_CART = 'ADD_CART'
const ADD_DELIVERY = 'ADD_DELIVERY'
const ADD_TYPE = 'ADD_TYPE'
const ADD_SELLER_ID = 'ADD_SELLER_ID'
const ADD_ORDER_TIME = 'ADD_ORDER_TIME'
const ADD_SBER_ID = 'ADD_SBER_ID'
const CONFIRMATION_PHONE = 'CONFIRMATION_PHONE'
const SET_ADDRESS_NOT_AUTH = 'SET_ADDRESS_NOT_AUTH'

const addAddress = (address, latitude, longitude, comment, city, deliveryPrice, addressCity, addressHome, street, buiding, porch) => {
    return {
        type: ADD_ADDRESS,
        address,
        latitude,
        longitude,
        comment,
        city,
        deliveryPrice,
        addressCity,
        addressHome,
        street,
        buiding,
        porch
    }
}

const addFio = (username, email, phone) => {
    return {
        type: ADD_FIO,
        username,
        email,
        phone
    }
}

const addCoordinates = (latitude, longitude, deliveryPrice, city, street, building, fullAddress ) => {
    return {
        type: ADD_COORDINATES,
        latitude,
        longitude,
        deliveryPrice,
        city,
        street,
        building, 
        fullAddress
    }
}

const setAddressNotAuth = (obj) => {
    return {
        type: SET_ADDRESS_NOT_AUTH,
        ...obj
    }
}

const addCart = (cartProducts) => {
    return {
        type: ADD_CART,
        cartProducts
    }
}

const addDelivery = (deliveryDate, deliveryInterval) => {
    return {
        type: ADD_DELIVERY,
        deliveryDate,
        deliveryInterval
    }
}

const addOrderTime = (time) => {
    return {
        type: ADD_ORDER_TIME,
        time
    }
}

const addType = (orderReceiving) => {
    return{
        type: ADD_TYPE,
        orderReceiving
    }
}

const addSellerId = (sellerId, phoneShop, phoneAdmin, address, shopId) => {
    return {
        type: ADD_SELLER_ID,
        sellerId,
        phoneShop,
        phoneAdmin,
        address,
        shopId
    }
}

const addSberId = (sberId) => {
    return{
        type: ADD_SBER_ID,
        sberId
    }
}

const confirmationPhone = () => {
    return{
        type: CONFIRMATION_PHONE,
    }
}

export {ADD_ADDRESS, ADD_FIO, ADD_COORDINATES, ADD_CART, ADD_DELIVERY, ADD_TYPE, ADD_SELLER_ID, ADD_ORDER_TIME, ADD_SBER_ID, CONFIRMATION_PHONE, SET_ADDRESS_NOT_AUTH}
export {addAddress, addFio, addCoordinates, addCart, addDelivery, addType, addSellerId, addOrderTime, addSberId, confirmationPhone, setAddressNotAuth}