import React from 'react';
import './CatalogProduct.sass'
import useResolution from "../../../API/useResolution";

const Description = ({props}) => {
    const isMobile = useResolution();
    return(
        <div className="product-content-block product-content-block_description">
            <div>
                <p className="catalog-product__bold">
                    Вес: 
                    <span> {props.weight}</span>
                </p>
                <p className="catalog-product__bold">
                    Страна:
                    <span> {props.country}</span>
                </p>
                <p className="catalog-product__bold">
                    Тип упаковки:
                    <span> {props.packing}</span>
                </p>
                <p className="catalog-product__bold">
                    Пищевая ценность:
                    <span> {props.foodValuation}</span>
                </p>
                <p className="catalog-product__bold">
                    Энергетическая ценность:
                    <span> {props.energyValuation}</span>
                </p>
                <p className="catalog-product__bold">
                    Состав:
                    <span> {props.weight}</span>
                </p>
                <p className="catalog-product__bold">
                    Условия хранения:
                    <span> {props.storageCondition}</span>
                </p>
            </div>
            <div style={{marginTop: isMobile ? '25px' : '0'}}>
                <p>{props.description}</p>
            </div>
        </div>
    )
}

export default Description