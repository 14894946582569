import React, {useState} from 'react';
import {appAPI} from '../../../API/API'
import DrawStars from '../../DrawStars/DrawStars';
import './CatalogProduct.sass'
import ChangeCountStars from './ChangeStarsCount';
import Review from './Review/Review';
import ReviewModal from './Review/ReviewModal';

const Reviews = ({props, id, forceRender}) => {
    props.average = (props.one + props.two * 2 + props.three * 3 + props.four * 4 + props.five * 5) / props.reviews.length;

    const [username, setUsername] = useState('')
    const [attachment, setAttachment] = useState()
    const [comment, setComment] = useState('')
    const [rate, setRate] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [flagValidation, setFlagValidation] = useState(false)

    const onChangeFile = (att) => {
        
        // formData.append('id', 1);
        setAttachment(att);
        
        console.log(att)
    }


    const submitHandler = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('productId', id);
        formData.append('username', username);
        formData.append('comment', comment);
        formData.append('rate', rate);
        formData.append('attachment', attachment);
        try {
            await appAPI.postReviews(formData)
            forceRender(prev => prev+1);
            // setModalOpen(true)
        } catch (err) {
            setFlagValidation(true)
            
        }
    }

    function validation (field) {
            switch (field) {
                case username :
                    if(field.length <= 5) return {color: 'red'}
                break;

                case comment :
                    if(field.length <= 5) return {color: 'red'}
                break;

                case rate:
                    if(field === null) return <h2>Выберите рейтинг</h2>
                break;
                default: return;
            }
    }
  



    return(
        <div className="product-content-block product-content-block_reviews">
            {modalOpen ? <ReviewModal close = {setModalOpen}/> : null}
            {props.reviews.length !== 0 ? 
            (
                <>
                <div className="product-content__block product-content__block_average">
                    <div>
                        <span className="product__average-mark">{props.average.toFixed(2)}</span>
                        <DrawStars rate = {props.average} />
                    </div>
                    <div className="product-block__reviews">
                        {props.reviews.map((review) => (
                            <Review props = {review} key = {review.id}/>
                        ))}
                    </div>
                </div>
                
            </>

            ) : 
            (
                <>
                    <div className="product-content__block product-content__block_average">
                        <div>
                            <span className="product__average-mark">0.0</span>
                            <DrawStars rate = {0} />
                        </div>
                        <div className="product-block__reviews">
                            <h4>У данного товара нет отзывов</h4>
                        </div>
                    </div>
                    
                </>
            )
            }
            <div className="product-content__block product-content__block_form">
                    <div>
                        <ChangeCountStars setRate = {setRate}/>
                        {flagValidation ? validation(rate) : null}
                    </div>
                    <form className="product-review__form" onSubmit={submitHandler}>
                        <input 
                            type="text"
                            name = 'name'
                            className="form-field form-field_input" 
                            placeholder = 'Введите ваше имя'
                            onChange={(e) => setUsername(e.target.value)}
                            style = {validation(username)}
                            />
                        <textarea 
                            name="textarea" 
                            className="form-field form-field_textarea" 
                            placeholder = 'Текст отзыва здесь'
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <div className="form-field__media">
                            <label className = "label-file">
                                <input 
                                    type="file"
                                    name = 'file'
                                    accept="image/*, video/*"
                                    onChange={(e) => onChangeFile(e.target.files[0])}
                                />
                                <img src="/img/skrepka.svg" alt="download"/>
                                {attachment ? 
                                (attachment.type.includes('image') ? <img alt="" className="label-img" src={URL.createObjectURL(attachment)}/> : <p>Видео загружено</p>)
                                : <p>Загрузите фото или видео</p>}
                            </label>
                            
                        </div>
                        <button className="form-btn btn" type="submit">Оставить отзыв</button>
                    </form>    
                </div>

        </div>
    )
}

export default Reviews