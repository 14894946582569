import React from 'react';
import SliderArrows from '../../SliderCategories/Slider/SliderArrows';

const settings = (isMobile) => {
  return {
    dots: isMobile,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    arrows: !isMobile,
    // centerMode: isMobile,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 6000,
    swipeToSlide: isMobile,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }],
    prevArrow: <SliderArrows src='/img/slider/arrowPrevSlider.svg' srcNew={'/img/slider/arrowPrevRed.svg'}/>,
    nextArrow: <SliderArrows src='/img/slider/arrowNextSlider.svg' srcNew={'/img/slider/arrowNextRed.svg'}/>,
    lazyLoad: true,
    appendDots: dots => (
        <div>
          {dots.slice(0, 7)}
        </div>
    )
  }
};

export default settings;
