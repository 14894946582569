const ADD_ITEM = 'ADD_ITEM';
const DEL_ITEM = 'DEL_ITEM';
const SET_CART = 'SET_CART';
const RENDER_CART = 'RENDER_CART';
const ADD_ITEMS = 'ADD_ITEMS';
const CHANGE_ITEMS = 'CHANGE_ITEMS';
const DEL_ITEMS = 'DEL_ITEMS';
const CLEAR_CART = 'CLEAR_CART';
const ADD_ITEMS_ALL = 'ADD_ITEMS_ALL';
const RERENDER_FAVOURITE = 'RERENDER_FAVOURITE';
const SET_CART_ID = 'SET_CART_ID'

const addItem = (summaCostItem, summaCountItem ) => {
    return {
        type: ADD_ITEM,
        summaCostItem,
        summaCountItem
    }
}

const delItem = (summaCostItem, summaCountItem) => {
    return {
        type: DEL_ITEM,
        summaCostItem,
        summaCountItem
    }
}

const setProductsCart = (summa, weight) => {
    return {
        type: SET_CART,
        summa,
        weight
    }
}

const rerenderCart = (index) => {
    return {
        type: RENDER_CART,
        index
    }
}

const addItemsAll = (items) => {
    return {
        type: ADD_ITEMS_ALL,
        items
    }
}

const addItems = (item) => {
    return {
        type: ADD_ITEMS,
        item
    }
}

const changeItems = (index, item) => {
    return{
        type: CHANGE_ITEMS,
        index,
        item
    }
}

const delItems = (id) => {
    return {
        type: DEL_ITEMS,
        id
    }
}

const clearCart = (id) => {
    return {
        type: CLEAR_CART,
    }
}

const rerenderFavourite = () => {
    return {
        type: RERENDER_FAVOURITE,
    }
}

const setCartId = (id) => {
    return {
        type: SET_CART_ID,
        id
    }
}

export {ADD_ITEM, DEL_ITEM, SET_CART, RENDER_CART, ADD_ITEMS, CHANGE_ITEMS, DEL_ITEMS, ADD_ITEMS_ALL, RERENDER_FAVOURITE, SET_CART_ID, CLEAR_CART}
export {addItem, delItem, setProductsCart, rerenderCart, addItems, changeItems, delItems, addItemsAll, rerenderFavourite, setCartId, clearCart}