import React from 'react';

const SliderArrowNext = (props) => {
  const { className, onClick } = props;
  
  return (
    <div onClick={onClick} className={className}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.96766 21.531C6.89781 21.4613 6.8424 21.3786 6.80459 21.2875C6.76678 21.1963 6.74732 21.0987 6.74732 21C6.74732 20.9014 6.76678 20.8037 6.80459 20.7126C6.8424 20.6214 6.89781 20.5387 6.96766 20.469L15.4382 12L6.96766 3.53101C6.82683 3.39017 6.74771 3.19917 6.74771 3.00001C6.74771 2.80084 6.82683 2.60983 6.96766 2.46901C7.10849 2.32817 7.2995 2.24906 7.49866 2.24906C7.69782 2.24906 7.88883 2.32817 8.02966 2.46901L17.0297 11.469C17.0995 11.5387 17.1549 11.6214 17.1927 11.7126C17.2305 11.8037 17.25 11.9014 17.25 12C17.25 12.0987 17.2305 12.1963 17.1927 12.2875C17.1549 12.3786 17.0995 12.4613 17.0297 12.531L8.02966 21.531C7.95999 21.6008 7.87723 21.6563 7.78611 21.6941C7.69499 21.7319 7.59731 21.7513 7.49866 21.7513C7.40001 21.7513 7.30233 21.7319 7.21121 21.6941C7.12009 21.6563 7.03733 21.6008 6.96766 21.531Z" fill="#6586B8"/>
            <path d="M17.4075 10.7675L7.88117 2.43326C6.75859 1.4497 5 2.24906 5 3.74178L5 20.4102C4.99971 20.7443 5.09573 21.0713 5.27655 21.3522C5.45736 21.6331 5.71532 21.856 6.01952 21.994C6.32372 22.1321 6.66128 22.1796 6.99176 22.1307C7.32224 22.0819 7.63164 21.9389 7.88291 21.7187L17.4057 13.3845C17.5924 13.2214 17.742 13.0202 17.8445 12.7946C17.947 12.5689 18 12.3239 18 12.076C18 11.8281 17.947 11.5831 17.8445 11.3574C17.742 11.1317 17.5924 10.9306 17.4057 10.7675H17.4075Z" fill="#6586B8"/>
        </svg>
    </div>
  )
};

export default SliderArrowNext;
