import React, {useEffect} from 'react'
import './OrderAccept.sass'
import NavigationList from '../../Components/NavigationList/NavigationList'

const OrderAccept = (props) => {

    var qr={};
    window.location.search.substring(1).split("&").forEach(p => { qr[p.split("=")[0]] = p.split("=")[1] })

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         await appAPI.confirmationOrder(sberId);
        //     } catch (err) {
        //         console.error(err)
        //     } finally {
        //         Cookies.set('sberbankId', '')
        //     }
        // }
        // fetchData();
        // localStorage.clear();
        // dispatch(clearCart());
    }, [])

    return(
        <div className="container container_accept">
            <NavigationList items = {[{name: 'Главная', src: '/'}, {name: 'Корзина', src: '/cart'}, {name: 'Оформление заказа', src: ''}]}/>
            <div className="page-orderAccept">
                <h2>Спасибо, заказ № принят </h2>
                <ul>
                    <li>
                        <img src="/img/arrowSuccess.svg" alt="img"/>
                        <p>Контактные данные</p>
                    </li>
                    <li>
                        <img src="/img/arrowSuccess.svg" alt="img"/>
                        <p>Способ получения</p>
                    </li>
                    <li>
                        <img src="/img/arrowSuccess.svg" alt="img"/>
                        <p>Подтверждение и оплата</p>
                    </li>
                </ul>
                <p>Письмо с подтверждением заказа было отправлено на <span>{qr.email} </span>  
                    О ходе выполнения заказа вы будете проинформированы через SMS.</p>
            </div>
        </div>
    )

}

export default OrderAccept;