import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckAuth } from './Actions/UserActions';
import './styles/App.sass';
import './styles/Reset.sass';
import { Route, Redirect, Switch } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import SignIn from './Components/Forms/SignIn';
import Main from './pages/Main';
import AboutUs from './pages/AboutUs/AboutUs';
import Job from './pages/Job/Job';
import Delivery from './pages/Delivery/Delivery';
import { appAPI } from './API/API';
import Contacts from './pages/Contacts/Contats';
import Catalog from './pages/Catalog/Catalog';
import Registration from './Components/Forms/Registration';
import Recepies from './pages/Recepies/Recepies';
import Shops from './pages/Shops/Shops';
import CartPage from './pages/CartPage/CartPage';
import Error404 from './pages/Errors/Error404';
import Error502 from './pages/Errors/Error502';
import Reviews from './pages/Reviews/Reviews';
import LKUser from './pages/LK/LKUser/LKUser';
import {userData} from './Actions/UserActions'
import 'dayjs/locale/ru'
import Recovery from './Components/Forms/Recovery';
import Reset from './Components/Forms/Reset';
import LicenseAgreement from './pages/LicenseAgreement';
import MainRecommend from './Components/Main/MainRecommend/MainRecommend';
import MainNew from './Components/Main/MainNew/MainNew';
import FormReview from './Components/Forms/FormReview';
import useResolution from "./API/useResolution";
import CustomModal from "./Components/Modal/Modal";
import Search from './pages/Search/Search'
import FormModalAgree from './Components/Forms/FormModalAgree';
import OrderAccept from './pages/OrderAccept/OrderAccept';
import ChangePassword from './Components/Forms/ChangePassword';
import Cookies from 'js-cookie';
import CatalogProduct from './pages/Catalog/CatalogProduct/CatalogProduct';
import ChangePhone from './Components/Forms/ChangePhone';

function App() {

  const dispatch = useDispatch();
  const { isAuth, checkAuth } = useSelector((state) => state.User);
  const isMobile = useResolution();
  const sberId = Cookies.get('sberbankId');

  const [userEmail, setUserEmail] = useState({
    email: '',
  });
  
  useEffect(() => {
    dispatch(CheckAuth());
    const fetchData = async () => {
      if(isAuth) {
        const response = await appAPI.getMe()
        dispatch(userData(response.role, response.phone, response.email, response.birthday))
      }
      //dispatch(setProductsCart(0,0))
    }
    fetchData();
    // eslint-disable-next-line
  }, [isAuth]);
  const [summaItems, setSummaItems] = useState(0);

  const activeForm = useSelector((state) => state.Form)

  const switchActiveForm = () => {
    switch (activeForm.activeForm) {
      case 'signin': 
        return <SignIn/>
      case 'recovery': 
      // eslint-disable-next-line
        return <Recovery style = 'flex' setUserEmail={setUserEmail} userEmail={userEmail}/>
      case 'reset': 
      // eslint-disable-next-line
        return <Reset style = 'flex' userEmail={userEmail.email}/>
      case 'registration':
        // eslint-disable-next-line
        return <Registration style = 'flex'/>
      case 'review':
        // eslint-disable-next-line
        return <FormReview style = 'flex'/>
      case 'phoneCode':
        return <FormModalAgree/>
      case 'changePassword':
        return <ChangePassword/>
      case 'confirmPhone':
        return <FormModalAgree type = 'userPhone'/>
      case 'changePhone':
        return <ChangePhone/>
      default:
        return <div></div>
    }
  }

  useEffect(() => {
    window.scroll(0,0)
    const fetchCart = async () => {
      const defaultProduct = JSON.parse(localStorage.getItem('defaultProduct'))
      if (!defaultProduct) {
        const defaultProduct = await appAPI.getDefaultProduct();
        localStorage.setItem('defaultProduct', JSON.stringify(defaultProduct));
      }
    }
    fetchCart();
  }, [])


  
  return (
    <div>
      {checkAuth ? (
        <>
          { isAuth  ? (
                <>
                <Header summaItems = {summaItems} setSummaItems={setSummaItems}/>
                {!isMobile && switchActiveForm(activeForm)}
                <CustomModal />
                <Switch>
                  <Route exact path='/'>
                    <Main />
                  </Route>
                  <Route path='/aboutUs'>
                    <AboutUs />
                  </Route>
                  <Route path='/job'>
                    <Job />
                  </Route>
                  <Route path='/delivery'>
                    <Delivery />
                  </Route>
                  <Route path='/contacts'>
                    <Contacts />
                  </Route>

                  <Route exact path = '/catalog/products/:id' 
                    render = { props =>
                      <>
                        <CatalogProduct id={props.match.params.id}/>
                        {!isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                        )}
                      </>
                    }>
                  </Route>

                  <Route exact path = '/catalog/products/:id' component = {CatalogProduct}/>
                  <Route exact path = '/catalog/products/:id' component = {MainRecommend}/>

                  <Route path='/shops'>
                    <Shops />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/recepies'>
                    <Recepies />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/catalog'>
                    <Catalog />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/cart'>
                    <CartPage summaItems = {summaItems} setSummaItems={setSummaItems}/>
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route exact path = '/reviews'>
                    <Reviews/>
                  </Route>
                  <Route exact path = '/search'>
                    <Search/>
                  </Route>
                  {sberId &&
                    <Route exact path = '/order-accept'>
                      <OrderAccept/>
                    </Route>
                  }
                  <Route path = '/404'>
                    <Error404/>
                  </Route>
                  <Route path = '/502'>
                    <Error502/>
                  </Route>
                  <Route exact path = '/lk'>
                    <LKUser/>
                  </Route>
                  <Route>
                      <Redirect to = '/404'/>
                  </Route>
                </Switch>
                <Footer />
              </>
          ) : (
              <Route path = '/' >
                <Header summaItems = {summaItems} setSummaItems={setSummaItems}/>
                {!isMobile && switchActiveForm(activeForm)}
                <CustomModal />
                <Switch>
                  <Route exact path='/'>
                    <Main />
                  </Route>
                  <Route exact path='/register'>
                    <Main  />
                  </Route>
                  <Route path='/aboutUs'>
                    <AboutUs />
                  </Route>
                  <Route path='/job'>
                    <Job />
                  </Route>
                  <Route path='/delivery'>
                    <Delivery />
                  </Route>
                  <Route path='/contacts'>
                    <Contacts />
                  </Route>

                  <Route exact path = '/catalog/products/:id' 
                    render = { props =>
                      <>
                        <CatalogProduct id={props.match.params.id}/>
                        {!isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                        )}
                      </>
                    }>
                  </Route>
                  
                  <Route path='/shops'>
                    <Shops />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/recepies'>
                    <Recepies />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/catalog'>
                    <Catalog />
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route path='/cart'>
                    <CartPage summaItems = {summaItems} setSummaItems={setSummaItems}/>
                    {
                      !isMobile && (
                          <>
                            <MainRecommend/>
                            <MainNew/>
                          </>
                      )
                    }
                  </Route>
                  <Route exact path = '/reviews'>
                    <Reviews/>
                  </Route>
                  <Route exact path = '/search'>
                    <Search/>
                  </Route>
                  {sberId &&
                    <Route exact path = '/order-accept'>
                      <OrderAccept/>
                    </Route>
                  }
                  <Route path = '/404'>
                    <Error404/>
                  </Route>
                  <Route path = '/502'>
                    <Error502/>
                  </Route>
                  <Route exact path = '/license-agreement'>
                    <LicenseAgreement/>
                  </Route>
                  <Route>
                        <Redirect to = '/404'/>
                  </Route>
                </Switch>
                <Footer />
              </Route>
          )
        }
        </>
    ): null}
    </div>
  );
}

export default App;
