import React, { useState, useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { appAPI } from '../../API/API';
import Shop from './Shop/Shop';
import './Shops.sass';
import ShopLink from './ShopLink/ShopLink';

const Shops = (props) => {
  let { path } = useRouteMatch();

  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false)

    const fetchData = async () => {
      try{
        const result = await appAPI.getShops();
        setData(result.data);
        setIsFetching(true)
      } catch(err) {
        
      }
    };


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  return (
    <div className='shops'>
      {isFetching ? (
        data.map((el) => (
          <ShopLink key={el.id} props={el} />
        )) ) : null}
      {isFetching ? (
        data.map((el) => (
          <Route path={`${path}/${el.id}`} key={el.id}>
            <Shop props={el} />
          </Route>
        ))
      ): null}
    </div>
  );
};

export default Shops;
