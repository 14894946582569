import React, {useState } from 'react';
import Form from './Form';
import { appAPI } from '../../API/API';
import './Forms.sass';
import {openModalAction} from "../../Actions/ModalActions";
import FormModalAgree from './FormModalAgree'
import {setRegistrationToken} from '../../Actions/UserActions'
import {setForm} from '../../Actions/FormActions'
import SignIn from "./SignIn";
import {useDispatch} from "react-redux";
import useResolution from "../../API/useResolution";

const Registration = ({close, ...props}) => {
  const dispatch = useDispatch();
  const isMobile = useResolution();

  const openSignIn = () => {
    dispatch(openModalAction(SignIn, {}, true))
  }
  const formRegistration = {
    title: 'Регистрация',
    fields: [
      {
        type: 'email',
        placeholder: 'Введите e-mail',
        name: 'email',
      },
      {
        type: 'phone',
        placeholder: 'Введите телефон',
        name: 'phone'
      },
      {
        type: 'password',
        placeholder: 'Введите пароль',
        name: 'password',
      },
      {
        type: 'password',
        placeholder: 'Повторите пароль',
        name: 'passwordRepeat'
      }
    ],
    btn: 'Зарегистрироваться',
    firstDesc: 'Принимаю условия пользовательского соглашения и политики конфедициальности',
    secondDesc: 'У вас уже есть личный кабинет?',
    link: 'Войти',
    src: '/',
    modal: openSignIn,
  };

  const [user, setUser] = useState({
    password: '',
    email: '',
    phone: '',
    passwordRepeat: '',
    checkbox: false
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    let str = user.phone.slice(1);
    let strArr = str.split(' ');
    str = strArr.join('')
    if(user.password === user.passwordRepeat) {
      try{
        const response = await appAPI.registerUser({email: user.email, password: user.password, phone: str});

        sessionStorage.setItem('userRegistration', JSON.stringify({email: user.email, password: user.password, phone: str}));

        dispatch(setRegistrationToken(response.data.registrationToken))
        if (isMobile) dispatch(openModalAction(FormModalAgree, {}, true))
        else dispatch(setForm('phoneCode', true))
      } catch(err) {
        setFlagValidation('Введённый логин или номер телефона уже используются!')
      }
    } else (setFlagValidation('Пароли должны совпадать!'))
  
  };

  const [flagValidation, setFlagValidation] = useState(null)

  const validation = (name, value) => {
    if(flagValidation) {
      switch(name) {
        case 'email':
          if(/[\w.%+-]+@[A-Za-z\d.-]+\.\w+/.test(value.email)) { return ''} else return 'active'
        case 'password':
          
          if (value.password.length < 3) { return 'active'} else return ''
        default: return;
      }
    }
  }

  return (
    <Form
      formName={'form_registration'}
      props={formRegistration}
      state={user}
      errorMessage={flagValidation}
      setState={setUser}
      submitHandler={submitHandler}
      validation = {validation}
      close={close}
      {...props}
    />
  );
};

export default Registration;
