import { appAPI, setTokenForAPI } from '../API/API';
import Cookies from 'js-cookie';

const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const FETCHED = 'USER/FETCHED';
const CHECK_AUTH = 'USER/CHECK_AUTH';
const SET_TOKEN = 'USER/SET_TOKEN';
const USER_DATA = 'USER_DATA';
const SET_REGISTRATION_TOKEN = 'SET_REGISTRATION_TOKEN';
const SET_AUTH = 'SET_AUTH'

const toggleFetch = (status, msg = null) => {
  return {
    type: FETCHED,
    fetched: status,
    error: msg,
  };
};
const checkAuthToggle = (status) => {
  return {
    type: CHECK_AUTH,
    status,
  };
};
const setSuccessToken = (token) => {
  return {
    type: SET_TOKEN,
    token,
  };
};

const setToken = (token) => async (dispatch) => {
  dispatch(setSuccessToken(token));
  setTokenForAPI(token);
};

const CheckAuth = () => (dispatch) => {
  const token = Cookies.get('access-token');
  const username = Cookies.get('username');
  if (token && typeof token !== 'undefined' && token !== 'undefined') {
    dispatch(setToken(token));
    dispatch({
      type: LOGIN_USER,
      token,
      username,
      isAuth: true,
    });
  }
  dispatch(checkAuthToggle(true));
};

const loginUser = (username, password) => async (dispatch) => {
  dispatch(toggleFetch(true));
  let data = await appAPI.loginUser(username, password);
  let token = data.authorizationToken;
  localStorage.setItem('cart', '[]')
  if (token) {
    Cookies.set('access-token', token, {});
    await dispatch(CheckAuth());
  }
};

const logoutUser = async (dispatch) => {
  Cookies.remove('access-token');
  Cookies.remove('username');
  localStorage.setItem('cart', '[]')
  await dispatch({
    type: LOGOUT_USER,
  });
  window.location.href = "/";
};

const userData = (role, phone, email, birthday) => {
  return {
    type: USER_DATA,
    role,
    phone,
    email,
    birthday
  }
}

const setRegistrationToken = (token) => {
  return {
    type: SET_REGISTRATION_TOKEN,
    token
  }
}

const setAuth = (auth) => {
  return {
    type: SET_AUTH,
    auth
  }
}

export { LOGIN_USER, LOGOUT_USER, FETCHED, CHECK_AUTH, USER_DATA, SET_REGISTRATION_TOKEN, SET_AUTH };

export { loginUser, logoutUser, CheckAuth, userData, setRegistrationToken, setAuth };
