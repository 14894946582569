import React, {useState, useRef} from 'react';
import {setProductList} from '../../Actions/SearchActions'
import {appAPI} from '../../API/API'
import {useSelector, useDispatch} from 'react-redux'
import './Header.sass';
import SearchShow from './SearchShow/SearchShow';
import ClickAwayListener from 'react-click-away-listener';

const Search = () => {
  
  const {token} = useSelector((state) => state.User)
  const dispatch = useDispatch();
  const [openShow, setOpenShow] = useState(false)
  const inputSearch = useRef('');
  
  const changeHandler = async e => {
    if (e.target.value !== '') {
      try{
        const response = await appAPI.searchProducts(token, e.target.value)
        dispatch(setProductList(response.data))
        response.data.length > 0 ? setOpenShow(true) : setOpenShow(false)
      } catch(err) {
      }
    } else {
      dispatch(setProductList([]))
      setOpenShow(false)
    }
  }

  return (
      <div className='search'>
        <ClickAwayListener onClickAway={() => {setOpenShow(false); }}>
          <input 
            type='text' 
            placeholder='Поиск...' 
            onChange = {changeHandler}
            ref={inputSearch}
          />
          {openShow && <SearchShow setOpenShow = {setOpenShow}/>}
        </ClickAwayListener>
      </div>
  );
};

export default Search;
