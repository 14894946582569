import React from "react";
import {useDispatch} from "react-redux";
import {AccordionCaret} from "../../assets/AccordionCaret";
import {Link} from "react-router-dom";
import {openModalAction} from "../../Actions/ModalActions";

const SubMenu = ({history, currentModalNumber, close, needCaret, id = '', ...props}) => {

    const dispatch = useDispatch();

    const openNextSection = (subList, parentId) => {
        dispatch(openModalAction(
            SubMenu,
            {
                history: !history[currentModalNumber+1] 
                    ? [...history, {list: subList, prevModal: SubMenu}] 
                    : [
                        ...history.slice(0, currentModalNumber+1),
                        {list: subList, prevModal: SubMenu},
                        ...history.slice(currentModalNumber+2)
                    ],
                currentModalNumber: currentModalNumber + 1,
                needCaret: needCaret,
                id: id + '/' + parentId
            },
            true
        ))
    }
    

    const openPrevSection = () =>  {
        dispatch(openModalAction(
            history[currentModalNumber].prevModal,
            {
                currentModalNumber: currentModalNumber - 1,
                needCaret: needCaret,
                history: [
                    ...history.slice(0, currentModalNumber),
                    {list: history[currentModalNumber].list, prevModal: SubMenu},
                    ...history.slice(currentModalNumber+1)
                ],
                // eslint-disable-next-line
                id: id.slice(0, id.indexOf(id.match(/\/[\w\-]+/g).pop()))
            },
            true
        ))
    }

    return (
        <div className={`submenu ${props.className}`}>
            <div className="submenu__buttons">
                <div onClick={openPrevSection}>
                    {
                        (currentModalNumber >= 1 || needCaret) && <AccordionCaret fillColor="#EF6752" rotate='90deg' />
                    }
                </div>
                <div onClick={close}>
                    <img src='/img/forms/formClose.svg' alt='close' className='close' />
                </div>
            </div>
            <div className="submenu__items">
                {
                    history[currentModalNumber].list.map(item => (
                        <div key={item.id} className="submenu__item" onClick={() => {
                            if (item.categories || (item.subcategories && item.subcategories.length)) {
                                openNextSection(item.categories ? item.categories : item.subcategories, item.id)
                            }
                        }}>
                            {
                                item.categories || (item.subcategories && item.subcategories.length)
                                    ? <><span>{item.name || item.title}</span><AccordionCaret fillColor="black" rotate="-90deg"/></>
                                    : <Link to={`/catalog/categories${id}`} onClick={close}>{item.name || item.title}</Link>
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SubMenu;