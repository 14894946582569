import React from 'react';
import DrawStars from '../../../DrawStars/DrawStars';
import './Review.sass'
import moment from "moment";

const Review = ({props}) => {
    return(
        <div className = 'product-review'>
            <div className="product-review__top">
                <div>
                    <h5 className="product-review__title">{props.username}</h5>
                    <DrawStars rate = {props.rate}/>
                </div>
                <div>
                    <p>{moment(props.createdAt).format('LLL')}</p>
                </div>
            </div>
            <div className="product-review__bottom">
                {props.comment}
            </div>
        </div>
    )
}

export default Review;