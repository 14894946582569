import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './CatalogMenu.sass';

const CatalogMenu = ({groups, menuSwitchHandler, setMenuSwitchHandler, menuConstruction}) => {

  const clickHandler = filterName => setMenuSwitchHandler(filterName)

  useEffect(() => {
    if(menuSwitchHandler) {
      const fetchData = async () => {
        // await appAPI.getCategoriesByGroup(switchHandler.id)
        //   .then(response => setActiveMenuCategories(response.data))
        // await appAPI.getCategoryProducts(menuSwitchHandler.id, token)
        //   .then(response => console.log(response.data))
      }
      fetchData();
    }
  },[menuSwitchHandler])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ul className='catalog-menu'>
      <li className='catalog-menu__item'>
        <NavLink activeClassName = 'active' className = 'catalog-menu__link' to = {`/catalog/sales`}>
          <p>Скидки</p>
        </NavLink>
      </li>
      <li className='catalog-menu__item'>
        <NavLink activeClassName = 'active' className = 'catalog-menu__link' to = {`/catalog/news`}>
          <p>Новинки</p>
        </NavLink>
      </li>
      {menuConstruction.map((group, i) => (
        <li
          className={`catalog-menu__item`}
          onClick={() => clickHandler(group)}
          key={i}>
          <NavLink 
            className='catalog-menu__link' 
            activeClassName = 'active' 
            to={`/catalog/categories/${group.id}/?title=${group.name}`} 
            key = {group.id}
            ><p>{group.name}</p>
          </NavLink>
            <ul className = 'catalog-menu__categories'>
              {group.categories.map(category => 
                <React.Fragment key = {category.id}>
                  <NavLink 
                    className = 'catalog-menu__categories__link' 
                    activeClassName = 'active' 
                    to = {`/catalog/categories/${group.id}/${category.id}?title=${category.title}`}>
                    <li className='catalog-menu__categories__item'>{category.title}</li>
                  </NavLink>
                  <ul>
                    {
                      category.subcategories.map(subcategory => 
                        !subcategory.empty &&
                          <NavLink 
                            className = 'catalog-menu__categories__link catalog-menu__categories__link_sublink' 
                            to = {`/catalog/categories/${group.id}/${category.id}/${subcategory.id}?title=${subcategory.name}`}
                            key = {subcategory.id}
                            activeClassName = 'active'>
                            <li className='catalog-menu__categories__item catalog-menu__categories__item_sublink'>{subcategory.name}</li>
                          </NavLink>)
                    }
                  </ul>
                </React.Fragment>
                )}
            </ul>
        </li>
      ))}
    </ul>
  );
};

export default CatalogMenu;
