import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { appAPI } from '../../API/API';
import CatalogMenu from '../../Components/Catalog/CatalogMenu/CatalogMenu';
import './Catalog.sass';
import CatalogNews from '../../pages/Catalog/CatalogNews/CatalogNews';
import CatalogMain from '../../Components/Catalog/CatalogMain/CatalogMain';
import CatalogCategory from '../../Components/Catalog/CatalogCards/CatalogCategory/CatalogCategory';
import CatalogSales from './CatalogSales/CatalogSales';
import NavigationList from '../../Components/NavigationList/NavigationList';
import CatalogCategoryPage from './CatalogCategory/CatalogCategory';
import {useDispatch} from 'react-redux';
import useResolution from "../../API/useResolution";
import Categories from "../../Components/Categories/Categories";
import SubMenu from "../../Components/Header/SubMenu";
import {openModalAction} from "../../Actions/ModalActions";
import CatalogSubcategoryPage from './CatalogCategory/CatalogSubcategory';

const Catalog = () => {

  const isMobile = useResolution();
  const dispatch = useDispatch();
  const [data] = useState([]);
  const [menuConstruction, setMenuConstruction] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [groups] = useState([])
  const [menuSwitchHandler, setMenuSwitchHandler] = useState(null);

  const openCatalog = () => {
    dispatch(openModalAction(
        SubMenu,
        {history: [{list: menuConstruction, prevModal: SubMenu}], currentModalNumber: 0},
        true
    ))
  }

  useEffect(() => {

    window.scrollTo(0, 0);

    const fetchData = async () => {
      
        // await appAPI.getProductGroupsCat()
        //   .then(response => setGroups(response.data))

        await appAPI.getMenuConstruction()
          .then(response => setMenuConstruction(response.data))

        // userValues.isAuth 
        //   ? await appAPI.getProductGroups(userValues.token).then(result => setData(result)) 
        //   : await appAPI.getProductGroups().then(result => setData(result.data))

        setIsFetching(true)

    };
    fetchData();
  }, []);

  const CategoriesBtn = <button className="categories__btn" onClick={openCatalog}>Категории</button>


  return (
    <section className='section_catalog'>
          <div className='container container_catalog'>
          {isFetching ?
          (
          <>
          <Switch>
            <Route path='/catalog'>
              <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: ''}]}/>
              {isMobile && CategoriesBtn}
              <div className='catalog-content'>
                {
                  isMobile
                    ? <Route exact path='/catalog'>
                        <Categories />
                      </Route>
                    : (
                        <>
                          <CatalogMenu menuConstruction = {menuConstruction} groups={groups} menuSwitchHandler = {menuSwitchHandler} setMenuSwitchHandler = {setMenuSwitchHandler}/>
                          <Route exact path='/catalog'>
                            <CatalogMain groups = {menuConstruction}/>
                          </Route>
                        </>
                      )
                }
                <Route exact path='/catalog/categories/:id' component={CatalogCategory}/>
                <Route exact path='/catalog/categories/:groupId/:categoryId' component = {CatalogCategoryPage}/>
                <Route exact path='/catalog/categories/:groupId/:categoryId/:subcategoryId' component = {CatalogSubcategoryPage}/>
                <Route exact path='/catalog/news'>
                  <CatalogNews groups = {menuConstruction}/>
                </Route> 
                <Route exact path='/catalog/sales'>
                  <CatalogSales groups = {menuConstruction}/>
                </Route>
              </div>
            </Route>



            {/* {data.map((el, i) => (
              <Route exact path={`/catalog/${el.name}`} key = {el.id}>
                <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: el.name, src: ''}]}/>
                {isMobile && (
                    <>
                      {CategoriesBtn}
                      <h3>{el.name}</h3>
                    </>
                )}
                <div className='catalog-content'>
                  {!isMobile && <CatalogMenu data={data}/>}
                  <CatalogCategory data={el}/>
                </div>
              </Route>
            ))}

            {data.map((el) => (
              el.categories.map((category) => (
                <Route exact path = {`/catalog/${el.name}/${category.title}`} key = {category.key}>

                  <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: el.name, src: ''}]}/>
                  {isMobile && CategoriesBtn}
                  <div className='catalog-content'>
                    {!isMobile && <CatalogMenu data = {data}/> }
                    <CatalogCategoryPage category = {category} elName = {el.name}/>
                  </div>                    
                </Route>
              ))
            ))}

            {data.map(el => (
              el.categories.map(category => (
                category.subcategories.map(subcategory => 
                  !subcategory.empty &&
                  <Route path = {`/catalog/${el.name}/${category.title}/${subcategory.name}`}>
                    <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: el.name, src: ''}]}/>
                    {isMobile && CategoriesBtn}
                    <div className='catalog-content'>
                      {!isMobile && <CatalogMenu data = {data}/> }
                      <CatalogSubcategoryPage subcategory = {subcategory} elName = {el.name}/>
                    </div>
                  </Route>
                )
              ))))} */}

            <Route exact path='/catalog/sales'>
              <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: 'Скидки', src: ''}]}/>
                {isMobile && CategoriesBtn}
                <div className='catalog-content'>
                {!isMobile && <CatalogMenu data = {data}/> }
                  <CatalogSales data = {data}/>
                </div>
            </Route>

            <Route exact path='/catalog/news'>
              <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Каталог', src: '/catalog'}, {name: 'Новинки', src: ''}]}/>
                {isMobile && CategoriesBtn}
                <div className='catalog-content'>
                {!isMobile && <CatalogMenu data = {data}/> }
                  <CatalogNews data = {data}/>
                </div>
            </Route>

            <Route path = '*'>
              <Redirect to = '/catalog'/>
            </Route>
          </Switch>
          </>

          ) : null
          }
        </div>
    </section>
  )

};

export default Catalog;
