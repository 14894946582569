import React from 'react';

const SliderArrowPrev = (props) => {
  const { className, onClick} = props;

  return (
    <div onClick={onClick} className={className}>
        <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.592534 11.3819L10.1188 19.7162C11.2414 20.6997 13 19.9004 13 18.4076V1.73923C13.0003 1.40516 12.9043 1.07809 12.7235 0.797181C12.5426 0.516275 12.2847 0.293443 11.9805 0.155369C11.6763 0.0172954 11.3387 -0.0301664 11.0082 0.0186672C10.6778 0.0675009 10.3684 0.21056 10.1171 0.430712L0.594272 8.76491C0.407622 8.92802 0.258026 9.12917 0.155528 9.35486C0.0530292 9.58055 0 9.82556 0 10.0734C0 10.3213 0.0530292 10.5663 0.155528 10.792C0.258026 11.0177 0.407622 11.2188 0.594272 11.3819H0.592534Z" fill="#6586B8"/>
        </svg>
    </div>
  )
};

export default SliderArrowPrev;
