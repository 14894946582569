import React from 'react'
import ReviewsItems from '../../Components/Reviews/ReviewsItems/ReviewsItems'
import ReviewsStars from '../../Components/Reviews/ReviewsStars/ReviewsStars'
import './Reviews.sass'
import NavigationList from '../../Components/NavigationList/NavigationList';

const Reviews = () => {

    return( 
        <section className = 'section section_reviews'>
            <div className="container container_reviews">
                <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Отзывы', src: ''}]} />
                <h2>Отзывы</h2>
                <div className="reviews-content">
                        <ReviewsStars/>
                    <div className= "reviews-content__right">
                        <ReviewsItems/>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default Reviews;