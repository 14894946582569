import React, {useState, useEffect}  from 'react';
import './CatalogSales.sass'
import { useSelector } from 'react-redux';
import { appAPI } from '../../../API/API';
import CatalogNewsFilter from '../CatalogNews/CatalogNewsFilter';

const CatalogSales = ({groups}) => {

    const {token} = useSelector(state => state.User)
    const [products, setProducts] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            await appAPI.getProductsPromotional(token)
                .then(response => setProducts(response.data))
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    return(
        <section className = 'section_sales'>
            <div className="catalog catalog_sales">
                <div className="catalog-product" style = {{maxWidth: '825px'}}>
                    <CatalogNewsFilter products = {products} filterItems = {groups}/>
                {/* <CatalogFilterName data = {props.data} page = {'sales'}/> */}
                </div>

            </div>
        </section>
    )
}

export default CatalogSales;