import React, {useState, useEffect} from 'react';
import {appAPI} from '../../API/API'
import '../Menu/Menu.sass';
import {useDispatch} from "react-redux";
import { Link } from 'react-router-dom';
import {AccordionCaret} from "../../assets/AccordionCaret";
import CustomAccordion from "../Accordion/Accordion";
import {closeModalAction, openModalAction} from "../../Actions/ModalActions";
import SubMenu from "./SubMenu";

const HeaderMenuModal = (props) => {

    const dispatch = useDispatch();
    const [menuConstruction, setMenuConstruction] = useState([])

    useEffect(() => {

        window.scrollTo(0, 0);

        const fetchData = async () => {
            await appAPI.getMenuConstruction()
                .then(response => setMenuConstruction(response.data))
        };
        fetchData();
    }, []);



    useEffect(() => {
        document.querySelector('.modal-dialog').style.height = '100%';
        document.querySelector('.modal-dialog').classList.remove('modal-dialog-centered');
        document.querySelector('.modal').classList.add('noPadding');
        const fetchData = async () => {
            await appAPI.getMenuConstruction()
                .then(response => setMenuConstruction(response.data))
        };
        fetchData();
        return () => {
            document.querySelector('.modal').classList.remove('noPadding');
            document.querySelector('.modal-dialog').style.height = '100%';
        document.querySelector('.modal-dialog').classList.remove('modal-dialog-centered');

        }
      }, [])

    const openCatalog = () => {
        dispatch(openModalAction(
            SubMenu,
            {history: [{list: menuConstruction, prevModal: HeaderMenuModal}], currentModalNumber: 0, needCaret: true},
            true
        ))
    }

    const close = () => {
        dispatch(closeModalAction())
    }

    const arrBuyers = [
        {
            id: 0,
            name: 'Доставка',
            src: '/delivery',
        },
        {
            id: 1,
            name: 'О нас',
            src: '/aboutUs',
        },
        {
            id: 2,
            name: 'Пользовательское соглашение',
            src: '/PDFs/LicenseAgreement.pdf',
            isBlank: true,
        },
        {
            id: 3,
            name: 'Политика конфиденциальности',
            src: '/PDFs/confidential.pdf',
            isBlank: true,
        },
    ];

    const arrContacts = [
        {
            id: 0,
            name: 'Обратная связь',
            src: '/contacts',
        },
        {
            id: 1,
            name: 'Отдел развития',
            src: '/development',
        },
        {
            id: 2,
            name: 'Отдел закупок',
            src: '/purchase',
        },
        {
            id: 3,
            name: 'Вакансии',
            src: '/job',
        },
    ]


    return (
        <div className={`menu ${props.className}`}>
            <div className="menu__city">
                <span>Санкт-Петербург</span>
            </div>
            <div className="menu__items">
                <div className="menu__item" onClick={openCatalog}>
                    <span>Каталог</span>
                    <AccordionCaret fillColor="black" rotate="-90deg" />
                </div>
                <div className="menu__item">
                    <Link to="/catalog/sales" onClick={close}>Скидки</Link>
                </div>
                <div className="menu__item">
                    <Link to="/catalog/news" onClick={close}>Новинки</Link>
                </div>
                <div className="menu__item">
                    <Link to="/shops" onClick={close}>Магазины</Link>
                </div>
                <div className="menu__item">
                    <Link to="/delivery" onClick={close}>Доставка и оплата</Link>
                </div>
                <div className="menu__item menu__item__accordion">
                    <CustomAccordion callback={close} title="Контакты" caret={{fillColor: 'black'}} items={arrContacts}/>
                </div>
                <div className="menu__item">
                    <Link to="/reviews" onClick={close}>Отзывы</Link>
                </div>
                <div className="menu__item">
                    <Link to="/recepies" onClick={close}>Рецепты</Link>
                </div>
                <div className="menu__item menu__item__accordion">
                    <CustomAccordion callback={close} title="Покупателям" caret={{fillColor: 'black'}} items={arrBuyers}/>
                </div>
            </div>
        </div>
    );
};

export default HeaderMenuModal;
