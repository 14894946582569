import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../Actions/UserActions';
import {setForm} from '../../Actions/FormActions'
import Form from './Form';
import { appAPI } from '../../API/API';
import './Forms.sass';
import {openModalAction} from "../../Actions/ModalActions";
import Registration from "./Registration";
import useResolution from "../../API/useResolution";

const SignIn = ({close, ...props}) => {
  const dispatch = useDispatch();
  const isMobile = useResolution();

  const openRegistration= () => {
    dispatch(openModalAction(Registration, {}, true))
  }

  const formSignIn = {
    title: 'Войти в личный кабинет',
    fields: [
      {
        type: 'text',
        placeholder: 'Введите e-mail',
        name: 'email',
        validate: '/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]'
      },
      {
        type: 'password',
        placeholder: 'Введите пароль',
        name: 'password',
      },
    ],
    btn: 'Вход',
    firstDesc: 'Напомнить пароль',
    srcFirst: '/',
    secondDesc: 'Впервые у нас?',
    link: 'Зарегистрироваться',
    src: '/',
    modal: openRegistration,
  };


  const [user, setUser] = useState({
    password: '',
    email: '',
  });

  //const [validateMessage, setValidateMessage] = useState('')
  const [flagValidation, setFlagValidation] = useState(false)

  const submitHandler = async (e) => {
    e.preventDefault();
    try{
      await appAPI.loginUser(user.email, user.password);
      dispatch(loginUser(user.email, user.password));
      if (isMobile) {
        close()
      }
      else {
        dispatch(setForm('review', false))
      }

    } catch(err) {
      setFlagValidation('Неверный email или пароль!')
    }
  };

  const validation = (name, value) => {
    if(flagValidation) {
      switch(name) {
        case 'email':
          if(/[\w.%+-]+@[A-Za-z\d.-]+\.\w+/.test(value.email)) { return ''} else return 'active'
        case 'password':
          
          if (value.password.length < 3) { return 'active'} else return ''
        default: return;
  
      }
    }

  }
  return (
    <Form 
      formName={'form_signin'} 
      props={formSignIn} 
      state={user}
      errorMessage={flagValidation}
      setState={setUser} 
      submitHandler={submitHandler} 
      validation = {validation}
      close={close}
      {...props}
    />
  );
};

export default SignIn;
