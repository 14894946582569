import React, {useState, useEffect} from 'react';
import {appAPI} from '../../../../API/API'
import '../LKItems.sass'
import SaveCartItem from './SaveCartItem'
import dayjs from 'dayjs'
import {cartFunctions} from "../../../../pages/CartPage/CartFunctions/CartFunctions";
import {clearCart, rerenderCart} from "../../../../Actions/CartActions";
import {useDispatch, useSelector} from "react-redux";

const SaveCart = () => {
    const [carts, setCarts] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const {render} = useSelector(state => state.Cart)
    const dispatch = useDispatch()

    const packetId = '17266d47-4b41-11eb-ab51-a4bf01485720'

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const response = await appAPI.getCarts()
            if(response.statusCode === 200 && isMounted) {
                // console.log(response.data)
                setCarts(response.data)
                setIsFetching(true)
            }
        }
        fetchData();
        return () => { isMounted = false }
    }, [carts])

    const repeatHandler = async (id) => {
        console.log(carts)
        try {
            appAPI.postCartClear().then(() => {dispatch(clearCart()); dispatch(rerenderCart(render+1))}).then(async () => {
                //
                const cart = carts.find(cart => cart.id === id)

                for (const product of cart.products) {
                    // if(product.product.id === packetId) continue
                    await cartFunctions.addProductCartAuth({...product.product, amount: product.amount})
                    dispatch(rerenderCart(render+1))
                }

            }).then(() => dispatch(rerenderCart(render + 1)))
        } catch (error) {
            console.log(error)
        }
    }

    const deleteHandler = async (id) => {
        try{
            await appAPI.delSaveCart(id).then(()=>{dispatch(rerenderCart(render+1))})
        }
        catch (err){
            console.log(err)
        }
    }

    return(
        <div className = 'lk-history'>
            <div className="lk-history__carts">
            {
                isFetching ? (
                    carts.length > 1 || carts[0].cartType === 'SAVED' ? (
                        <>
                            {carts.map((cart, i) => (
                                cart.cartType === 'SAVED' ? (
                                    <div className="lk-history__items" key = {i}>
                                        <div className="lk-history__details">
                                            <h4>Корзина от {dayjs(cart.savedTime).format('DD.MM.YYYY', 'ru')}</h4>
                                            <div className='lk-history__icons'>
                                                <img src="/img/lk/user/deleteCart.svg" alt='delete' onClick={()=>deleteHandler(cart.id)}/>
                                                <img src="/img/lk/user/repeatCart.svg" alt="repeat" onClick={()=>repeatHandler(cart.id)}/>
                                            </div>
                                        </div>
                                        {cart.products.map((product) => <SaveCartItem product = {product} key = {product.id}/>)}
                                        <p className="lk-history__price">
                                            Итого: {cart.cartPrice} ₽
                                        </p>
                                    </div>
                                ) : null
                            ))}
                        </>
                    ) : <h2 className = 'lk-history__title'>У вас нет сохраненных корзин</h2>
                ) : null
            }
            </div>
        </div>
    )
}

export default SaveCart;