import React from 'react';
import {Link} from 'react-router-dom'

const MenuHeaderCatalogItem = ({group}) => {


    return(
        <div className='menu_block__item menu-wrapper'>
            <Link to = {`/catalog/categories/${group.id}?title=${group.name}`} className='menu_catalog__link_head'>{group.name}</Link>
            <ul className='menu_catalog'>
            {
                group.categories.map(category => (
                    <li className='menu__item' key={category.id}>
                        <Link to={`/catalog/categories/${group.id}/${category.id}?title=${category.title}`} className='menu__link menu__link_catalog'>
                            {category.title}
                        </Link>
                    </li>
            ))}
            </ul>
      </div>
    )
}

export default MenuHeaderCatalogItem