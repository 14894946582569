import React, {useState} from 'react';
import './Forms.sass'
import {appAPI} from '../../API/API'
import {useDispatch, useSelector} from 'react-redux'
import {setForm} from '../../Actions/FormActions'
import useResolution from '../../API/useResolution'
import ClickAwayListener from 'react-click-away-listener';

const ChangePassword = () => {

    const dispatch = useDispatch();
    const {open} = useSelector((state) => state.Form)
    const isMobile = useResolution();

    const [passwordOld, setPasswordOld] = useState('')
    const [passwordNew, setPasswordNew] = useState('')

    const [validateNewPassword, setValidateNewPassword] = useState('')
    const [changeSuccess, setChangeSuccess] = useState(false)


    const submitHandler = async (e) => {

        e.preventDefault()


        if( passwordNew.length >= 5 && passwordOld.length >= 5 && passwordOld && passwordNew) {
            try{
                await appAPI.changePasswordMe(passwordOld, passwordNew);
                setChangeSuccess(true)
            } catch(err) {
                setValidateNewPassword('Неверный пароль!')
            }
        } else setValidateNewPassword('Пароль должен содержать более 5 символов!')

    }

    return(
        <ClickAwayListener onClickAway={ () => dispatch(setForm('', false))}>
            <form className= {`form form_changePassword ${open && !isMobile ? 'active' : ''}`} onSubmit={submitHandler}>
                <img 
                    src="/img/forms/formClose.svg" 
                    alt="close"
                    className = 'form-close'
                    onClick = { () => dispatch(setForm('', false)) }
                />

                {!changeSuccess ? (
                    <>
                        <h2>Изменить пароль</h2>
                        <input 
                            type="password"
                            className = 'form__field form__field_input'
                            placeholder = 'Введите старый пароль'
                            onChange = { (e) => setPasswordOld(e.target.value)}
                        />
                        <input 
                            type="password"
                            className = 'form__field form__field_input'
                            placeholder = 'Введите новый пароль'
                            onChange = { (e) => setPasswordNew(e.target.value)}
                        />
                        <p>{validateNewPassword}</p>
                        <button 
                            type='submit' 
                            className={`btn form-btn_main ${passwordOld && passwordNew ? 'active' : ''}`}>Готово</button>
                    </>
                ): <h2 className = 'password-succeed'>Пароль успешно сменен!</h2>}   
            </form>
        </ClickAwayListener>
    )

}

export default ChangePassword;