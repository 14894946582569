import React from 'react';

const LicenseAgreement = () => {



    return (
        <div className="container">
            <a href="/PDFs/LicenseAgreement.pdf"> </a>
        </div>
    )
}

export default LicenseAgreement;