import { FETCHED, LOGIN_USER, LOGOUT_USER, CHECK_AUTH, USER_DATA, SET_REGISTRATION_TOKEN, SET_AUTH } from '../Actions/UserActions';

const UserReducer = (
  state = {
    login: '',
    isAuth: undefined,
    token: '',
    error: null,
    fetched: false,
    checkAuth: undefined,
    role: undefined,
    phone: '',
    email: '',
    birthday: '',
    registrationToken: ''
  },
  action,
) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuth: action.isAuth,
        token: action.token,
        login: action.username,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isAuth: false,
        checkAuth: false,
      };

    case FETCHED:
      return {
        ...state,
        ...action,
      };

    case CHECK_AUTH:
      return {
        ...state,
        checkAuth: action.status,
      };

    case USER_DATA:
      return {
        ...state,
        role: action.role,
        phone: action.phone,
        email: action.email,
        birthday: action.birthday
      }
    
    case SET_REGISTRATION_TOKEN:
      return {
        ...state,
        registrationToken: action.token
      }
    
    case SET_AUTH:
      return {
        ...state,
        isAuth: action.auth,
        checkAuth: action.auth
      }
    default:
      return state;
  }
};

export { UserReducer };
