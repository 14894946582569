import React from 'react';
import SliderArrows from '../../Components/SliderCategories/Slider/SliderArrows';

const settings = (isMobile) => {
  return {
    dots: isMobile,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 2 : 4,
    arrows: !isMobile,
    slidesToScroll: 1,
    swipeToSlide: isMobile,
    autoplay: false,
    autoplaySpeed: 6000,
    appendDots: dots => (
        <div>
          {dots.slice(0, 7)}
        </div>
    ),
    prevArrow: <SliderArrows src='/img/slider/arrowPrevSlider.svg' srcNew={'/img/slider/arrowPrevRed.svg'} />,
    nextArrow: <SliderArrows src='/img/slider/arrowNextSlider.svg' srcNew={'/img/slider/arrowNextRed.svg'} />,
  }
}

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: isMobile ? 1 : 4,
//   arrows: !isMobile,
//   slidesToScroll: 1,
//   centerMode: isMobile,
//   autoplay: false,
//   autoplaySpeed: 6000,
//   prevArrow: <SliderArrows src='/img/slider/arrowPrevSlider.svg' srcNew={'/img/slider/arrowPrevRed.svg'} />,
//   nextArrow: <SliderArrows src='/img/slider/arrowNextSlider.svg' srcNew={'/img/slider/arrowNextRed.svg'} />,
// };

export default settings;
