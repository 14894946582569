import React, {useState, useEffect} from 'react';
import {appAPI} from '../../../API/API'
import './ReviewsStars.sass'
import DrawStars from '../../DrawStars/DrawStars';

const ReviewsStars = () => {

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const response = await appAPI.getReviewsSummary();
            if(isMounted && response.statusCode === 200) {
                response.data.average = (response.data.one + response.data.two * 2 + response.data.three * 3 + response.data.four * 4 + response.data.five * 5) / response.data.reviewsCount;
                response.data.average = response.data.average.toFixed(1);
                setReviews(response.data)
            }
        }
        fetchData();
        return () => {isMounted = false}
    }, [])

    return(
        <div className="review-stars">
            <div className="review-stars__mark">
                <div className="review-stars__mark-content">
                    <h4 className="review-stars__average">{reviews.average}</h4>
                    <div className="review-stars__average-reviews">
                        <DrawStars rate = {reviews.average} />
                        <p>{reviews.reviewsCount} отзыва</p>
                    </div>
                </div>
            </div>
            <div className="review-stars__marks">
                <h4>Оценки</h4>
                <ul className="review-stars__list">
                    <li className = "review-stars__item">
                        <DrawStars rate = {5}/>
                        <div className = "review-stars__measure"><span style = {{width: (reviews.five/reviews.reviewsCount*100) + '%'}}/></div>
                        <span>{reviews.five}</span>
                    </li>
                    <li className = "review-stars__item">
                        <DrawStars rate = {4}/>
                        <div className = "review-stars__measure"><span style = {{width: (reviews.four/reviews.reviewsCount*100) + '%'}}/></div>
                        <span>{reviews.four}</span>
                    </li>
                    <li className = "review-stars__item">
                        <DrawStars rate = {3}/>
                        <div className = "review-stars__measure"><span style = {{width: (reviews.three/reviews.reviewsCount*100) + '%'}}/></div>
                        <span>{reviews.three}</span>
                    </li>
                    <li className = "review-stars__item">
                        <DrawStars rate = {2}/>
                        <div className = "review-stars__measure"><span style = {{width: (reviews.two/reviews.reviewsCount*100) + '%'}}/></div>
                        <span>{reviews.two}</span>
                    </li>
                    <li className = "review-stars__item">
                        <DrawStars rate = {1}/>
                        <div className = "review-stars__measure"><span style = {{width: (reviews.one/reviews.reviewsCount*100) + '%'}}/></div>
                        <span>{reviews.one}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ReviewsStars;