import React, {useState, useEffect} from 'react';
import {appAPI} from '../../API/API'
import '../Menu/Menu.sass';
import MenuHeaderCatalogItem from './MenuHeaderCatalog/MenuHeaderCatalogItem';

const MenuCatalog = () => {

  const [catalogMenu, setCatalogMenu] = useState([])
  
  useEffect(() => {
    // eslint-disable-next-line
    let isMounted = true;
    const fetchData = async () => {
      await appAPI.getMenuConstruction()
        .then(response => setCatalogMenu(response.data))
    }
    fetchData();
    return () => { isMounted = false }
  }, [])

  return (
    <div className='menu_block__wrapper menu_block__wrapper_catalog'>
        {catalogMenu.map(group => <MenuHeaderCatalogItem src = {`${group.id}?title=${group.name}`} title = {group.name} group = {group} key = {group.id}/>)}
    </div>
  );
};

export default MenuCatalog;
