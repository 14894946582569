import React from 'react';

const ReviewModal = (props) => {

    return(
        <div className="cart-page-modal">
            <h2>Отзыв был добавлен!</h2>
            <img src="/img/cart/watchBlue.svg" alt="clock"/>
            <button className="btn form-btn" onClick={ () => props.close(false)}>Вернуться к товарам</button>
        </div>
    )
}

export default ReviewModal;