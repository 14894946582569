import React from 'react';
import '../Filter.sass';
import {addSellerId, addCoordinates} from '../../../../Actions/OrderActions'
import {useDispatch} from 'react-redux'

const FilterItem = (props) => {

  let newDate = new Date().getHours();
  const dispatch = useDispatch();

  const onClickShop = () => {
    props.setActiveBalloon(props.shop.id)
    console.log('props', props.shop.seller)
    if (props.shop.seller)  {
        dispatch(addSellerId(props.shop.seller.id, props.shop.shopPhone, props.shop.adminPhone, props.shop.address, props.shop.id))
    }
    dispatch(addCoordinates(props.shop.latitude, props.shop.longitude))
  }

  return (
    <div className={`filter-item ${props.activeBalloon === props.shop.id ? 'active' : ''}`} onClick={onClickShop}>
      <h3 className='filter-item__title'>{props.shop.name}</h3>
      <div className='filter-item__subway'>
        <p className='filter-item__subway'>{props.shop.underground}</p>
      </div>
      <div className='filter-item__phone'>
        <p>Телефон администратора</p>
        <span>{props.shop.adminPhone}</span>
      </div>
      <div className='filter-item__status'>
        <p className='filter-item__status_open'> 
          {
            newDate < props.shop.closeTime.split(':')[0] 
            ? `Открыт. Закроется в ${props.shop.closeTime}`
            : 'Закрыт.'
          }
        </p>
        <p>{props.shop.status}</p>
      </div>
    </div>
  );
};

export default FilterItem;
