import React, {useState} from "react";
import './Accordion.sass';
import {Accordion} from "react-bootstrap";
import CustomToggle from "./CustomToggle";
import {AccordionCaret} from "../../assets/AccordionCaret";
import {Link} from "react-router-dom";

const CustomAccordion = ({title, items, caret, callback}) => {
    const [isOpen, toggleAccordion] = useState(false);
    return (
        <Accordion>
            <div>
                <CustomToggle isOpen={isOpen} toggleAccordion={toggleAccordion} eventKey="1">
                    <span className="accordion__title">{title}</span>
                    <AccordionCaret {...caret} />
                </CustomToggle>
                <Accordion.Collapse eventKey="1">
                    <div className="accordion__body">
                        {
                            items.map(item => <div key={item.id} className="accordion__item">
                                {
                                    item.isBlank
                                        ? <a href={item.src} target="_blank" rel="noopener noreferrer">{item.name}</a>
                                        : <Link to={item.src} onClick={callback}>{item.name}</Link>
                                }
                            </div>)
                        }
                    </div>
                </Accordion.Collapse>
            </div>
        </Accordion>
    )
}

export default CustomAccordion;