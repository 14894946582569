import React, {useState, useEffect} from 'react';
import {appAPI} from '../../../API/API'
import InputMask from 'react-input-mask';
import './LKItems.sass';
import useResolution from "../../../API/useResolution";
import {useDispatch, useSelector} from 'react-redux'
import {setForm} from '../../../Actions/FormActions'
import Cookies from 'js-cookie';
import { getPhone } from '../../Common/FormatPhone';

const PersonalData = () => {

    const dispatch = useDispatch();

    const [activeContent, setActiveContent] = useState('data')
    const {token} = useSelector(state => state.User)
    const [data, setData] = useState([])
    const isMobile = useResolution();
    const [formData, setFormData] = useState({
        username: '',
        birthday: '',
        email: '' ,
        // phone: ''
    })
    const [phone, setPhone] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            const response = await appAPI.getMe();
            setData(response);
            // setFormData(() => ({name: response.username, birthday: response.birthday, phone: response.phone, email: response.email});
        }
        fetchData();
    }, [])

    const submitHandler = async (e) => {
        e.preventDefault();
        setActiveContent('data')
        Object.entries(formData).map(a=>Object.entries(a[1]).filter(b=>b[1].length).length?a:delete formData[a[0]]);
        if (Object.keys(formData).length !== 0) {
            await appAPI.patchMe({...formData})
            .then(() => setData(prev => ({...prev, ...formData})))
            .catch(err => err.response.status === 500 && alert('Уже есть пользователь с такими данными'))
        } 
        if(phone) {
            await appAPI.changeUserPhone(phone.split(' ').join(''), token)
            .then(response => {
                Cookies.set('phoneConfirmUser', response.data.data.changePhoneToken)
                dispatch(setForm('changePhone', true));
            })
            .catch(err => err.response.status === 400 && alert('Уже есть пользователь с такими данными'))
        }
    }

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const switchActiveContent = (activeContent) => {
        switch (activeContent){
            case 'data':
                return (
                        <>
                            <ul className = 'lk-personal-data__data'>
                                {
                                    isMobile ? (
                                            <>
                                                <li className="data-name"><p>ФИО</p></li>
                                                <li>{data.username}</li>
                                                <li className="data-name"><p>Дата Рождения</p></li>
                                                <li>{data.birthday}</li>
                                                <li className="data-name"><p>Email</p></li>
                                                <li>{data.email}</li>
                                                <li className="data-name"><p>Телефон</p></li>
                                                <li>{data.phone}</li>
                                            </>
                                        )
                                        : (
                                            <>
                                                <li><p>{data.username}</p></li>
                                                <li><p>{data.birthday}</p></li>
                                                <li><p>{data.email}</p></li>
                                                <li><p>{getPhone(data.phone)}</p></li>
                                            </>
                                        )
                                }
                            </ul>
                            <img src="/img/lk/user/pencil.svg" alt="pencil" className = 'lk-personal-data__pencil' onClick = { () => setActiveContent('form')}/>
                        </>
                    )
            case 'form':
                return (
                    <form className = 'form ' onSubmit = {submitHandler} style = {{ display: 'flex' }}>
                        {isMobile && <div className="form-data-name">ФИО</div>}
                        <input type="text" name = 'username' className="form__field form__field_input" placeholder =  {data.username} onChange = {changeHandler}/>
                        {isMobile && <div className="form-data-name">Дата Рождения</div>}
                        <InputMask
                            type = 'phone' 
                            name = 'birthday' 
                            className="form__field form__field_input" 
                            mask = '99.99.9999'
                            maskChar = '_'
                            onChange = {changeHandler}
                            placeholder = {data.birthday}
                        />
                        {/* <input type="data" name = 'birthday' className="form__field form__field_input" placeholder = {data.birthday} onChange = {changeHandler}/> */}
                        {isMobile && <div className="form-data-name">Email</div>}
                        <input type="email" name = 'email' className="form__field form__field_input" placeholder = {data.email} onChange = {changeHandler}/>
                        {isMobile && <div className="form-data-name">Телефон</div>}
                        <InputMask 
                            type = "phone" 
                            name = 'phone' 
                            className="form__field form__field_input" 
                            placeholder = {data.phone} 
                            mask="+7 999 999 99 99"
                            onChange={(e) => setPhone(e.target.value)}
                        />

                        {/* <input type="phone" name = 'phone' className="form__field form__field_input" placeholder = {data.phone}/> */}
                        <button type = 'submit' className="btn form-btn">Сохранить</button>
                    </form>
                    
                )
            default: return;
        }

    }

    return(
        <div className = 'lk-personal-data'>
            { data.length !== 0 && (
                <div className = 'lk-personal-data__content'>
                    <div className = 'lk-personal-data__change'>
                        {!isMobile && (<ul className={`lk-personal-data__list ${activeContent === 'form' ? 'active' : ''}`}>
                            <li><p>ФИО</p></li>
                            <li><p>Дата Рождени</p>я</li>
                            <li><p>Email</p></li>
                            <li><p>Телефон</p></li>
                        </ul>)}
                        {switchActiveContent(activeContent)}
                    </div>
                    <p 
                        className = 'lk-personal-data__link'
                        onClick = { () => dispatch(setForm('changePassword', true)) }
                    >
                        Изменить пароль
                    </p>
                </div>
                
            )
            }
        </div>
    )
}

export default PersonalData;