import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import {appAPI, apiURL} from '../../../API/API'
import {rerenderCart} from '../../../Actions/CartActions'
import {setForm} from '../../../Actions/FormActions'
import './SliderCategoriesCard.sass';
import { useDispatch, useSelector } from 'react-redux';
import useResolution from "../../../API/useResolution";
import {cartFunctions} from '../../../pages/CartPage/CartFunctions/CartFunctions'

const SliderCategoriesCard = (props) => {
  
  
  const productId = props.product.id;
  const isMobile = useResolution();
  const [activeImgLike, setActiveImgLike] = useState(false)
  const [activeImgCart, setActiveImgCart] = useState(false)
  const [favWasAdded, setFavWasAdded] = useState(false)
  const dispatch = useDispatch();
  const userValues = useSelector((state) => state.User)
  const {render} = useSelector(state => state.Cart)
  const {isAuth} = useSelector(state => state.User)
  const cartItemFromLocal = cartFunctions.getItemFromLocal(props.product.id)

  useEffect(() => {
    if(isAuth && props.product.inFavorite) setActiveImgLike(true)
// eslint-disable-next-line
  }, [isAuth])

  const counterCart = (sign) => {
    if(isAuth) {
        cartFunctions.putProductCartAuth(props.product.id, cartItemFromLocal.amount + 1 * sign) 
        .then(() => dispatch(rerenderCart(render + 1)))
    } else {
        cartFunctions.putProductCart(props.product.id, cartItemFromLocal.amount + 1 * sign)
        dispatch(rerenderCart(render + 1))
    }
}
  
  const addProductCart = async () => {
    if(isAuth) {
      cartFunctions.addProductCartAuth({...props.product, amount: 1}) 
      .then(() => dispatch(rerenderCart(render + 1)))
    } else {
      cartFunctions.addProductCart({...props.product, amount: 1})
      dispatch(rerenderCart(render + 1))
    }
    setActiveImgCart(false)
  }

  const addProductFavourites = async () => {
    if(userValues.isAuth) {
      try {
        await appAPI.postFavorites(props.product.id);
        props.cardName === 'favourites' && props.deliteCardFavourite(props.idFavourite)
        favWasAdded ? setFavWasAdded(false) : setFavWasAdded(true)
      } catch(err) {}
    } else dispatch(setForm('signin', true))

  }

  const omMouseEnterFav = () => {
    if(userValues.isAuth) {
      !props.product.inFavorite && setActiveImgLike(true)
    } else setActiveImgLike(true)
  }


  const omMouseLeaveFav = () => {
    if(userValues.isAuth) {
      if(!props.product.inFavorite && !favWasAdded) setActiveImgLike(false)
    } else setActiveImgLike(false)
  }

  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const handleOnMouseDown = (e) => {
      setMouseX(e.clientX)
      setMouseY(e.clientY)
  }
  const handleClick = (e) => {
      console.log(mouseX, e.clientX)
      console.log(mouseY, e.clientY)
      console.log(mouseX !== e.clientX || mouseY !== e.clientY)
      if (mouseX !== e.clientX || 
          mouseY !== e.clientY) {
          e.preventDefault()
          e.stopPropagation()
      }
  }

  return (
    <div className='card card_main_recommended' >

      <Link to = {`/catalog/products/${productId}`} onMouseDown={handleOnMouseDown} onClick={handleClick} >
      {/* <Link to = {props.folder !== null ? `/catalog/${props.folder}/${props.product.name.replace(/(\(|\))/g, '')}` : ''}> */}
        <div className='card-top'>
          {props.product.name && props.product.images ? 
            <img src={`${apiURL}` + props.product.images[0].url} alt='' className='card-top__img' />
            :<img src='/img/blocks/caviar/caviar.jpg' alt='' className='card-top__img' />
          }
          <div className='card-top__icons'>
            <img src='/img/cards/New.svg' alt='New' className='card-top__icon card-top__icon_new' />
            <img src='/img/cards/Sale10.svg' alt='Sale' className='card-top__icon card-top__icon_star' />
          </div>
        </div>

        <div className='card-middle'>
            <h4 className='card__title'>{props.product.name}</h4>
            <p className='card__description'>{props.product.description}</p>
            {!isMobile && <p className='card__description_more'>Подробнее о товаре</p>}
        </div>

      </Link>
      <div className='card-bottom card-bottom-slider'>
        <div className='card-bottom__price'>
          <p className='card-bottom__costs'>
          {props.product.promotionalPrice ? props.product.promotionalPrice : props.product.ordinaryPrice} Р
          {isMobile && <br/>}
          <span className='card-bottom__costs_throw'>
             {props.product.promotionalPrice ? props.product.ordinaryPrice + ' Р' : '' }
          </span>
          </p>
          <p className='card-bottom__weight'>{
            (props.product.unit !== 'шт' && props.product.unit !== 'упак' && props.product.weightRange)
              ? props.product.weightRange
              : '1 '}
              {` ${props.product.unit}`}</p>
        </div>
        <div className='card-bottom__icons'>
          
            <img 
                src={activeImgLike ? '/img/catalog/likeFill.svg': '/img/catalog/like.svg'} 
                alt='Like' 
                className='card-bottom__icon' 
                onClick={addProductFavourites}
                onMouseEnter = {omMouseEnterFav}
                onMouseLeave = {omMouseLeaveFav}
              />        
          
          {cartItemFromLocal && cartItemFromLocal.amount ? (
            <div className='cart-item__counter'>
              <img
                src='/img/cart/cartMines.svg'
                alt='Mines'
                className='cart-item__btn cart-item__btn_mines'
                onClick = {() => counterCart(-1)} 
              />
              {isAuth 
                        ? <span className='cart-item__show'>{cartItemFromLocal.amount}</span>
                        : <span className='cart-item__show'>{cartItemFromLocal.amount && cartItemFromLocal.amount}</span>
                    }
              <img
                src='/img/cart/cartPlus.svg'
                alt='Plus'
                className='cart-item__btn cart-item__btn_plus'
                onClick = {() => counterCart(1)} 
              />
          </div>
          ): (
            <img 
            src={ activeImgCart ? '/img/catalog/cartFill.svg': '/img/catalog/cart.svg'} 
            alt='Cart' className='card-bottom__icon' 
            onClick = {addProductCart} 
            onMouseEnter = {() => setActiveImgCart(true)}
            onMouseLeave = {() => setActiveImgCart(false)}
            />
          )
          }


        </div>
      </div>
    </div>
  );
};

export default SliderCategoriesCard;
