export const getPhone = phone => {
    let newPhone = '';
    if(phone[0] === '+' && phone.length <= 12) {
        for(let i = 0; i < phone.length; i++) {
            if(i === 2 || i === 5 || i === 8 || i === 10) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else if(phone[0] === '8'){
        for(let i = 0; i < phone.length; i++) {
            if(i === 0) newPhone = '+7 '
            else if(i === 4 || i === 7 || i === 9) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else if(phone[0] === '7' && phone.length === 11){
        for(let i = 0; i < phone.length; i++) {
            if(i === 0) newPhone = '+7 '
            else if(i === 4 || i === 7 || i === 9) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else return phone
 
}