import React from 'react';
import dayjs from 'dayjs'
import DrawStars from '../../DrawStars/DrawStars'
import './ReviewsItems.sass'
import { apiURL } from '../../../API/API';

const ReviewsItem = ({props}) => {

    require('dayjs/locale/ru')
    return(
        <div className="review-item">
            <div className="review-item__top">
                <div className="review-item__stars">
                    <DrawStars rate = {props.rate}/>
                </div>
                <div className="review-item__date">
                    {dayjs(props.createdAt).locale('ru').format('DD MMMM YYYY HH:mm', 'ru')}
                </div>
            </div>
            <div className="review-item__title">

            </div>
            <div className="review-item__content">
                {props.comment}
            </div> 
            {(props.attachment && props.attachment_type === 'image') &&
            <div className="review-item__attachment">
                <img src={apiURL + props.attachment} alt="review attachment"/>
            </div>
            }
            {(props.attachment && props.attachment_type === 'video') &&
            <div className="review-item__attachment">
                <video controls src={apiURL + props.attachment} alt="review attachment"/>
            </div>
            }
            <div className="review-item__name">
                {props.username}
            </div>
        </div>
    )
}

export default ReviewsItem;