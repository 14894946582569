import React, {useState} from 'react';
import '../Menu/Menu.sass';
import Menu from '../Menu/Menu';
import { Link } from 'react-router-dom';
import MenuCatalog from './MenuCatalog';
import AnimateHeight from 'react-animate-height';

const HeaderMenu = () => {

  const [height, setHeight] = useState(0)

  const arrBuyers = [
    {
      id: 0,
      name: 'Доставка',
      src: '/delivery',
    },
    {
      id: 1,
      name: 'О нас',
      src: '/aboutUs',
    },
    {
      id: 2,
      name: 'Пользовательское соглашение',
      src: '/soglashenie',
    },
    {
      id: 3,
      name: 'Политика конфиденциальности',
      src: '/politica',
    },
  ];

  const arrContacts = [
    {
      id: 0,
      name: 'Обратная связь',
      src: '/contacts',
    },
    {
      id: 1,
      name: 'Отдел развития',
      src: '/development',
    },
    {
      id: 2,
      name: 'Отдел закупок',
      src: '/purchase',
    },
    {
      id: 3,
      name: 'Вакансии',
      src: '/job',
    },
  ]


  return (
    <ul className='menu menu_header'>
      <li className='menu__item menu__item_catalog' onMouseEnter = {() => setHeight('auto')} onMouseLeave = {() => setHeight(0)}>
        <Link to = '/catalog' className='menu__item_hidden'>
          Каталог
        </Link>
        <AnimateHeight
          height={height}
          duration = {350}
          id = 'animate-height-catalog'
        >
          <MenuCatalog />
        </AnimateHeight>
      </li>
      <li className='menu__item'>
        <Link to='/catalog/sales' className='menu__link menu__link_header'>
          Скидки
        </Link>
      </li>
      <li className='menu__item'>
        <Link to='/catalog/news' className='menu__link menu__link_header'>
          Новинки
        </Link>
      </li>
      <li className='menu__item'>
        <Link to='/shops' className='menu__link menu__link_header'>
          Магазины
        </Link>
      </li>
      <li className='menu__item menu__item_contacts'>
        <p className='menu__link menu__link_header'>Контакты</p>
        <Menu menuName = {'menu_contacts'} arr = {arrContacts}/>
      </li>
      <li className='menu__item'>
        <Link to='/reviews' className='menu__link menu__link_header'>
          Отзывы
        </Link>
      </li>
      <li className='menu__item'>
        <Link to='/recepies' className='menu__link menu__link_header'>
          Рецепты
        </Link>
      </li>
      <li className='menu__item menu__item_buyers'>
        <p className='menu__item_hidden'>
          Покупателям
        </p>
        <Menu menuName={'menu_buyers'} arr={arrBuyers} />
        <ul className='menu menu_buyers'>
          {arrBuyers.map((el, i) => {
            return (
              <li className='menu__item' key={el.id}>
                {i === 2 ? 
                      <a href = '/PDFs/LicenseAgreement.pdf' target="_blank" className='menu__link' >Пользовательское соглашение</a>
                      : i === 3 ? <a href = '/PDFs/confidential.pdf' target="_blank" className='menu__link'> Политика конфиденциальности</a>
                      : <Link to={el.src} className='menu__link'>
                          {el.name}
                        </Link>
                 }

              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default HeaderMenu;
