import { SET_LIST} from '../Actions/BasketActions';

const BasketReducer = (
    state = {
        basketlist: null,
    },
    action
) => {
    switch (action.type) {
        case SET_LIST:
            return {
                ...state,
                basketlist: action.list
            }
        default:
            return {
                ...state
            }
    }
}


export {BasketReducer};