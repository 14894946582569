import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import 'react-dadata/dist/react-dadata.css';
import {addCoordinates, setAddressNotAuth} from '../../../../Actions/OrderActions'
import { AddressSuggestions } from 'react-dadata';
import InputMask from 'react-input-mask';
import {appAPI} from '../../../../API/API'
import './Addresses.sass'

const AddAddress = (props) => {

    useEffect(() => {window.scrollTo(0,250)}, [])
    const userValues = useSelector((state) => state.User)
    const orderValues = useSelector((state) => state.Order)
    const dispatch = useDispatch();

    orderValues.phone = orderValues.phone.length < 12 ? '+7' + orderValues.phone : orderValues.phone;

    const [formData, setFormData] = useState({
        city: 'Санкт-Петербург',
        street: orderValues.street,
        building: orderValues.building,
        porch: orderValues.porch,
        floor: orderValues.floor,
        office: orderValues.office,
        phone: orderValues.phone,
        comment: orderValues.comment,
        name: orderValues.username,
        latitude: orderValues.latitude,
        longitude: orderValues.longitude,
        general: orderValues.general ? orderValues.general : false,
    })
    console.log('formData',formData);

    const submitHandler = async (event) => {
        event.preventDefault();
        if (userValues.isAuth) {
            try {
                await appAPI.postAdresses(formData);
                    props.setActiveBlock('showAddresses')
            } catch (err) {
                alert('Данные введены некорректно')
            }
        } else {

        }
    }

    

    const blurHandler = (e) => {
        dispatch(setAddressNotAuth(formData))
    }

    const changeHandler = (e) => {
        if(e.target.type === 'checkbox') {
            e.target.checked ? setFormData({...formData, general: true}) : setFormData({...formData, general: false})
            
        } else {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    }

    const clickCancel = (e) => {
        e.preventDefault()
        setFormData({})
        props.setActiveBlock('showAddresses')
    }

    const onChangeAddress = async (e) => { 
        const city = e.data.city;
        const street = e.data.street_with_type;
        const latitude = e.data.geo_lat;
        const longitude = e.data.geo_lon;
        const building = `${e.data.house_type_full} ${e.data.house}`;
        const fullAddress = e.value;
        
        setFormData({ ...formData, 
            city,
            street,
            latitude,
            longitude,
            building,
            fullAddress
        })
        if  (!userValues.isAuth) {
            const response = await appAPI.getDeliveryPrice(latitude, longitude)
            dispatch(addCoordinates(
                latitude, longitude, response.data.deliveryPrice, city, street, building, fullAddress))
        }
    }


    return(
        <div className="lk-addresses__add">
            <div className="lk-addresses__add-form">
                <h4>Адрес</h4>
                <form className="form form_addresses" onSubmit={submitHandler}>
                    <div className="form_addresses__block form_addresses__block_left">
                        <AddressSuggestions                         
                            placeholder="*Город, улица, дом, корпус/строение"
                            token="bfb7568c9faac153f1051a1b01ef035aa88b96eb" 
                            onChange={onChangeAddress} 
                            onClick = {onChangeAddress}
                            inputProps = {
                            {   
                                placeholder: "*Город, улица, дом, корпус/строение",
                                type: 'text',
                                name: 'street'
                            }
                            }
                        />
                        <div className="form_addresses__inputs-small">
                            <input 
                                type="number" 
                                name = 'porch'
                                className="form__field form__field_input form__field_input_small" 
                                placeholder="Подъезд"
                                onChange = {changeHandler}
                                defaultValue = {orderValues.porch}
                                onBlur = {blurHandler}
                            />
                            <input 
                                type="number" 
                                name = 'floor'
                                className="form__field form__field_input form__field_input_small" 
                                placeholder="Этаж"
                                onChange = {changeHandler}
                                defaultValue = {orderValues.floor}
                                onBlur = {blurHandler}
                            />
                            <input 
                                type="number" 
                                name = 'office'
                                className="form__field form__field_input form__field_input_small" 
                                placeholder="*Квартира"
                                onChange = {changeHandler}
                                defaultValue = {orderValues.office}
                                onBlur = {blurHandler}
                            />
                        </div>

                        <InputMask 
                            type = "phone" 
                            name = 'phone' 
                            className="form__field form__field_input" 
                            placeholder = '*+7' 
                            mask="+7 999 999 99 99"
                            onChange={changeHandler}
                            defaultValue = {orderValues.phone.slice(1)}/>
                        <input 
                            type="text" 
                            name = 'name'
                            className="form__field form__field_input" 
                            placeholder="*Имя получателя заказа"
                            onChange = {changeHandler}
                            defaultValue = {orderValues.username}
                        />                        
                        <p><sup>*</sup>Поля обязательные для заполнения</p>
                    </div>
                        <div className="form_addresses__block form_addresses__block_right">
                        <textarea 
                            name = "comment" 
                            className = "form__field form__field_textarea" 
                            onChange = {changeHandler} 
                            defaultValue = {orderValues.comment}
                            placeholder = 'Укажите детали адреса доставки: Домофон, Особенности двора и тд.'
                            onBlur = {blurHandler}
                        />
                    {userValues.isAuth ? (
                        <>
                        <p className = "form__field_checkbox">

                            <label htmlFor = 'main'><input id = 'main' type="checkbox" onChange = {changeHandler}/><span>Сделать основным</span></label>
                        </p>
                        <div className = "form_addresses__btns">
                            <button onClick = {clickCancel} className="form-btn btn">Отменить</button>
                            <button type ='submit'className="form-btn btn">Сохранить</button>
                        </div>
                        </>
                        ) : null
                    }
                    </div>
 
                </form>
            </div>
        </div>
    )
}

export default AddAddress;