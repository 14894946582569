import React, { useEffect, useState } from 'react';
import './Vacancies.sass';
import VacanciesItem from './VacanciesItem';
import { appAPI } from '../../../API/API';

const Vacancies = () => {
  
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await appAPI.getVacancies();
      setData(result.data);
    };
    fetchData();
  }, []);
  return (
    <div className='vacancies-items'>
      {data.map((el) => (
        <VacanciesItem key={el.id} props={el} />
      ))}
    </div>
  );
};

export default Vacancies;
