import React from 'react';
import ContentLoader from 'react-content-loader';

export const LKListLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            viewBox="0 0 830 300"
            backgroundColor="grey"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="0" ry="0" width="405" height="1" />
            <rect x="0" y="0" rx="0" ry="0" width="1" height="284" />
            <rect x="405" y="0" rx="0" ry="0" width="1" height="284" />
            <rect x="20" y="20" rx="0" ry="0" width="220" height="26" />
            <rect x="230" y="20" rx="0" ry="0" width="100" height="26" />
            <rect x="20" y="70" rx="0" ry="0" width="180" height="18" />
            <rect x="190" y="70" rx="0" ry="0" width="120" height="18" />
            <rect x="310" y="70" rx="0" ry="0" width="60" height="18" />
            <rect x="20" y="95" rx="0" ry="0" width="120" height="18" />
            <rect x="20" y="130" rx="0" ry="0" width="360" height="140" />
            <rect x="0" y="284" rx="0" ry="0" width="405" height="1" />
            <rect x="425" y="0" rx="0" ry="0" width="405" height="1" />
            <rect x="425" y="0" rx="0" ry="0" width="1" height="284" />
            <rect x="829" y="0" rx="0" ry="0" width="1" height="284" />
            <rect x="445" y="20" rx="0" ry="0" width="220" height="26" />
            <rect x="230" y="20" rx="0" ry="0" width="100" height="26" />
            <rect x="445" y="70" rx="0" ry="0" width="180" height="18" />
            <rect x="190" y="70" rx="0" ry="0" width="120" height="18" />
            <rect x="310" y="70" rx="0" ry="0" width="60" height="18" />
            <rect x="445" y="95" rx="0" ry="0" width="120" height="18" />
            <rect x="445" y="130" rx="0" ry="0" width="360" height="140" />
            <rect x="425" y="284" rx="0" ry="0" width="405" height="1" />
        </ContentLoader>
    )
}