import React, {useState} from 'react'

const ChangeCountStars = (props) => {

    const [countStars, setCountStars] = useState([
        {
            id: 1,
            fill: false,
        },
        {
            id: 2,
            fill: false,
        },
        {
            id: 3,
            fill: false,
        },
        {
            id: 4,
            fill: false,
        },
        {
            id: 5,
            fill: false,
        }
    ])
// eslint-disable-next-line
    const [prevCountStars, setPrevCountStars] = useState([])

    const onClickStar = (star) => {
        props.setRate(star.id)
        star.fill
            ?   setCountStars(prev => prev.map((el) => el.id > star.id ? {...el, fill: false} : el))
            :   setCountStars(prev => prev.map(el => el.id <= star.id ? {...el, fill: true} : el))
    } 
    return(
            <div className="draw-stars">
                {countStars.map((star, i) => (
                    star.fill ? (
                        <svg 
                            width="42" 
                            height="40" 
                            viewBox="0 0 42 40" 
                            fill="none" 
                            xmlns="http://www.w3.org/2000/svg"
                            onClick = {() => onClickStar(star)}
                            key = {star.id} 
                            
                            >
                        <path d="M9.47796 39.5377C8.46471 40.0575 7.31496 39.1466 7.51971 37.9837L9.69846 25.5675L0.45059 16.758C-0.413035 15.9337 0.0358402 14.427 1.19347 14.2642L14.0507 12.4372L19.7837 1.07885C20.3008 0.0550977 21.7 0.0550977 22.2171 1.07885L27.9501 12.4372L40.8073 14.2642C41.965 14.427 42.4138 15.9337 41.5502 16.758L32.3023 25.5675L34.4811 37.9837C34.6858 39.1466 33.5361 40.0575 32.5228 39.5377L20.9965 33.6157L9.47534 39.5377H9.47796Z" fill="#F4B928"/>
                        </svg>
                    ) : (
                        <svg 
                        width="42" 
                        height="40" 
                        viewBox="0 0 42 40" 
                        fill="none" 
                        className = 'star-unfilled'
                        xmlns="http://www.w3.org/2000/svg" 
                        onClick = {() => onClickStar(star)}
                        key = {star.id}
                        >
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.52362 37.9813C7.31887 39.1468 8.46862 40.0576 9.48187 39.5379L21.0056 33.6159L32.5267 39.5379C33.54 40.0576 34.6897 39.1468 34.485 37.9839L32.3062 25.5676L41.5541 16.7581C42.4177 15.9339 41.9689 14.4271 40.8112 14.2644L27.954 12.4374L22.221 1.07901C22.1118 0.84877 21.9395 0.654242 21.7241 0.518029C21.5087 0.381816 21.2591 0.309509 21.0043 0.309509C20.7495 0.309509 20.4999 0.381816 20.2845 0.518029C20.0691 0.654242 19.8968 0.84877 19.7876 1.07901L14.0546 12.44L1.19737 14.267C0.0397465 14.4298 -0.409128 15.9365 0.454497 16.7608L9.70237 25.5703L7.52362 37.9865V37.9813ZM20.3992 30.7179L10.7235 35.6896L12.5452 25.3025C12.5879 25.0634 12.5712 24.8175 12.4966 24.5863C12.422 24.3552 12.2918 24.1459 12.1174 23.9769L4.48912 16.7056L15.1256 15.1936C15.3459 15.1604 15.5548 15.0742 15.7344 14.9426C15.9141 14.8109 16.0592 14.6376 16.1572 14.4376L21.0056 4.83539L25.8514 14.4376C25.9494 14.6376 26.0945 14.8109 26.2742 14.9426C26.4538 15.0742 26.6627 15.1604 26.883 15.1936L37.5195 16.703L29.8912 23.9743C29.7162 24.1434 29.5856 24.3531 29.511 24.5848C29.4364 24.8165 29.42 25.063 29.4634 25.3025L31.2851 35.6896L21.6094 30.7179C21.4223 30.6214 21.2148 30.5711 21.0043 30.5711C20.7938 30.5711 20.5863 30.6214 20.3992 30.7179Z" fill="#1D3A65"/>
                        </svg>
                    )
                ))}
            </div>
    )
}

export default ChangeCountStars;