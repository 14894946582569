import React from 'react';
import './Forms.sass'
import {useDispatch, useSelector} from 'react-redux'
import {setForm} from '../../Actions/FormActions'
import ClickAwayListener from 'react-click-away-listener';
import { useFormik } from 'formik';

const validate = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less';
    }
  
    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less';
    }
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    if(!values.phone) {
        errors.phone = 'Required';
    } else if (values.phone.length < 16) {
        errors.phone = 'Must be 16 characters'
    }

    return errors;
  };

const FormReview = () => {

    const formValues = useSelector((state) => state.Form)
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [values, setValues] = React.useState({});

    const formik = useFormik({
        initialValues: {
            firstName: '',
            text: '',
            file: false,
            stars: 0,
        },
        validate,
        onSubmit: values => {
          alert(JSON.stringify(values, null, 2));
        },
    });

    const handleClickAway = () => {
        if(formValues.open) {
          dispatch(setForm('signin', false))
        }
    };

    const onClickClose = () => {
        dispatch(setForm('signin', false))
      };
     

    return(
        <div>
        <ClickAwayListener onClickAway={handleClickAway}>
            <form className={`form form_review ${formValues.open ? 'active' : ''}`} onSubmit={formik.handleSubmit}>
                <img src='/img/forms/formClose.svg' alt='closeForm' className='form-close' onClick={onClickClose} />
                <h2 className='form__title'>Отзыв</h2>
                <div className='form_review__stars'>
                    
                </div>
                <input
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    className='form__field form__field_input'
                    placeholder='Введите ваше имя'
                />
                <textarea
                    name="text"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.text}
                    className='form__field form__field_textarea'
                    placeholder='Текст отзыва'
                    onBlur={formik.handleBlur}
                    control= 'none'
                />
                <label className = 'label-file'>
                    <input 
                        type="file"
                        name = 'file'
                    />
                    <img src="/img/skrepka.svg" alt="download"/>
                    <p>Загрузите фото или видео</p>
                </label>

                <button type="submit" className = 'btn form-btn'>Оставить отзыв</button>
            </form>
        </ClickAwayListener>
      </div>
    )
}

export default FormReview;