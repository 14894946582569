import React from 'react';
import './AboutUs.sass';
import NavigationList from '../../Components/NavigationList/NavigationList'
import useResolution from "../../API/useResolution";

const AboutUs = () => {

  React.useEffect(() => window.scrollTo(0,0), [])

  const isMobile = useResolution();
  const arrAbout = [
    {
      id: '0',
      title: 'Кто мы?',
      imgSrc: '/img/aboutUs/0.png',
      text: `«Икра и Рыба» – это розничная сеть магазинов по продаже свежемороженой рыбы, икры, морепродуктов, которая раскинулась в ключевых точках СПб, Ленобласти и насчитывает более 40 магазинов, продолжая расширяться.

        Помимо этого «Икра и Рыба» – это онлайн магазин по продаже рыбы и морепродуктов, действующий на базе нашей сети. Именно эта особенность позволяет пользователям оперативно получать нашу продукцию с доставкой на дом. Благодаря широкой сети магазинов и дарксторов, нам удаётся минимизировать время доставки, при этом сохраняя низкие цены на продукцию.
        
        Внимание к покупателям является одним из принципов нашей работы. Ежедневно мы анализируем предпочтения наших покупателей. Выявляем, что пришлось по душе, что понравилось, как покупатели отнеслись к новинкам. Все это действительно важно для нас. И, отталкиваясь от Ваших предпочтений, мы делаем следующие шаги.`,
    },
    {
      id: '1',
      title: 'Почему у нас низкие цены?',
      imgSrc: '/img/aboutUs/1.png',
      text: `Мы стараемся держать наши цены на рыбу и иные продукты на максимально низком уровне, опережая конкурентов. Также мы постоянно работаем над расширением ассортимента, ведем работу с лучшими поставщиками из Карелии, Китая, Вьетнама, Чили. Также у нас есть собственное производство на Камчатке, благодаря чему рыба всегда свежая.

      Мы тесно взаимодействуем с поставщиками. То есть в нашей цепочке всего три звена: Производство — Икра и Рыба — Вы! И никого более!`,
    },
    {
      id: '2',
      title: 'Где нас найти?',
      imgSrc: '/img/aboutUs/2.png',
      text: `Наши магазины расположены по всему Санкт-Петербургу и Ленинградской области, то есть у Вас всегда есть возможность приобрести нашу продукцию и убедиться в ее качестве. Найти ближайший к Вам магазин очень просто, для этого есть несколько путей:
      Вы можете посмотреть адрес ближайшего магазина на этом сайте в разделе «Магазины» или на главной странице.
      
      Вы можете ввести название нашей сети «Икра и Рыба» поисковую строку Яндекс. Карт, 2Гис, Google Maps; Вы можете позвонить на нашу горячую линию, где вам объяснят как добраться домагазина;
      
      Вы можете зайти в наши социальные сети, например в группу ВК или Инстаграм, где обнаружите видео- маршруты до каждого магазина.
      
      Если же магазин сети «Икра и Рыба» оказался на значительном удалении от Вас, илиже возможность его посетить отсутствует, Вы всегда можете сделать заказ онлайн с доставкой на дом .`,
    },
  ];


  return (
    <section className='section_aboutUs'>
      <div className='container container_aboutUs'>
        <div className='about'>
        <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'О нас'}]}/>
          <h2 className='about-title'>О нас</h2>
          <div className='about-blocks'>
            {
              !isMobile
                  ? arrAbout.map((el, i) => (
                      <div className='about-block' key = {el.id}>
                        {i % 2 === 0 ? (
                            <>
                              <img src={el.imgSrc} alt='img' className='about-block__img' />
                              <div className='about-block__desc'>
                                <h3 className='about-block__title'>{el.title}</h3>
                                <p className='about-block__text'>{el.text}</p>
                              </div>
                            </>
                        ) : (
                            <>
                              <div className='about-block__desc'>
                                <h3 className='about-block__title'>{el.title}</h3>
                                <p className='about-block__text'>{el.text}</p>
                              </div>
                              <img src={el.imgSrc} alt='img' className='about-block__img' />
                            </>
                        )}
                      </div>
                  ))
                  : arrAbout.map((el, i) => (
                      <div className='about-block' key={el.id}>
                        <img src={el.imgSrc} alt='img' className='about-block__img' />
                        <div className='about-block__desc'>
                          <h3 className='about-block__title'>{el.title}</h3>
                          <p className='about-block__text'>{el.text}</p>
                        </div>
                      </div>
                  ))
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
