import React, { useState } from 'react';
import './Form.sass';
import { appAPI } from '../../../API/API';
import InputMask from 'react-input-mask';

const Form = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    name: '',
    phone: '',
    textarea: '',
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    
  };

  const submitHandler = (e) => {
    e.preventDefault();
    appAPI.sentMessage(data.email, data.password, data.name, data.phone, data.textarea);
  };

  return (
    <form className='form form_job' onSubmit={submitHandler}>
      <input
        type='email'
        name='email'
        className='form-field form-field_input'
        placeholder='Введите email'
        onChange={changeHandler}
      />
      <input
        type='text'
        name='name'
        className='form-field form-field_input'
        placeholder='Введите ваше имя'
        onChange={changeHandler}
      />
      <InputMask 
        type = "phone" 
        name = 'phone' 
        className="form__field form__field_input"
        placeholder = '+7' 
        mask="+7 999 999 99 99"
        style={{marginBottom: '20px'}}
        onChange={changeHandler}
      />
      <textarea
        placeholder='Сообщение'
        className='form-field form-field_textarea'
        name='textarea'
        onChange={changeHandler}
      />
      <button type='submit' className='form-btn btn form-field form-field_btn'>
        Отправить
      </button>
    </form>
  );
};

export default Form;
