import React from 'react'
import {Link} from 'react-router-dom'
import './SearchShow.sass'
import {useSelector} from 'react-redux'
import {apiURL} from '../../../API/API'

const SearchShow = ({setOpenShow}) => {

    const {productList} = useSelector((state) => state.Search)

    return(
            <div className="search-show">
                <div className="product-list">
                    {productList.map((product) =>
                        product.groups.length ?
                        <Link 
                            className = 'search-show__card' 
                            to = {`/catalog/products/${product.id}`}
                            onClick = {() => setOpenShow(false)}
                            key = {product.id}
                        >
                            
                            <img src={`${apiURL}${product.images[0].url}`} alt="img"/>
                            <p>{product.composition}</p>
                        </Link>
                        : null
                    )}
                </div>
            </div>
    )

}

export default SearchShow;