import React, {useState} from 'react'
import '../Oformlenie.sass'
import GetWayDelivery from './GetWayDelivery';
import {useSelector} from 'react-redux'
import GetWayVivoz from './GetWayVivoz';
import useResolution from "../../../API/useResolution";


const GetWay = (props) => {

    const isMobile = useResolution();
    const orderValues = useSelector((state) => state.Order)
    const userValues = useSelector((state) => state.User)
    const [activeItem, setActiveItem] = useState('delivery')
    const [showReduxFields, setShowReduxFields] = useState(false)

    const clickHandler = () => {
        console.log('orderValues',orderValues)
        // eslint-disable-next-line
        if(orderValues.latitude !== '' && orderValues.longitude !== '' && orderValues.addressForDelivery // eslint-disable-line
        || (orderValues.porch && orderValues.office && orderValues.street && orderValues.username)// eslint-disable-line
         || orderValues.sellerId && orderValues.orderReceiving === 'PICKUP') {// eslint-disable-line

            if(userValues.isAuth) {
                props.setActiveContent('confirmation')
                props.setActiveContentSucceess({
                    ...props.activeContentSucceess,
                    way: true
                })
                setShowReduxFields(true)

            } else {
                props.setActiveContent('confirmation')
                props.setActiveContentSucceess({
                    ...props.activeContentSucceess,
                    way: true
                })
                
            }

        }
        
    }


    const switchActiveItem = (activeItem) => {
        switch (activeItem) {
            case 'delivery':
                return (<GetWayDelivery showReduxFields = {showReduxFields}/>)
            
            case 'vivoz':
                return (<GetWayVivoz/>)
            default: return;
        }
    }

    return(
        <div className = 'lk-list__info'>
        <ul className="lk-list">
            <li 
                className={`lk-list__item ${activeItem === 'delivery' ? 'active' : ''}`}
                onClick={() => setActiveItem('delivery')}
                >
                {!isMobile && <img src="/img/lk/user/3.svg" alt="img"/>}
                Доставка
            </li>
            <li 
                className={`lk-list__item ${activeItem === 'vivoz' ? 'active' : ''}`}
                onClick={() => {
                    setActiveItem('vivoz')
                }}
            >
                {!isMobile && <img src="/img/lk/user/3.svg" alt="img"/>}
                Самовывоз
            </li>
        </ul>
        <div className="lk-list__content">
            {switchActiveItem(activeItem)}
        </div>
            {
                isMobile
                    ? (
                        <div className="btn_oformlenie_wrapper">
                            <button className={`btn form-btn btn_oformlenie ${ 
                                // eslint-disable-next-line
                                orderValues.latitude !== '' && orderValues.longitude !== '' && orderValues.addressForDelivery || (orderValues.porch && orderValues.office && orderValues.street && orderValues.username) ? 'active' : ''} `
                                }  onClick = {clickHandler}>Далее</button>
                        </div>
                    )
                    : (
                        <button className={`btn form-btn btn_oformlenie 
                        
                        ${ // eslint-disable-next-line
                        orderValues.latitude !== '' && orderValues.longitude !== '' && orderValues.addressForDelivery // eslint-disable-line
                        || (orderValues.porch && orderValues.office && orderValues.street && orderValues.username) // eslint-disable-line
                        || orderValues.sellerId && orderValues.orderReceiving === 'PICKUP'// eslint-disable-line
                        ? 'active' : '' } 
                        `}  onClick = {clickHandler}>Далее</button>
                    )
            }
        </div>
    )
}

export default GetWay;