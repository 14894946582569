import React, {useState, useEffect} from 'react'
import { AddressSuggestions } from 'react-dadata';
import {appAPI} from '../../../../API/API'
import InputMask from 'react-input-mask';
import './Addresses.sass'

const PatchAddress = (props) => {
    
    const [valueAddress] = useState('');
    const [formData, setFormData] = useState({
        street: '',
        building: '',
        phone: '',
        comment: '',
        latitude: '12.345',
        longitude: '34.567',
        general: false,
        name: ''
    })

    useEffect(() => {
        let isMounted = true;   

        const fetchData = async () => {
            try{
                const response = await appAPI.getAddressesId(props.activeAddress)
                if(isMounted) {
                    let obj = {
                        street: response.data.addressCity,
                        building: response.data.addressHome,
                        phone: response.data.phone,
                        comment: response.data.comment,
                        general: response.data.general,
                        latitude: response.data.latitude,
                        longitude: response.data.longitude,
                        name: response.data.name,
                    };
                    
                    setFormData(obj)
                }
            } catch(err) {
               window.location.href = '/404'
            }
        }

        fetchData();
// eslint-disable-next-line
    }, [])
    

    /*useEffect(() => {
        const fetchData = async () => {
            const response = await appAPI.putAddress(props.activeAddress)
            
        }
        fetchData();
    }, [])*/

    const submitHandler = async (event) => {
        event.preventDefault();
        
        try{ 
            await appAPI.putAddress(
                props.activeAddress,
                formData.street, 
                formData.building, 
                formData.phone, 
                formData.comment,
                formData.latitude, 
                formData.longitude, 
                formData.general,
                formData.name);
            props.setActiveBlock('showAddresses')
        } catch(err) {
            alert('Некорректные данные')
        }

    }

    const changeHandler = (e) => {
        if(e.target.type === 'checkbox') {
            e.target.checked ? setFormData({...formData, general: true}) : setFormData({...formData, general: false})
            
        } else {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        }
        
    }

    const clickCancel = (e) => {
        e.preventDefault()
        setFormData({})
        props.setActiveBlock('showAddresses')
    }

    const onChangeAddress = (e) => {
        
        const val = e.value;
        const lat = e.data.geo_lat;
        const long = e.data.geo_lon;
        setFormData({ ...formData, 
            street: val,
            latitude: lat,
            longitude: long
        })
    }

    return(
        <div className="lk-addresses__add">
            <div className="lk-addresses__add-form">
                <h4>Адрес</h4>
                <form className="form form_addresses" onSubmit={submitHandler}>
                    <div className="form_addresses__block form_addresses__block_left">
                        <AddressSuggestions                         
                            placeholder="*Город, улица, дом, корпус/строение"
                            token="bfb7568c9faac153f1051a1b01ef035aa88b96eb" 
                            value={valueAddress} 
                            onChange={onChangeAddress}
                            defaultValue = {formData.street}
                            inputProps = {
                            {   
                                placeholder: "*Город, улица, дом, корпус/строение",
                                type: 'text',
                                name: 'street',
                            }
                            }
                        />
                        <input 
                            type="text" 
                            name = 'building'
                            className="form__field form__field_input" 
                            placeholder="*Квартира, подъезд, этаж, домофон"
                            onChange = {changeHandler}
                            defaultValue = {formData.building}
                        />
                        <InputMask 
                            type = "phone" 
                            name = 'phone' 
                            className="form__field form__field_input" 
                            placeholder = '*+7' 
                            mask="+7 999 999 99 99"
                            onChange={changeHandler}
                            defaultValue = {formData.phone}
                            />
                        <input 
                            type="text" 
                            name = 'name'
                            className="form__field form__field_input" 
                            placeholder="*Имя получателя заказа"
                            onChange = {changeHandler}
                            defaultValue = {formData.name}
                        />                        
                        <p><sup>*</sup>Поля обязательные для заполнения</p>
                    </div>
                    <div className="form_addresses__block form_addresses__block_right">
                        <textarea 
                            name = "comment" 
                            className = "form__field form__field_textarea" 
                            onChange = {changeHandler} 
                            placeholder = 'Укажите детали адреса доставки: Домофон, Особенности двора и тд.'
                            defaultValue = {formData.comment}
                            />
                        <p className = "form__field_checkbox">

                            <label htmlFor = 'main'><input id = 'main' type="checkbox" onChange = {changeHandler}/><span>Сделать основным</span></label>
                        </p>
                        <div className = "form_addresses__btns">
                            <button onClick = {clickCancel} className="form-btn btn">Отменить</button>
                            <button type ='submit'className="form-btn btn">Сохранить</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PatchAddress;