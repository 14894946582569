import React, { useState } from 'react';
import SliderCategoriesCard from '../../SliderCategories/SliderCards/SliderCategoriesCard';
import './CatalogFilter.sass';
import useResolution from "../../../API/useResolution";

const CatalogSubcategoryFilter = ({categories}) => {

  const isMobile = useResolution();
  const [activeCategory, setActiveCategory] = useState('Все');
  const clickHandler = filterName => setActiveCategory(filterName)
  const ids = [];

  console.log(categories)

  return (
    <div>
      <div className='recepies-filter__buttons'>
        <button
          className={`recepies-filter__button ${activeCategory === 'Все' ? 'active' : ''}`}
          onClick={() => clickHandler('Все')}>
          Все
        </button>

        {categories.map(category =>
          category.name &&
          <button
            className={`recepies-filter__button ${activeCategory === category.name ? 'active' : ''}`}
            key={category.id}
            onClick={() => clickHandler(category.name)}>
            {category.name}
          </button>
        )}
      </div>
      <div className={isMobile ? 'category-cards' : 'recepies-cards recepies-cards_catalog-category'}>
        {activeCategory === 'Все'
          ? categories.map(category =>
              category.products.map(product => {
                if (ids.includes(product.id)) {
                  return null
                }
                ids.push(product.id);
                return <SliderCategoriesCard key = {product.id} product={product} />
              })
            )
          : categories.map(category =>
              category.name === activeCategory ? (
                category.products.length > 0 ?
                  category.products.map(product => 
                    <SliderCategoriesCard key = {product.id} product = {product} />
                  )
                : <div className = 'placeholder-block' key = {category.id}>
                    <img className = 'placeholder-fish' src="/img/catalog/placeholderFish.svg" alt="img"/>
                    <p className = 'placeholder-desc'>Ваша рыба еще ловится</p>
                  </div>
            ) : null
            )}
      </div>
    </div>
  );
};

export default CatalogSubcategoryFilter;
