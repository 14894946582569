import React from 'react';
import './Delivery.sass';
import NavigationList from '../../Components/NavigationList/NavigationList'

const Delivery = () => {

  React.useEffect(() => window.scrollTo(0,0), [])
  return (
    <section className='section section_delivery'>
      <div className='container container_delivery'>
        <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Доставка', src: ''}]}/>
        <div className='delivery'>
          <h2 className='delivery__title'>Доставка и оплата</h2>
          <div className='delivery-del'>
            <div className='delivery-del__desc'>
              <h3 className='delivery-delivery__title'>Доставка</h3>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Приём заказов осуществляется:</h4>
                <p className='delivery-block__desc'>Пн- Вс : с 10 до 21</p>
              </div>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Доставка осуществляется</h4>
                <p className='delivery-block__desc'>Пн- Вс : с 11 до 22</p>
              </div>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Стоимость доставки в пределах КАД:</h4>
                <p className='delivery-block__desc'>300 ₽</p>
              </div>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Стоимость доставки в СПб за пределами КАД:</h4>
                <p className='delivery-block__desc'>400 ₽</p>
              </div>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Стоимость доставки в ЛО:</h4>
                <p className='delivery-block__desc'>500 ₽</p>
              </div>
              <div className='delivery-block'>
                <h4 className='delivery-block__title'>Бесплатная доставка:</h4>
                <p className='delivery-block__desc'>При покупке товаров на сумму от 5 000 ₽</p>
              </div>
              <p className='delivery-block__desc'>Товары доставляются только в рамках зоны доставки</p>
            </div>
            <div>
              <img src='/img/delivery/map.svg' className='delivery-del__map' alt='' />
            </div>
          </div>
          <div className='delivery-vivoz'>
            <h3 className='delivery-vivoz__title'>Самовывоз</h3>
            <div className='delivery-block'>
              <h4 className='delivery-block__title'>Приём заказов осуществляется:</h4>
              <p className='delivery-block__desc'>Пн- Вс : с 10 до 20</p>
            </div>
            <div className='delivery-block'>
              <h4 className='delivery-block__title'>Доставка осуществляется</h4>
              <p className='delivery-block__desc'>Пн- Вс : с 10 до 21</p>
            </div>
            <div className='delivery-block'>
              <h4 className='delivery-block__title'>Срок резерва</h4>
              <p className='delivery-block__desc'>
                С момента открытия до мемента закрытия магазина в рамках одного дня
              </p>
            </div>
            <div className='delivery-block'>
              <h4 className='delivery-block__title'>Стоимость самовывоза:</h4>
              <p className='delivery-block__desc'>Бесплатно</p>
            </div>
          </div>
          <div className='delivery-payment'>
            <h3 className='delivery-payment__title'>Оплата</h3>
            <p className='delivery-payment__desc'>
              Оплата товаров для доставки и самовывоза возможна только онлайн при формировании заказа
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Delivery;
