import React from "react";
import "./Contacts.sass";

const SuccessModal = ({close, className}) => {
    return (
        <div className={`successModal ${className}`}>
            <p className="successModal__message">Ваше сообщение успешно доставлено</p>
            <button onClick={close} className="successModal__button">Ок</button>
        </div>
    )
}

export default SuccessModal