import {appAPI} from '../../../API/API'

// const packetId = '17266d47-4b41-11eb-ab51-a4bf01485720'

export const cartFunctions = {

    async addProductCart (product) {
        const cart = JSON.parse(localStorage.getItem('cart'))
        // localStorage.setItem('cart', JSON.stringify([...cart, product]))
        if (cart && cart.length > 0) {
            localStorage.setItem('cart', JSON.stringify([...cart, product])) 
        } else {
            const defaultProduct = JSON.parse(localStorage.getItem('defaultProduct'))
            localStorage.setItem('cart', JSON.stringify([{amount: 1, ...defaultProduct}, product]));
        }
    },

    delProductCart (id) {
        // if(id === packetId) return;
        const cart = JSON.parse(localStorage.getItem('cart'))
        localStorage.setItem('cart', JSON.stringify(cart.filter(product => product.id !== id)))
    },

    putProductCart (id, amount) {
        // if(id === packetId && amount === 0) return;
        const cart = JSON.parse(localStorage.getItem('cart'))
        amount === 0 
            ? localStorage.setItem('cart', JSON.stringify(cart.filter(product => product.id !== id)))
            : localStorage.setItem('cart', JSON.stringify(cart.map( product => product.id === id ? {...product, amount}: product))) 
    },

    async addProductCartAuth (product) {
        await appAPI.postCartProduct(product.amount, product.id)
        .then(() => this.addProductCart(product))
        .catch(err => console.log(err))
    },

    async delProductCartAuth (id) {
        // if(id === packetId) return;
        await appAPI.delCartProduct(id)
        .then(() => this.delProductCart(id))
        .catch(err => console.log(err))
    },

    async putProductCartAuth (id, amount) {
        // if(id === packetId && amount === 0) return;
        await appAPI.putCartProduct(amount, id)
        .then(() => this.putProductCart(id, amount))
        .catch(err => console.log(err))
    },

    getItemFromLocal (id) {
        const cart = JSON.parse(localStorage.getItem('cart'))
        if(cart) return cart.find(item => item.id === id)
    },

};
