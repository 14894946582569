import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import useResolution from "../../../API/useResolution";


const CartForm = () => {

    const isMobile = useResolution();
    const [flag, setFlag] = useState(false)
    const [location] = useState(window.location.href)
    const cartValues = useSelector((state) => state.Cart);
    const order = useSelector((state) => state.Order)
    const deliveryPrice = order.deliveryPrice;

    useEffect(() => {
        location.includes('oformlenie') ? setFlag(true) : setFlag(false)
// eslint-disable-next-line
    }, [])

    useEffect(() => {
        
    }, [cartValues.render])

    const showOstatok = () => {
        return 5000 - cartValues.summa <= 0 ? '0' : 5000 - cartValues.summa;
    };

    return(
        <div className='cart-details'>
            <div className='cart-order__top'>
                <p className='cart-order__desc'>Сумма заказа: {cartValues.summa} ₽</p>
                <p className='cart-order__weight'>Вес заказа: {cartValues.weight?.toFixed(2)} кг</p>
                {!isMobile && <input type="text" className='form__field form__field_input' placeholder='Ввести промокод'/>}
            </div>
            <div className='cart-order'>
            <div className='cart-order__itog'>
                <div className='cart-order__itogovaya'>
                    <p>Итого: { cartValues.summa < 5000 && order.orderReceiving !== 'PICKUP' ? cartValues.summa + deliveryPrice : cartValues.summa} ₽</p>
                    {deliveryPrice === 0 && <p>Стоимость без учета доставки</p>} 
                    <p className='cart-order__description'>
                        Стоимость весового товара предварительно рассчитывается 
                        с максимальном возможным весом единицы товара. 
                        После оформления заказа будет произведен перерасчёт,
                        разница будет возвращена покупателю.
                    </p>
                </div>
                {flag === false && cartValues.summa !== 0 ? (
                    <Link onClick = { () => setFlag(true)} className = 'btn cart-order__btn form-btn' to = '/cart/oformlenie'>
                        Оформить заказ
                    </Link>

                ) : null
                }

                </div>
            </div>
            {isMobile && (<div className="cart__promo">
                <input type="text" className='form__field form__field_input' placeholder='Ввести промокод'/>
            </div>)}
            <div className='cart-bottom'>
            <p className='cart-bottom_desc'>Закажите на 5 000 рублей и доставка будет бесплатной</p>
            <div className='cart-bottom__graph'>
                <div className='graph__img'>
                <div className='graph__green' style={{width: cartValues.summa/5000* 100 + '%'}}></div>
                </div>
                <div className='graph__points'>
                <p> {cartValues.summa} ₽</p>
                <p>Осталось {showOstatok()} ₽</p>
                <p>5000 ₽</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default CartForm;