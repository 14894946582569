import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import '../Oformlenie.sass'
import MapYandex from '../../Map/Map/Map';
import {addType} from '../../../Actions/OrderActions'
import {appAPI} from "../../../API/API";

const GetWayVivoz = () => {

    const dispatch = useDispatch();
    const {isAuth} = useSelector(state => state.User);
    const {cartId} = useSelector(state => state.Cart)

    const getUnauthorizedUserProducts = () => {
        const products = [];
        // console.log(localStorage.key(0), localStorage.getItem(localStorage.key(0)));
        const cart = JSON.parse(localStorage.getItem('cart'));
        for (let i = 0; i < cart.length; i++) {
            products.push({productId: cart[i].id, amount: cart[i].amount})
        }
        return products;
    }

    useEffect(() => {
        dispatch(addType('PICKUP'))
        // eslint-disable-next-line
    }, [])

    return(
        <div className="oformlenie-get__vivoz">
            <h4 style = {{marginBottom: '15px'}}>Выбор магазина</h4>
            <MapYandex filter = {true} apiFunc={() => isAuth ? appAPI.getRemainsShops(cartId) : appAPI.getRemainsShopNotAuth(getUnauthorizedUserProducts()) }/>
        </div>
    )
}

export default GetWayVivoz;