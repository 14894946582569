import React from 'react';
import { Route, Link, useRouteMatch } from 'react-router-dom';
import './ShopLink.sass';
import {apiURL} from '../../../API/API'

const ShopLink = ({ props }) => {

  let { url } = useRouteMatch();
  let newDate = new Date().getHours();


  return (
    <Route exact path={`${url}`}>
      <div className='shop-link'>
        <div>
          <Link to={`${url}/${props.id}`}>
            {props.images[0]
                ? <img src={`${apiURL}${props.images[0].url}`} alt='img' className='shop-link__img' />
                : <img src='/img/shops/shopEmpty.svg' alt='img' className='shop-link__img' />
            }
            <h3 className='shop-link__title'>{props.name}</h3>
          </Link>
          <div className='shop-link__subway'>
            <p className='shop-link__subway'>{props.underground}</p>
          </div>
        </div>
        <div>
          <div className='shop-link__phone'>
            <p>Телефон магазина</p>
            <span>{props.shopPhone}</span>
          </div>
          <div className='shop-link__phone'>
            <p>Телефон администратора</p>
            <span>{props.adminPhone}</span>
          </div>
          <div className='shop-link__status'>
            <p className='shop-link__status_open'>
              {
                newDate < props.closeTime.split(':')[0]
                    ? `Открыт. Закроется в ${props.closeTime}`
                    : 'Закрыт.'
              }
            </p>
            <p>{props.status}</p>
          </div>
        </div>
      </div>
    </Route>
  );
};

export default ShopLink;
