import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {apiURL} from '../../API/API'
import { rerenderCart} from '../../Actions/CartActions'
import './Cart.sass';
import { cartFunctions } from '../../pages/CartPage/CartFunctions/CartFunctions';

const CartItem = ({props, amount}) => {

  const dispatch = useDispatch();
  const {isAuth} = useSelector((state) => state.User)
  const {render} = useSelector(state => state.Cart)


  const counterCart = (sign) => {
    if(isAuth) {
        cartFunctions.putProductCartAuth(props.id, amount + 1 * sign) 
        .then(() => dispatch(rerenderCart(render + 1)))
    } else {
        cartFunctions.putProductCart(props.id, amount + 1 * sign)
        dispatch(rerenderCart(render + 1))
    }
  }

  const deleteItem = async () => {

    if(isAuth) {
      cartFunctions.delProductCartAuth(props.id) 
        .then(() => dispatch(rerenderCart(render + 1)))
    } else {
      cartFunctions.delProductCart(props.id)
        dispatch(rerenderCart(render + 1))
    }

  }

  return (
    <div className='cart-item'>
      {props.images
        ? <img src={`${apiURL}${props.images[0].url}`} alt='img' className='cart-item__img' /> 
        : <img src = '/img/blocks/caviar/caviar.jpg' alt='img' className='cart-item__img' /> }
      <div className='cart-item__description'>
        <p className='cart-item__name'>{props.name}</p>
        <p className='cart-item__articul'>{props.articul}</p>
      </div>
      <div className='cart-item__right'>
        <div className='cart-item__counter'>
          <img
            src='/img/cart/cartMines.svg'
            alt='Mines'
            className='cart-item__btn cart-item__btn_mines'
            onClick={() => counterCart(-1)}
          />
          {isAuth 
                    ? <span className='cart-item__show' type='number'>{amount}</span>
                    : <input className='cart-item__show' type='number' placeholder={amount}/>
                }
          <img
            src='/img/cart/cartPlus.svg'
            alt='Plus'
            className='cart-item__btn cart-item__btn_plus'
            onClick={() => counterCart(1)}
          />
        </div>
        <div className='cart-item__costs'>
          <p className='cart-item__cost'>{
            isAuth ? (props.promotionalPrice ? props.promotionalPrice * amount : props.ordinaryPrice * amount) :
          props.promotionalPrice ? props.promotionalPrice * amount : props.ordinaryPrice * amount} ₽</p>
          <p className='cart-item__amount'>
            {isAuth ? 
            (
              props.promotionalPrice ? props.promotionalPrice + 'x' + amount : props.ordinaryPrice + 'x'  + amount
            ): props.promotionalPrice ? props.promotionalPrice + 'x' + amount : props.ordinaryPrice + 'x'  + amount}
          </p>
        </div>

        <img
          src='/img/forms/formClose.svg'
          alt='deleteItem'
          className='btn_close'
          onClick = {deleteItem}
        />
      </div>
    </div>
  );
};

export default CartItem;
