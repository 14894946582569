import React, {useState, useEffect} from 'react';
import './CatalogCategory.sass';
import CatalogFilter from '../../CatalogFilter/CatalogFilter';
import { appAPI } from '../../../../API/API';
import {useSelector} from 'react-redux'

const CatalogCategory = ({match}) => {

  const id = match.params.id;

  const {token} = useSelector(state => state.User)
  const [categories, setCategories] = useState([])
  const [allProducts, setAllProducts] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      await appAPI.getCategoriesWithGroupsByGroupId(id, token)
        .then(response => {
          // if (response.data.products && response.data.products.length > 0 ) {
          //   response.data.categories.push({products: response.data.products})
          // }
          console.log('response.data', response.data)
          setAllProducts(response.data.products);
          setCategories(response.data.categories);
        })
        .catch(err => {console.log('err',err); })//err.response.data.errCode === 404 && history.push('/404')
    }
    fetchData();
    window.scroll({top: 0, behavior: 'smooth'})
    // eslint-disable-next-line
  }, [id])
  
  return (
    <div className='catalog-product' style={{maxWidth: '800px'}}>
      {/* <h2 className='catalog__title'>{groupName.title}</h2> */}
      <CatalogFilter categories = {categories} products={allProducts} />
    </div> 
  );
};

export default React.memo(CatalogCategory, (prev, next) => prev.children !== next.children);
