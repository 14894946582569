import React, { useState } from 'react';
import './Filter.sass';
import FilterItem from './FilterItem/FilterItem';
import useResolution from '../../../API/useResolution'

const Filter = (props) => {

  const isMobile = useResolution();
  const [displayItems, setDisplayItems] = useState(props.shopList);

  let [filterPlace, setFilterPlace] = useState(false);
  let [filterSubway, setFilterSubway] = useState(false);
  let [filterStatus, setFilterStatus] = useState(false);

  const isShoppingCenter = (name) => {
    return (
      name.toLowerCase().includes('трк') 
      || name.toLowerCase().includes('тк')
      || name.toLowerCase().includes('трц')
      || name.toLowerCase().includes('тц')
    )
  }

  function filtration() {
    arrFiltered = [];
    for (let i = 0; i < props.shopList.length; i++) {
      if (filterStatus) {
        if (filterPlace && filterSubway) {
          if (props.shopList[i].place && props.shopList[i].isNearTheSubway// eslint-disable-line
            || props.shopList[i].name && // eslint-disable-line
            (isShoppingCenter(props.shopList[i].name)))
              arrFiltered.push(props.shopList[i]);
        }
        if (filterPlace && !filterSubway) {
          if (props.shopList[i].place && !props.shopList[i].isNearTheSubway // eslint-disable-line
            || props.shopList[i].name && // eslint-disable-line
            (isShoppingCenter(props.shopList[i].name))) arrFiltered.push(props.shopList[i]);
        }
        if (!filterPlace && filterSubway) {
          if (!props.shopList[i].place && props.shopList[i].isNearTheSubway) arrFiltered.push(props.shopList[i]);
        }
        if (!filterPlace && !filterSubway) {
          arrFiltered.push(props.shopList[i]);
        }
      } else if (
        (filterPlace && filterPlace === isShoppingCenter(props.shopList[i].name) 
          && props.shopList[i].name && !filterSubway) 
          ||
        (filterPlace && filterPlace === isShoppingCenter(props.shopList[i].name) 
        && props.shopList[i].name && filterSubway && filterSubway === props.shopList[i].isNearTheSubway)
        ||
        (!filterPlace
        && props.shopList[i].name && filterSubway && filterSubway === props.shopList[i].isNearTheSubway)
      ) {
        arrFiltered.push(props.shopList[i]);
      }
    }

    if (!filterPlace && !filterSubway && !filterStatus) {
      setDisplayItems(props.shopList);
    } else setDisplayItems(arrFiltered);
  }

  let arrFiltered = [];
  const onClickPlace = (e) => {
    if (e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove('active');
      filterPlace = false;
      setFilterPlace(filterPlace);
    } else {
      e.currentTarget.classList.add('active');
      filterPlace = true;
      setFilterPlace(filterPlace);
    }
    filtration();
  };
  const onClickSubway = (e) => {
    if (e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove('active');
      filterSubway = false;
      setFilterSubway(filterSubway);
    } else {
      e.currentTarget.classList.add('active');
      filterSubway = true;
      setFilterSubway(filterSubway);
    }
    filtration();
  };
  const onClickStatus = (e) => {
    if (e.currentTarget.classList.contains('active')) {
      e.currentTarget.classList.remove('active');
      filterStatus = false;
      setFilterStatus(filterStatus);
    } else {
      e.currentTarget.classList.add('active');
      filterStatus = true;
      setFilterStatus(filterStatus);
    }
    filtration();
  };

  const changeHandler = (e) => {
    let filterArr = props.shopList.filter((el, i) => { // eslint-disable-line
      let val = e.currentTarget.value.toLowerCase();
      if (el.name && el.name.toLowerCase().includes(val) || el.underground && el.underground.toLowerCase().includes(val)) { // eslint-disable-line
        return el
      }
    });
    setDisplayItems(filterArr);
  };

  return (
    <div className='filter-map'>
      <div className='filter-action'>
        <input type='text' className='filter-search' placeholder='Введите адрес или метро' 
          onChange={changeHandler} 
          />
        <div className='filter-action__buttons'>
          <button className='filter-action__btn' name='Place' onClick={onClickPlace}>
            Торговый центр
          </button>
          <button className='filter-action__btn' name='Subway' onClick={onClickSubway}>
            У метро
          </button>
          <button className='filter-action__btn' name='Status' onClick={onClickStatus}>
            Открыт
          </button>
        </div>
        {isMobile ? <a rel="noopener noreferrer" className = 'filter-action__btn_link' target = '_blank' href = 'https://yandex.ru/maps/2/saint-petersburg/chain/ikra_i_ryba/25889259969/?clid=9403&ll=30.356454%2C59.950646&sll=30.305582%2C59.918077&sspn=0.525496%2C0.346619&z=11'>Наши магазины на картах Яндекс</a> : null}
      </div>
      <div className='filter-content'>
        {displayItems.map((el) => (
          <FilterItem 
            shop={el} 
            key={el.id} 
            setActiveBalloon = {props.setActiveBalloon}
            activeBalloon = {props.activeBalloon}
            />
        ))}
      </div>
    </div>
  );
};

export default Filter;
