import React, {useState, useEffect} from 'react';
import {appAPI} from '../../../API/API'
import ReviewsItem from './ReviewsItem';
import './ReviewsItems.sass';

const ReviewsItems = () => {

    const [reviewsData, setReviewsData] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const [nextReviewsData, setNextReviewsData] = useState([])

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try{
                const response = await appAPI.getReviewsPagination(`/reviews?offset=0&limit=3`);
                if (isMounted && response.statusCode === 200) {
                    
                    setReviewsData(response.data.paginated)
                    setNextReviewsData(response.data.nextPage)
                    setIsFetching(true)
                }
            } catch(err) {
                window.location.href = '/404'
            }

        }
        fetchData();
        return () => { isMounted = false };
    }, [])

    const loadData = () => {
        let isMounted = true;
        const fetchData = async () => {
            const response = await appAPI.getReviewsPagination(nextReviewsData);
            if (isMounted && response.statusCode === 200) {
                setReviewsData( (prevData) => [
                    ...prevData,
                    ...response.data.paginated
                ])
                setNextReviewsData(response.data.nextPage)
            }
        }
        fetchData();
        return () => { isMounted = false };
    }
    return(
        <>
        <div className="reviews-items">
            {isFetching === true ? (
                reviewsData.map((review) => <ReviewsItem props = {review} key = {review.id}/>)
            ): null}
            
        </div>
        <button className="review-load" onClick = {loadData}>Загрузить еще</button>
        </>
    )
}
export default ReviewsItems;