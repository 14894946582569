import {SET_FORM} from '../Actions/FormActions';

const FormReducer = (
    state = {
        activeForm: 'signin',
        open: false
    },
    action
) => {
    switch (action.type) {
        case SET_FORM:
            return {
                ...state,
                activeForm: action.activeForm,
                open: action.open,
            }
        default:
            return {
                ...state
            }
    }
}


export {FormReducer};