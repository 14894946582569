const SET_FORM = 'SET_FORM'

const setForm = (activeForm, open) => {
    return {
        type: SET_FORM,
        activeForm,
        open
    }
}

export {SET_FORM}
export {setForm}