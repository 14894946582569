import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {Modal} from "react-bootstrap";
import {closeModalAction} from "../../Actions/ModalActions";
import './Modal.sass';

const CustomModal = () => {
    const {modalBody: Component, ...props} = useSelector(state => state.Modal);

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeModalAction())
    }

    if (!Component) return <></>



    return (
        <Modal
            show={props.isShow}
            onHide={closeModal}
            className={props.parameters.withoutPadding === false ? 'noPadding' : ''}
            centered={true}
        >
            {
                props.withBtn && (<Modal.Header closeButton>
                    {props.title && <Modal.Title>{props.title}</Modal.Title>}
                </Modal.Header>)
            }
            <Modal.Body
                onClick={(e) => {
                    if (!e.target.closest('.modal-body__child')) closeModal()
                }}
            >
                <Component className="modal-body__child" {...props.parameters} close={closeModal} />
            </Modal.Body>
        </Modal>
    )
}

export default CustomModal;