const SET_ADDRESSES = 'SET_ADDRESSES'

const setAddressesList = (list) => {
    return {
        type: SET_ADDRESSES,
        list
    }
}


export {SET_ADDRESSES}
export {setAddressesList}