import React, {useState, useRef, useEffect} from 'react';
import PersonalData from '../LKItems/PersonalData';
import Addresses from '../LKItems/Addresses';
import Favorite from '../LKItems/Favourite';
import BuyHistory from '../LKItems/BuyHistory';
import SaveCart from '../LKItems/SaveCart/SaveCart';
import {useSelector} from 'react-redux'
import Slider from 'react-slick';
import useResolution from "../../../API/useResolution";

const LKList = (props) => {

    const isMobile = useResolution();
    const {activeFolder} = useSelector((state) => state.LK)
    const arrItems = ['Личные данные', 'Адреса доставки', 'Избранное', 'История покупок', 'Сохраненные корзины']
    const [activeItem, setActiveItem] = useState(arrItems[activeFolder])
    const slider = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        centerPadding: '30px',
        swipeToSlide: true,
        slidesToShow: 2,
        autoplay: false,
        className: 'carousel'
    };

    useEffect(() => {
        if (slider.current && isMobile) {
            slider.current.slickGoTo(arrItems.indexOf(activeItem));
        }
        // eslint-disable-next-line
    }, [activeItem])

    
    const switchActiveContent = (item) => {
        switch (item) {
            case arrItems[0]:
                return (<PersonalData/>)
            case arrItems[1]:
                return (<Addresses/>)
            case arrItems[2]:
                return (<Favorite/>)
            case arrItems[3]:
                return (<BuyHistory/>)
            case arrItems[4]:
                return (<SaveCart/>)
            default: return;
        }
    }

    

    const Wrapper = ({children}) => isMobile
        ? <Slider ref={slider} {...settings}>{children}</Slider>
        : <>{children}</>

    
    useEffect(() => {window.scrollTo(0,0)}, [])


    return(
        <>
        <ul className = 'lk-list'>
            <Wrapper>
                {arrItems.map((item, i) => (
                    <li className = {`lk-list__item ${activeItem === item ? 'active' : ''}`}
                        key = {i}
                        onClick={() => setActiveItem(item)}
                        style = {{zIndex: `${arrItems.length-i}`}}>
                        {!isMobile && <img alt="" src={`/img/lk/user/${i}.svg`}/>}
                        {item}
                    </li>
                ) )}
            </Wrapper>
        </ul>
        <div className='lk-list__content'>
            {switchActiveContent(activeItem)}
        </div>
        </>
    )   
}

export default LKList;