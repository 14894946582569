import React from 'react';
import {Link} from 'react-router-dom'
import './NavigationList.sass'

const NavigationList = (props) => {

    return (
        <ul className = 'navigation-list'>
            {props.items.map((el, i) =>
            i !== props.items.length - 1 ? (
            <li key = {i}>
                <Link to = {el.src}>
                    <p>{el.name}</p>
                </Link>
                <span>{`>`}</span>
            </li>
            ) : <li key = {i}><p>{el.name}</p></li>
            )}
        </ul>
    )   
}

export default NavigationList;