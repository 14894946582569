import React from 'react';
import './Errors.sass'

const Error502 = () => {
    
    return(
        <section className = 'section_error section_error_502'>
            <div className="container container_error container_error_502">
                <div className="error-text">
                    <h2 className="error-title">502</h2>
                    <p className = 'error-desc'>пожалуйста, свяжитесь с администратором</p> 
                </div>
            </div>
        </section>
    )
}

export default Error502;