import React from 'react';
import './CartPage.sass'

const CartModal = (props) => {

    return(
        <div className="cart-page-modal">
            <h2>Корзина была сохранена!</h2>
            <img src="/img/cart/watchBlue.svg" alt="clock"/>
            <button className="btn form-btn" onClick={ () => props.close(false)}>Вернуться к покупкам</button>
        </div>
    )
}

export default CartModal;