import React, { useState } from 'react';
import Form from './Form';
import { appAPI } from '../../API/API';
import './Forms.sass';
import {useDispatch} from "react-redux";
import {openModalAction} from "../../Actions/ModalActions";
import SignIn from "./SignIn";
import useResolution from "../../API/useResolution";
import {setForm} from '../../Actions/FormActions'

const Recovery = ({close, userEmail, setUserEmail, ...props}) => {
  const dispatch = useDispatch();
  const isMobile = useResolution();

  const openSignIn = () => {
    dispatch(openModalAction(SignIn, true, true))
  }

  const formRecovery = {
    title: 'Восстановление пароля',
    fields: [
      {
        name: 'email',
        type: 'email',
        placeholder: 'Введите e-mail',
      },
    ],
    btn: 'Восстановить',
    firstDesc: 'На указанный адрес будет отправлено сообщение с кодом для восстановления',
    secondDesc: 'Вспомнили пароль?',
    link: 'Войти',
    src: '/',
    modal: openSignIn
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await appAPI.forgotPassword(userEmail.email);
      // openSignIn()
      dispatch(setForm('reset', true))
      if (isMobile) close()
    } catch (err) {
      setFlagValidation('Что-то пошло не так');
    }
    
    
  };

  const [flagValidation, setFlagValidation] = useState(false)

  const validation = (name, value) => {
    if(flagValidation) {
      switch(name) {
        case 'email':
          if(/[\w.%+-]+@[A-Za-z\d.-]+\.\w+/.test(value.email)) { return ''} else return 'active'
        case 'password':
          
          if (value.password.length < 3) { return 'active'} else return ''
        default: return;
      }
    }
  }

  return (
    <Form
      formName={'form_recovery'}
      props={formRecovery}
      state={userEmail}
      setState={setUserEmail}
      submitHandler={submitHandler}
      style = {props.style}
      validation = {validation}
      close={close}
      {...props}
    />
  );
};

export default Recovery;
