import React, { useState } from 'react';
import SliderCategoriesCard from '../../../Components/SliderCategories/SliderCards/SliderCategoriesCard';
import '../../../Components/Catalog/CatalogFilter/CatalogFilter.sass';
import {LKListLoader} from '../../../Components/Common/Loader'

const CatalogNewsFilter = ({products, filterItems, isFetching}) => {


    const [activeCategory, setActiveCategory] = useState('Все');
    const clickHandler = (filterName) => setActiveCategory(filterName);


  return (
    <div>
      <div className='recepies-filter__buttons'>
          <button
            className={`recepies-filter__button ${activeCategory === 'Все' ? 'active' : ''}`}
            onClick={() => clickHandler('Все')}>
            Все
          </button>
        {filterItems.map((el, i) => {
          return (
          <button
            className={`recepies-filter__button ${activeCategory === el.name ? 'active' : ''}`}
            key={i}
            onClick={() => clickHandler(el.name)}>
            {el.name}
          </button>
          )
        })}
      </div>
      <div className='recepies-cards'>
      {isFetching 
        ? <LKListLoader style={{ width: '100%' }} />
        :  activeCategory === 'Все'
            ? products.map(product => <SliderCategoriesCard folder = {product.name} product ={product} key = {product.id}/>) 
            : products.map(product => activeCategory === product.groups[0].name ? <SliderCategoriesCard folder = {product.name} product ={product} key = {product.id}/> : null)
        
      }
                   
        {/* //   : props.data.map((el) =>
        //       el.name === activeCategory ? (
        //         el.categories.map((category) => 
        //           category.products.length !== 0 ?
        //             category.products.map((product) => (                      
        //                 props.page === 'sales' ? (
        //                 product.isSale ? <SliderCategoriesCard folder = {el.name} product ={product} key = {product.id}/> : null
        //                 ) : (
        //                     product.isNew ? <SliderCategoriesCard folder = {el.name} product ={product} key = {product.id}/> : null
        //                 )
                      
        //             ))
        //           : null
        //             )
        //     ) : null
        //     )} */}
      </div>
    </div>
  );
};

export default CatalogNewsFilter;
