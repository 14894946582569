import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addAddress} from '../../../../Actions/OrderActions'
import {appAPI} from '../../../../API/API'
import './Addresses.sass'
import useResolution from "../../../../API/useResolution";

const ShowAddressItem = (props) => {

    const isMobile = useResolution();
    const dispatch = useDispatch();
    const orderState = useSelector((state) => state.Order)


    const chooseDelivery = async (e) => {
        try{
            const response = await appAPI.getDeliveryPrice(props.el.latitude, props.el.longitude)
            dispatch(addAddress(props.el.id, 
                props.el.latitude, 
                props.el.longitude, 
                props.el.comment, 
                `г.${props.el.city}, 
                ул. ${props.el.street}, 
                ${props.el.building}, 
                кв. ${props.el.office}`,
                response.data.deliveryPrice,
                props.el.city,
                props.el.building,
                props.el.street,
                props.el.porch,
                props.el.flat
                ))
        } catch(err) {}
        
    }

    const putAddress = () => {
        props.setActiveBlock('showPatchAddresses')
        props.setActiveAddress(props.el.id)
    }

    const patchAddress = async () => {
        const response = await appAPI.patchAddress(props.el.id)
        response.statusCode === 200 && props.setGeneralAddress(props.el.id)
    }


    return(
        <div className="lk-addresses__show__item">
            <div className="item-icons">
                {props.el.general ? (
                    <img src="/img/lk/addresses/houseFill.svg" alt="houseFill"/> 
                    ) : props.patchAddress ? (
                            <img 
                            src="/img/lk/addresses/house.svg" alt="house" 
                            onClick={patchAddress} />
                        ) : <img 
                        src="/img/lk/addresses/house.svg" alt="house" />}
                <img src="/img/lk/addresses/pencil.svg" alt="pencil" onClick={putAddress}/>
                <img src="/img/lk/addresses/trash.svg" alt="trash" onClick = {() => props.deleteAddress(props.el.id)}/>
            </div>
            <div className="item-block">
                <div className="item-block__field">
                    <h5>Адрес:</h5>
                    <p>{props.el.city}, &nbsp; {props.el.street}, &nbsp; {props.el.building}, &nbsp; кв.{props.el.office}</p>
                </div>
                <div className="item-block__field">
                    <h5>Телефон:</h5>
                    <p>{props.el.phone}</p>
                </div>
                <div className="item-block__field">
                    <h5>ФИО:</h5>
                    <p>{props.el.name}</p>
                </div>
                <div className="item-block__field">
                    <h5>Комментарий:</h5>
                    <p>{props.el.comment}</p>
                </div>
            </div>
            {props.checkbox && !isMobile && <label><input checked={props.el.id === orderState.addressForDelivery} type = 'checkbox' onClick = {chooseDelivery}/><span>Выбрать для доставки</span></label>}
            {props.checkbox && isMobile && (
                <div className="item-block__field">
                    <label className="item-block__field" >
                        <input checked={props.el.id === orderState.addressForDelivery} type ='checkbox' onChange = {chooseDelivery} />
                        <span>Выбрать для доставки</span>
                    </label>
                </div>
            )
            }
        </div>
    )
}

export default ShowAddressItem;