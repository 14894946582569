const SET_LIST = 'SET_LIST'

const setBusketList = (list) => {
    return {
        type: SET_LIST,
        list
    }
}


export {SET_LIST}
export {setBusketList}