import React from 'react';
import './DrawStars.sass'

const DrawStars = ({rate, setRate}) => {


    const arrStars = [];
    const arrStarsFill = [];
    const arrStarsHalf = [];

    for (let i = 1; i <= 5; i++) {
        if (i <= rate) {
            arrStarsFill.push(i)
        } else if (i === Math.ceil(rate)) {
            arrStarsHalf.push(i)
        } else {
            arrStars.push(i)
        }
    }

    // for(let i = 1; i < rate; i++) {
    //     arrStarsFill.push(i)
    // }

    // if (Math.ceil(rate) > Math.floor(rate)) {
    //     arrStarsHalf.push(Math.floor(rate))
    // }

    // for (let i = Math.ceil(rate); i <= 5; i++) {
    //     arrStars.push(i)
    // }  


    return (
        <div className={`draw-stars`}>
            {arrStarsFill.map((star, index) =>  ( //onClick={() => setRate(star + 1)}
                <svg  className = "star-unfilled" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" key = {star}>
                    <path d="M9.47796 39.5377C8.46471 40.0575 7.31496 39.1466 7.51971 37.9837L9.69846 25.5675L0.45059 16.758C-0.413035 15.9337 0.0358402 14.427 1.19347 14.2642L14.0507 12.4372L19.7837 1.07885C20.3008 0.0550977 21.7 0.0550977 22.2171 1.07885L27.9501 12.4372L40.8073 14.2642C41.965 14.427 42.4138 15.9337 41.5502 16.758L32.3023 25.5675L34.4811 37.9837C34.6858 39.1466 33.5361 40.0575 32.5228 39.5377L20.9965 33.6157L9.47534 39.5377H9.47796Z" fill="#F4B928"/>
                </svg>

                )
            )
            }
            {arrStarsHalf.map((star, index) =>  (
                <svg className = "star-unfilled" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" key = {star}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.0549 12.4374L19.7879 1.079C19.898 0.850503 20.0701 0.657512 20.2845 0.522C20.4989 0.386488 20.747 0.3139 21.0007 0.3125C21.481 0.3125 21.9614 0.567125 22.2213 1.079L27.9543 12.4374L40.8115 14.2644C41.132 14.3148 41.4253 14.4741 41.6419 14.7156C41.8586 14.9571 41.9853 15.2659 42.0007 15.59C42.0186 15.8057 41.9878 16.0227 41.9106 16.2249C41.8333 16.4271 41.7116 16.6093 41.5544 16.7581L32.3065 25.5676L34.4853 37.9839C34.69 39.1467 33.5403 40.0576 32.527 39.5379L21.0007 33.6159L9.47953 39.5379C9.35904 39.6005 9.22986 39.6447 9.09628 39.6691C8.20115 39.8266 7.34278 39.0024 7.52128 37.9839L9.70003 25.5676L0.454781 16.7581C0.311526 16.6219 0.197768 16.4577 0.120555 16.2758C0.0433426 16.0938 0.00432014 15.8979 0.00590583 15.7002C0.00657964 15.4209 0.0827688 15.147 0.226406 14.9075C0.329827 14.7344 0.47079 14.5868 0.638893 14.4755C0.806995 14.3642 0.997946 14.292 1.19766 14.2644L14.0549 12.4374ZM21.0007 30.5709C21.2107 30.5709 21.4207 30.6181 21.6097 30.7179L31.2854 35.6896L29.4637 25.3025C29.4207 25.0634 29.4373 24.8174 29.5119 24.5862C29.5866 24.355 29.7169 24.1458 29.8915 23.9769L37.5198 16.7056L26.8833 15.1936C26.663 15.1604 26.4541 15.0742 26.2745 14.9425C26.0948 14.8109 25.9497 14.6376 25.8517 14.4376L21.0059 4.83537L21.0007 4.84325V30.5682V30.5709Z" fill="#F4B928"/>
                </svg>
                )
            )
            }
            {arrStars.map((star, index) => (
                <svg className = "star-filled" width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg" key = {star}>
                 <path fillRule="evenodd" clipRule="evenodd" d="M7.52362 37.9813C7.31887 39.1468 8.46862 40.0576 9.48187 39.5379L21.0056 33.6159L32.5267 39.5379C33.54 40.0576 34.6897 39.1468 34.485 37.9839L32.3062 25.5676L41.5541 16.7581C42.4177 15.9339 41.9689 14.4271 40.8112 14.2644L27.954 12.4374L22.221 1.07901C22.1118 0.84877 21.9395 0.654242 21.7241 0.518029C21.5087 0.381816 21.2591 0.309509 21.0043 0.309509C20.7495 0.309509 20.4999 0.381816 20.2845 0.518029C20.0691 0.654242 19.8968 0.84877 19.7876 1.07901L14.0546 12.44L1.19737 14.267C0.0397465 14.4298 -0.409128 15.9365 0.454497 16.7608L9.70237 25.5703L7.52362 37.9865V37.9813ZM20.3992 30.7179L10.7235 35.6896L12.5452 25.3025C12.5879 25.0634 12.5712 24.8175 12.4966 24.5863C12.422 24.3552 12.2918 24.1459 12.1174 23.9769L4.48912 16.7056L15.1256 15.1936C15.3459 15.1604 15.5548 15.0742 15.7344 14.9426C15.9141 14.8109 16.0592 14.6376 16.1572 14.4376L21.0056 4.83539L25.8514 14.4376C25.9494 14.6376 26.0945 14.8109 26.2742 14.9426C26.4538 15.0742 26.6627 15.1604 26.883 15.1936L37.5195 16.703L29.8912 23.9743C29.7162 24.1434 29.5856 24.3531 29.511 24.5848C29.4364 24.8165 29.42 25.063 29.4634 25.3025L31.2851 35.6896L21.6094 30.7179C21.4223 30.6214 21.2148 30.5711 21.0043 30.5711C20.7938 30.5711 20.5863 30.6214 20.3992 30.7179Z" fill="#1D3A65"/>
                </svg>
            ))}
        </div>
    )
}

export default DrawStars;