import React from 'react';
import './LKItems.sass'

const BuyHistory = () => {

    return(
        <div className = 'lk-history'>
            <h2 className = 'lk-history__title'>Вы пока не совершили ни одной покупки</h2>
        </div>
    )
}

export default BuyHistory;