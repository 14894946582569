import React from 'react';
import NavigationList from '../../Components/NavigationList/NavigationList'
import { Route } from 'react-router-dom';
import MapYandex from '../../Components/Map/Map/Map';
import Shops from '../../Components/Shops/Shops';
import './Shops.sass';

const ShopsPage = () => {
  return (
    <section className='shops-page'>
      <div className='container container_shops'>
        <Route exact path = '/shops'>
          <NavigationList items={[{name: 'Главная', src: '/'}, {name: 'Магазины', src: ''}]}/>
        </Route>
        <h2>Магазины</h2>
        <Route exact path='/shops'>
          <MapYandex />
        </Route>
        <Shops />
      </div>
    </section>
  );
};

export default ShopsPage;
