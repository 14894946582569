import React, {useState, useEffect} from 'react'
import {appAPI} from '../../../../API/API'
import './Addresses.sass'
import ShowAddressItem from './ShowAddressItem'

const ShowAddress = (props) => {

    const [addresses, setAddresses] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await appAPI.getAddresses();
            if(response.statusCode === 200) {
                if(props.sort) response.data.sort((prev, next) => next.general - prev.general)
                setAddresses(response.data)
                setIsFetching(true)
                
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [props.generalAddress])

    const deleteAddress = async (id) => {
        const response = await appAPI.delAddress(id)
        if(response.statusCode === 200) {
            const arrFiltered = addresses.filter((address) => address.id !== id)
            setAddresses(arrFiltered)
        }
    }

    return(
        <div className="lk-addresses__show">
            {isFetching &&
                <>
                    {addresses.map((address, i) =>
                    (
                        <React.Fragment key={i}>
                            <h4>Адрес {i+1}</h4>
                            <ShowAddressItem 
                                el = {address} 
                                deleteAddress = {deleteAddress} 
                                checkbox = {props.checkbox} 
                                setActiveAddress = {props.setActiveAddress} 
                                setActiveBlock={props.setActiveBlock}
                                setGeneralAddress = {props.setGeneralAddress}
                                patchAddress = {props.patchAddress}
                            />
                        </React.Fragment>
                    ))
                    }
                </>
            }
        </div>
    )
}

export default ShowAddress;