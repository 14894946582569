import {ADD_ADDRESS, ADD_FIO, ADD_COORDINATES, ADD_CART, ADD_DELIVERY, ADD_TYPE, ADD_SELLER_ID, ADD_ORDER_TIME, ADD_SBER_ID, CONFIRMATION_PHONE, SET_ADDRESS_NOT_AUTH} from '../Actions/OrderActions';

const OrderReducer = (
    state = {
        addressForDelivery: null,
        username: '',
        email: '',
        phone: '',
        cartProducts: '',
        orderReceiving: 'PICKUP',
        deliveryDate: '2020-10-20',
        deliveryInterval: 'MORNING',
        latitude: '',
        longitude: '',
        userId: '',
        sellerId: '',
        comment: '',
        city: '',
        phoneShop: '',
        phoneAdmin: '',
        time: '',
        sberId: '',
        deliveryPrice: 0,
        confirmPhone: false, 
        addressCity: '',
        addressHome: '',
        street: '',
        buiding: '',
        porch: '',
        floor: '',
        office: ''
    },
    action
) => {
    switch (action.type) {
        case ADD_ADDRESS:
            return {
                ...state,
                addressForDelivery: action.address,
                latitude: action.latitude,
                longitude: action.longitude,
                comment: action.comment,
                city: action.city,
                deliveryPrice: action.deliveryPrice,
                addressCity: action.addressCity,
                addressHome: action.addressHome,
                street: action.street,
                building: action.building,
                porch: action.porch
            }
        

        case ADD_FIO:
            return {
                ...state,
                username: action.username,
                email: action.email,
                phone: '8' + action.phone.slice(2)
            }

        case ADD_COORDINATES:
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude,
                deliveryPrice: action.deliveryPrice,
                addressCity: action.city,
                street: action.street,
                building: action.building,
                fullAddress: action.fullAddress,
            }

        case SET_ADDRESS_NOT_AUTH:
            return {
                ...state,
                porch: action.porch,
                comment: action.comment,
                floor: action.floor,
                office: action.office
            }
        case ADD_CART: {
            return {
                ...state,
                cartProducts: action.cartProducts
            }
        }

        case ADD_DELIVERY: {
            return {
                ...state,
                deliveryDate: action.deliveryDate,
                deliveryInterval: action.deliveryInterval
            }
        }

        case ADD_ORDER_TIME: {
            return {
                ...state,
                time: action.time
            }
        }

        case ADD_TYPE: {
            return {
                ...state,
                orderReceiving: action.orderReceiving
            }
        }

        case ADD_SELLER_ID: {
            return {
                ...state,
                sellerId: action.sellerId,
                phoneShop: action.phoneShop,
                phoneAdmin: action.phoneAdmin,
                addressForDelivery: action.shopId,
                city: action.address
            }
        }

        case ADD_SBER_ID: {
            return {
                ...state,
                sberId: action.sberId
            }
        }

        case CONFIRMATION_PHONE: {
            return {
                ...state,
                confirmPhone: true
            }
        }

        default:
            return {
                ...state
            }
    }
}


export {OrderReducer};