import React, { useState, useEffect } from 'react';
import {appAPI} from '../../../API/API'
import {YMaps, Map, Placemark, Button, ZoomControl} from 'react-yandex-maps';
import './Map.sass';
import Filter from '../Filter/Filter';
import useResolution from "../../../API/useResolution";

const MapYandex = ({apiFunc = appAPI.getShops , ...props}) => {

  const [zoom, setZoom] = useState(10)
  const isMobile = useResolution();
  const mapState = {
    center: [59.939095, 30.315868],
    zoom: zoom,
    controls: [
    ]
  };

  const [shopsMarks, setShopsMarks] = useState([])
  const [activeBalloon, setActiveBalloon] = useState('e8b59ee3-d811-4552-a528-c0ff48737d0a')
  const [shopList, setShopList] = useState([])
  const [isFetching, setIsFetching] = useState(false)
// eslint-disable-next-line
  const [stateImgBalloon, setStateImgBallon] = useState([])
  let newDate = new Date().getHours();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await apiFunc()
      if(isMounted && response.statusCode === 200) {
        setShopList(response.data);
        const arrShops = []
        const pointPics = []
        console.log(response.data);
        for(let i = 0; i < response.data.length; i++) {
          arrShops.push({
            geometry: [response.data[i].latitude, response.data[i].longitude],
            properties: {
              balloonContent:
              `<h3 class= "balloon-header">${response.data[i].name}</h3>` +
              `<p class="balloon-description">${response.data[i].underground}<br/></p>` +
              '<p class = "balloon-description">Телефон администратора<br/></p>' +
              `<a class = "balloon-link" href="tel:+7 952 242 36 22">${response.data[i].adminPhone}</a><br/>` +
              `<p class = "balloon-description">${
                newDate < response.data[i].closeTime.split(':')[0] 
                ? `Открыт. Закроется в ${response.data[i].closeTime}`
                : 'Закрыт.'
              }</p>`,
              iconContent: 'Узнать адрес',
            }
          })
          pointPics.push(false);
          
        }
        setStateImgBallon(pointPics)
        setShopsMarks(arrShops)
        setIsFetching(true)
      }
    }
    fetchData();
    return () => { isMounted = false };
    // eslint-disable-next-line
  }, [])

  return (
    <div className='container container_map'>
      <YMaps
        query={{
          apikey: 'ffa8919c-f377-4df6-b245-444b0b7c81e3',
        }}
        className = 'myYmap'
        >
        {isFetching &&
        <Map
          style={{position: 'relative'}}
          state={mapState}
          className='map'
          options={{
            minZoom: 4,
            maxZoom: 15,
          }}
          modules={[
            'control.SearchControl',
            'geoObject.addon.balloon',
            'geoObject.addon.hint',
            'templateLayoutFactory',
            'layout.ImageWithContent',
          ]}
          instanceRef={(ref) => {
            ref && !isMobile && ref.behaviors.disable('scrollZoom');
          }}>
          {/*<Button options={{*/}
          {/*  // position: 'absolute',*/}
          {/*  left: 500,*/}
          {/*  top: 100,*/}
          {/*  selectOnClick: false*/}
          {/*}} />*/}
          {
            shopsMarks.length ?
              shopsMarks.map((el, i) => (
                <PlacemarkWrapper
                  {...el}
                  key={i}
                  options={{
                    iconLayout: 'default#image',
                    iconImageSize: [50, 50],
                    // iconImageHref: stateImgBalloon[i] ? '/img/map/ballonRed.svg' : '/img/map/balloonBlack.svg',
                    hideIconOnBalloonOpen: false
                  }}
                  instanceRef={ref => { shopList[i].id === activeBalloon && ref && ref.balloon.open()}}
                />
                // <Placemark
                //   {...el}
                //   key={i}
                //   options={{
                //     iconLayout: 'default#image',
                //     iconImageSize: [50, 50],
                //     iconImageHref: stateImgBalloon[i] ? '/img/map/ballonRed.svg' : '/img/map/balloonBlack.svg',
                //     hideIconOnBalloonOpen: false
                //   }}
                //   instanceRef={ref => { shopList[i].id === activeBalloon && ref && ref.balloon.open()}}
                //   onMouseEnter = {(e) => {
                //     setStateImgBallon((prev) => prev.map((item, index) => index === i));
                //   }}
                //   onMouseLeave = {() => {
                //     setStateImgBallon((prev) => prev.map(() => false));
                //   }}
                // />
              ))
            : <div className = 'shops-remains'><h2>Извините, заданного перечня товаров нет в наличии в одном конкретном магазине, пожалуйста, воспользуйтесь доставкой.</h2></div>
          }
         {shopsMarks.length  ? <a rel="noopener noreferrer" className = {isMobile ? 'map-link-yandex mobile' : 'map-link-yandex'} target = '_blank' href = 'https://yandex.ru/maps/2/saint-petersburg/chain/ikra_i_ryba/25889259969/?clid=9403&ll=30.356454%2C59.950646&sll=30.305582%2C59.918077&sspn=0.525496%2C0.346619&z=11'>Наши магазины на картах Яндекс</a> : null}
        </Map>        
        }
      </YMaps>
      <div className='zoom-buttons'>
        <img src="/img/map/zoomPlus.svg" alt="plus" onClick={()=>{setZoom(zoom+1)}}/>
        <img src="/img/map/zoomMinus.svg" alt="plus" onClick={()=>{setZoom(zoom-1)}}/>
      </div>
      {shopList.length !== 0 ? <Filter shopList = {shopList} setActiveBalloon = {setActiveBalloon} activeBalloon = {activeBalloon}/> : null}
    </div>
  );
};

export default MapYandex;


const PlacemarkWrapper = ({options, ...props}) => {
  const [img, setImg] = useState('/img/map/balloonBlack.svg');

  return (
    <Placemark
      {...props}
      options={{ iconImageHref: img, ...options}}
      onMouseEnter = {(e) => {
        setImg('/img/map/ballonRed.svg');
      }}
      onMouseLeave = {() => {
        setImg('/img/map/balloonBlack.svg');
      }}
    />
  )
}