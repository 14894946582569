import React, {useState, useEffect} from 'react'
import {appAPI} from '../../../API/API'
import {useSelector} from 'react-redux'
import Slider from 'react-slick';
import settings from '../SliderMain/SliderSettings'
import SliderCategoriesCard from '../../SliderCategories/SliderCards/SliderCategoriesCard'
import useResolution from "../../../API/useResolution";

const MainNew = () => {

    const [data, setData] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const {isAuth, token} = useSelector((state) => state.User)
    const isMobile = useResolution();

    useEffect(() => {
        
        let isMounted = true;
        const fetchData = async () => {
        if(isAuth) {
            try{
                const result = await appAPI.getProductsNew(token);
                if(isMounted) {
                    setData(result.data);
                    setIsFetching(true)
                }
            } catch(err) {
                
            }
        } else {
            try{
                const result = await appAPI.getProductsNew();
                if(isMounted) {
                    setData(result.data);
                    setIsFetching(true) 
                }
            } catch(err){}

        }
    };
        fetchData();
        return () => { isMounted = false }
        // eslint-disable-next-line
    }, []);

    return(
        <div className="block block_slider">
        <h3 className="block__title">Новинки</h3>
        {isFetching ? (
            <Slider {...settings(isMobile)}>
                {data.map((product) => 
                    product.groups.length > 0 
                    ? <SliderCategoriesCard  folder = {product.category[0] && product.category[0].group ? product.category[0].group.name : null} product = {product} key = {product.id}/>
                    : null
                )}
            </Slider>
        ) : ''
            }
    </div>
    )
}

export default MainNew;