import React, { useState, useEffect } from 'react';
import Form from './Form';
import { appAPI } from '../../API/API';
import './Forms.sass';
import {useDispatch} from "react-redux";
import {openModalAction} from "../../Actions/ModalActions";
import SignIn from "./SignIn";
import useResolution from "../../API/useResolution";
import {setForm} from '../../Actions/FormActions';

const Recovery = ({close, userEmail, ...props}) => {
  const dispatch = useDispatch();
  const isMobile = useResolution();

  const openSignIn = () => {
    dispatch(openModalAction(SignIn, true, true))
  }

  const formRecovery = {
    title: 'Восстановление пароля',
    fields: [
      {
        name: 'code',
        type: 'tel',
        placeholder: 'Введите код из e-mail',
      },
      {
        name: 'password',
        type: 'password',
        placeholder: 'Введите новый пароль',
      },
      {
        name: 'passwordRepeat',
        type: 'password',
        placeholder: 'Повторите пароль',
      },
    ],
    btn: 'Восстановить',
    firstDesc: 'Введите код подтверждения и новый пароль',
    secondDesc: 'Вспомнили пароль?',
    link: 'Войти',
    src: '/',
    modal: openSignIn
  };

  const [user, setUser] = useState({
    email: userEmail ? userEmail : 'email', 
    code: '',
    password: '',
    passwordRepeat: '',
  });

  useEffect(() => {
    console.log('user', user)
  }, [user])

  const submitHandler = async (e) => {
    e.preventDefault();
    if(user.password === user.passwordRepeat) {
      try{
        const response = await appAPI.resetPassword(user.email, user.code, user.password);
        console.log('resetPassword', response);
        dispatch(setForm('signin', true));
      } catch(err) {
        setFlagValidation('Неверный код подтверждения!')
      }
    } else (setFlagValidation('Пароли должны совпадать!'))
    
    if (isMobile) close()
  };

  const [flagValidation, setFlagValidation] = useState(false)

  const validation = (name, value) => {
    if(flagValidation) {
      switch(name) {
        case 'email':
          if(/[\w.%+-]+@[A-Za-z\d.-]+\.\w+/.test(value.email)) { return ''} else return 'active'
        case 'password':
          
          if (value.password.length < 3) { return 'active'} else return ''
        default: return;
      }
    }
  }

  return (
    <Form
      formName={'form_recovery'}
      props={formRecovery}
      state={user}
      errorMessage={flagValidation}
      setState={setUser}
      submitHandler={submitHandler}
      style = {props.style}
      validation = {validation}
      close={close}
      // formName={'form_reset'}
      {...props}
    />
  );
};

export default Recovery;
