import React, {useEffect} from 'react';
import {useSelector} from 'react-redux'
import { appAPI } from '../../../API/API';
import { useLocation } from 'react-router-dom';
import CatalogSubcategoryFilter from '../../../Components/Catalog/CatalogFilter/CatalogSubcategoryFilter';

const CatalogCategoryPage = ({match}) => {

    const {token} = useSelector(state => state.User)
    const categoryId = match.params.categoryId;
    const [subcategories, setSubcategories] = React.useState([])
    const queryString = require('query-string');
    const location = useLocation();
    const categoryTitle = queryString.parse(location.search);
    
    useEffect(() => {
        window.scrollTo(0, 0)
        const fetchData = async () => {
            await appAPI.getSubcategoriesByCategoryId(categoryId, token)
                .then(response => {
                    let subcategories = response.data.subcategories;
                    if (response.data.products) subcategories.push({products: response.data.products});
                    setSubcategories(subcategories);
                })
        }
        fetchData();
// eslint-disable-next-line
    }, [categoryId])
    
    return(
        <div className="catalog-category-page">
            <h2>{categoryTitle.title}</h2>

            <CatalogSubcategoryFilter categories = {subcategories}/>
        </div>
    )
}

export default CatalogCategoryPage;