import React, {useEffect, useState} from 'react';
import {appAPI} from '../../../API/API'
import SliderCategoriesCard from '../../../Components/SliderCategories/SliderCards/SliderCategoriesCard'
import { useLocation } from 'react-router-dom';

const CatalogSubcategoryPage = ({match}) => {
    
    const id = match.params.subcategoryId;
    const [products, setProducts] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const queryString = require('query-string');
    const location = useLocation();
    const subcategoryName = queryString.parse(location.search);

    useEffect(() => {
        const fetchData = async () => {
                await appAPI.getProductsSubcategory(id)
                    .then(response => {
                        setProducts(response.data[0].products)
                        setIsFetching(false)
                    })
        }
        setIsFetching(true)
        fetchData();
    }, [id])

    console.log(isFetching)

    return(
        <div className="catalog-category-page">
            {isFetching 
                ?   null
                :   <>
                        {products.length > 0
                            ? (
                                <>
                                    <h2>{subcategoryName.title}</h2>
                                    <div className="recepies-cards">
                                        {products.map(product => 
                                            <SliderCategoriesCard product={product} key = {product.id}/>
                                        )}
                                    </div>
                                </>
                            ) : (
                                    <>
                                        <img className = 'placeholder-fish' src="/img/catalog/placeholderFish.svg" alt="img"/>
                                        <p className = 'placeholder-desc'>Ваша рыба еще ловится</p>
                                    </>
                            )
                        }
                    </>
            }
        </div>
    )
}

export default React.memo(CatalogSubcategoryPage, (prev, next) => prev.children !== next.children);