import React, {useState} from 'react'
import Confirmation from './Confirmation/Confirmation'
import ContactData from './ContactData/ContactData'
import GetWay from './GetWay/GetWay'
import './Oformlenie.sass'

const Oformlenie = (props) => {

    const [activeContent, setActiveContent] = useState('contacts')
    const [activeContentSucceess, setActiveContentSucceess] = useState({
        contacts: false,
        way: false,
        confirmation: false
    })

    const switchActiveContent = (activeContent) => {

        switch (activeContent) {
            case 'contacts':
                return (<ContactData setActiveContent = {setActiveContent} activeContentSucceess = {activeContentSucceess} setActiveContentSucceess = {setActiveContentSucceess}/>)
            
            case 'get':
                return <GetWay setActiveContent = {setActiveContent} activeContentSucceess = {activeContentSucceess} setActiveContentSucceess = {setActiveContentSucceess}/>

            case 'confirmation':
                return <Confirmation cart = {props.cart} activeContentSucceess = {activeContentSucceess} setActiveContentSucceess = {setActiveContentSucceess}/>
            default: 
                return;
        }
    }

    const onClickItem = (item) => {

        switch (item) {
            case 'contacts':
                activeContentSucceess.contacts && setActiveContent('contacts')
            break;
            case 'get':
                activeContentSucceess.way && setActiveContent('get')
            break;
            case 'confirmation':
                activeContentSucceess.confirmation && setActiveContent('confirmation')
            break
            default: 
                return;
        }
    }

    return(
        <div className = 'oformlenie'>
            <ul className="oformlenie-list">
                <li 
                    className={`oformlenie-list__item ${activeContent === 'contacts' ? 'active' : ''} ${activeContentSucceess.contacts ? 'active_success' : ''}`}
                    onClick = {() => onClickItem('contacts')}
                    >
                    <span>1</span>
                    <p>Контактные данные</p>
                </li>
                <li 
                    className={`oformlenie-list__item ${activeContent === 'get' ? 'active' : ''} ${activeContentSucceess.way ? 'active_success' : ''}`}
                    onClick = {() => onClickItem('get')}
                    >
                    <span>2</span>
                    <p>Способ получения</p>
                </li>
                <li className={`oformlenie-list__item ${activeContentSucceess.confirmation ? 'active_success' : ''}`}>
                    <span>3</span>
                    <p>Подтверждение и оплата</p>
                </li>
            </ul>
            {switchActiveContent(activeContent)}
        </div>
    )
}

export default Oformlenie;